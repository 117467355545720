// src/App.js
import React from 'react';
import LoginArea from '../components/Login/LoginArea';
import LoginNew from '../components/NewLogin/Login';
//import { ChatComponent } from 'ai-product-kit';
//css-selector

const MarketTema1 = () => (
    <div >
      <div className="w-screen min-h-screen " >
        <div className=""> {/*pt-[5%] pb-10 */}
          <LoginNew />
        </div>
      </div>
    </div>
);


export default MarketTema1;
