import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore,
  collection,
  addDoc,
  getDocs,
  enableIndexedDbPersistence ,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getPerformance } from "firebase/performance";

// Tu configuración de Firebase
const firebaseConfig = {
    apiKey: "AIzaSyD6NCBkukoGgS1AaZ3mzVja8kBOlTL50cM",
    authDomain: "oneclick-market.firebaseapp.com",
    projectId: "oneclick-market",
    storageBucket: "oneclick-market.appspot.com",
    messagingSenderId: "730920141406",
    appId: "1:730920141406:web:ab13ec7acbe8a5f411aacf",
    measurementId: "G-LD3H9X6KZD"
  };

// Inicializa Firebase y firestore
const app = initializeApp(firebaseConfig);


const perf = getPerformance(app);
//const db = getFirestore(app);

// Habilita la persistencia en caché
const db = initializeFirestore(app, {
  localCache: persistentLocalCache({
    tabManager: persistentMultipleTabManager()
  })
});

{/*
  enableIndexedDbPersistence(db)
  .catch((err) => {
    if (err.code === 'failed-precondition') {
      // Probablemente hay múltiples pestañas abiertas
      console.error('Persistencia fallida: hay múltiples pestañas abiertas.');
    } else if (err.code === 'unimplemented') {
      // El navegador no soporta todas las características requeridas
      console.error('Persistencia no soportada en este navegador.');
    }
  });

  */}
  
const storage = getStorage(app);

// Exporta la autenticación y el proveedor de Google
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const functions = getFunctions(app);

export {  db, storage ,collection, addDoc,getDocs };