import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  Switch,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Grid,
  Chip,
  CircularProgress,
  TextField,
  Box
} from '@mui/material';
import { storage } from 'firebase/app';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {  INTEGRACIONES, PLUGINS , SEO, SECCIONES , initialState}  from '../../../../utils/configFeatures';
import { saveToFirestore, uploadFile } from '../../../../utils/firebaseUtils';


function FeatureToggleModal({ open, onClose, onSave, store , decompressFeatures, decompressSeoDetails}) {
 
  console.log("storeConfig decompressFeatures",decompressFeatures);
  console.log("storeConfig decompressSeoDetails", decompressSeoDetails);
  console.log("storeConfig initialState",initialState); 
  const [loading, setLoading] = useState(false); // Agregamos el estado de carga
  // 1. Inicializamos el estado combinando todos los 'key' con sus valores por defecto


  const [features, setFeatures] = useState(decompressFeatures);
  const [seoDetails, setSeoDetails] = useState(decompressSeoDetails);
  
  const [tagInput, setTagInput] = useState('');

  // Manejador para actualizar los valores del estado
  const handleFeatureChange = (featureKey) => (event) => {
    setFeatures((prev) => ({
      ...prev,
      [featureKey]: event.target.checked,
    }));
  };

  const handleSeoDetailChange = (field) => (event) => {
    setSeoDetails((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  const handleAddTag = (event) => {
    if (event.key === 'Enter' && tagInput.trim() !== '' && !seoDetails.tags.includes(tagInput.trim())) {
      setSeoDetails((prev) => ({
        ...prev,
        tags: [...prev.tags, tagInput.trim()],
      }));
      setTagInput(''); // Clear input after adding tag
      event.preventDefault(); // Prevent form submission (default behavior on Enter key)
    }
  };

  /*const handleAddTag = (event) => {
    if (event.key === 'Enter' && tagInput.trim() !== '') {
      setSeoDetails((prev) => ({
        ...prev,
        tags: [...(prev.tags || []), tagInput.trim()], // Ensure `tags` is an array
      }));
      setTagInput(''); // Clear input after adding tag
      event.preventDefault(); // Prevent form submission
    }
  };*/
  

  const handleDeleteTag = (tagToDelete) => {
    setSeoDetails((prev) => ({
      ...prev,
      tags: prev.tags.filter((tag) => tag !== tagToDelete),
    }));
  };

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSeoDetails((prev) => ({
          ...prev,
          logo: reader.result, // Guardar la URL de la imagen cargada
        }));
      };
      reader.readAsDataURL(file); // Convertir la imagen a URL base64
    }
  };

  // Al presionar "Guardar", enviamos los datos y cerramos
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      await onSave?.({ features, seoDetails },store);// Simula guardar los datos
    } finally {
      setLoading(false); // Desactivar indicador de carga
      onClose();
    }
  };

  // Componentes reutilizables para simplificar:
  const renderFeatureGrid = (dataArray) => (
    <Grid container spacing={2}>
      {dataArray.map((item) => (
        <Grid item xs={item.xs} sm={item.sm} md={item.md} key={item.key}>
          <div style={{
            padding: '16px',
            border: '1px solid #ddd',
            borderRadius: '8px',
            height: '100%',
            boxSizing: 'border-box',
          }}>
            <FormControlLabel
              control={
                <Switch
                  checked={features[item.key]}
                  onChange={handleFeatureChange(item.key)}
                  disabled={item.key === "seoPage"}
                />
              }
              label={item.label}
            />
            {item.chips?.map((chip, idx) => (
              <Chip
                key={idx}
                label={chip.label}
                color={chip.color || 'default'}
                size="small"
                style={{ marginLeft: '8px', verticalAlign: 'middle' }}
              />
            ))}
            <br />
            <Typography variant="caption" style={{ color: '#555' }}>
              {item.description}
            </Typography>
            {item.requisitos && (
              <>
                <br />
                <Typography
                  variant="caption"
                  style={{ color: '#555', fontWeight: 'bold', marginTop: '8px' }}
                >
                  Requisitos:
                </Typography>
                <ul style={{ paddingLeft: '16px', marginTop: '4px', listStyleType: 'disc' }}>
                  {item.requisitos.map((req, rIdx) => (
                    <li key={rIdx}>{req}</li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </Grid>
      ))}
    </Grid>
  );

  console.log("storeConfig seoDetails",seoDetails); 

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit}>
        <Box sx={{ background: 'linear-gradient(to right, #DD69E5, #A55AEE, #62CEFB)', 
                           height:'14px',  
                           witdh:'100%' }}  ></Box>    
        <DialogTitle variant="titleModal">
            Configuración del sitio "{ store.nombre.toLowerCase() }"
        </DialogTitle>
        <DialogContent >
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                <b>SEO del Sitio</b>
                <br />
                Mejora tu visibilidad, atrae más tráfico y alcanza mejores posiciones en los buscadores.
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {renderFeatureGrid(SEO)}
              <div style={{ marginTop: '16px' }}>
                <TextField
                  label="Título"
                  variant="outlined"
                  fullWidth
                  value={seoDetails.title}
                  onChange={handleSeoDetailChange('title')}
                  style={{ marginBottom: '16px' }}
                />
                <TextField
                  label="Descripción"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={seoDetails.description}
                  onChange={handleSeoDetailChange('description')}
                  style={{ marginBottom: '16px' }}
                />

                <TextField
                  label="Google Analytics (GA4)"
                  value={seoDetails.googleAnalyticsScript}
                  onChange={handleSeoDetailChange('googleAnalyticsScript')}
                  fullWidth
                  margin="dense"
                  placeholder="Pega aquí el id de Google Analytics"
                  style={{ marginBottom: '16px' }}
                />

                <TextField
                  label="Google Search Console (GSC)"
                  value={seoDetails.googleSearchConsoleScript}
                  onChange={handleSeoDetailChange('googleSearchConsoleScript')}
                  fullWidth
                  margin="dense"
                  placeholder="Pega aquí el id de Google Search Console"
                  style={{ marginBottom: '16px' }}
                />

                
                {/* Tags: Campo con chips */}
                <Box display="flex" flexDirection="column" sx={{ width: { xs: "100%", md: "100%" } }} gap={1}>
                  <TextField
                    label="Añadir Tag"
                    type="text"
                    fullWidth
                    value={tagInput}
                    onChange={(e) => setTagInput(e.target.value)} // Handle input change
                    onKeyDown={handleAddTag}
                    helperText="Presiona Enter para añadir un tag"
                    margin="dense"
                  />
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                    {(seoDetails?.tags || []).map((tag, index) => (
                      <Chip
                        key={index}
                        label={tag}
                        onDelete={() => handleDeleteTag(tag)}
                        color="primary"
                      />
                    ))}
                  </Box>
                </Box>

                {/* Logo: Botón para subir imagen */}
                <Button
                  variant="outlined"
                  component="label"
                  style={{ marginTop: '16px', display: 'block', width:'fit-content' }}
                >
                  Subir Logo
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={handleLogoChange}
                  />
                </Button>
                {seoDetails.logo && (
                  <img
                    src={seoDetails.logo}
                    alt="Logo"
                    style={{ maxWidth: '100%', height: '50px', margin: '16px 0' }}
                  />
                )}

              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                <b>Secciones del Sitio</b>
                <br />
                Personaliza tu sitio web seleccionando qué secciones deseas mostrar u ocultar.
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {renderFeatureGrid(SECCIONES)}
            </AccordionDetails>
          </Accordion>

          {/* Accordion para Plugins */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                <b>Plugins</b>
                <br />
                Funcionalidades adicionales para mejorar la experiencia del usuario.
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {renderFeatureGrid(PLUGINS)}
            </AccordionDetails>
          </Accordion>

          {/* Accordion para Integraciones */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                <b>Integraciones</b>
                <br />
                Conecta la plataforma con servicios externos para ampliar sus capacidades.
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {renderFeatureGrid(INTEGRACIONES)}
            </AccordionDetails>
          </Accordion>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color="secondary" disabled={loading} >
            Cancelar
          </Button>

          
          <Button 
                  variant="btnprimary"
                  type="submit"
                  disabled={loading} // Botón deshabilitado durante la carga
                  startIcon={loading && <CircularProgress size={20} />} // Indicador en el botón
          >
          {loading ? 'Guardando...' : 'Guardar Cambios'} 
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default FeatureToggleModal;
