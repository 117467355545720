import React from 'react';
import { Button } from '@mui/material';

const SlideEdgeButton = ({ 
  icon: Icon,
  label,
  onClick,
  position = { top: '1rem', right: '-4.3rem' },
  ...props 
}) => {
  return (
    <Button
      variant="slideEdgeButton"
      color="primary"
      startIcon={Icon && <Icon />}
      onClick={onClick}
      sx={{
        ...position
      }}
      {...props}
    >
      {label}
    </Button>
  );
};

export default SlideEdgeButton;