import React, { useEffect, useState } from 'react';
import { Alert } from '@mui/material';
import Footer from '../components/Footer/components/Footer';
import { doc, getDoc , getDocFromCache, getDocFromServer, setDoc } from 'firebase/firestore'; // Firebase imports
import { db } from '../firebaseConfig'; // Firebase config
import { useParams } from 'react-router-dom';
import useStoreName from '../hooks/useStoreName'; 
import NavbarManager from '../components/Navbar/NavbarManager';
import SplashScreenManager from '../components/SplashScreen/SplashScreenManager';
import MetaTags from '../utils/MetaTags';
import {UIDetailMain} from '@misnpm/design-blog';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';


const Blog = () => {
  const { id: blogEntryId } = useParams();
  const { storeName, isAdminRoute } = useStoreName();
  const urlReplace = process.env.REACT_APP_URL_REPLACE;
  const [storeConfig, setStoreConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [savedContent, setSavedContent] =useState([]);
  const [editContent, setEditContent] =useState([]);

  const fetchStoreConfig = async () => {
    try {
      const storeDocRef = doc(db, storeName, 'config');
      console.log('storeDocRef',storeDocRef)
      const blogDocRef = doc(db, storeName, 'blog', 'entry', blogEntryId);
  
      let storeDoc, blogDoc;
  
      try {
        storeDoc = await getDocFromCache(storeDocRef);
        blogDoc = await getDocFromCache(blogDocRef);
      } catch (cacheError) {
        storeDoc = await getDocFromServer(storeDocRef);
        blogDoc = await getDocFromServer(blogDocRef);
      }
  
      if (storeDoc.exists()) {
        setStoreConfig(storeDoc.data());
      } else {
        throw new Error('No se encontró la configuración de la tienda.');
      }

      if (blogDoc.exists()) {
        const savedContentData = blogDoc.data().blogContent;
        const blogData = blogDoc.data();
        console.log("Contenido guardado desde Firebase:", savedContentData);
        setEditContent(blogData);
        try {
          if (typeof savedContentData === 'string') {
            const parsedContent = JSON.parse(savedContentData);
            setSavedContent(parsedContent);
          } else {
            setSavedContent(savedContentData);
          }
        } catch (error) {
          console.error('Error al parsear blogContent:', error);
          setSavedContent([]);
        }
      } else {
        console.log('No se encontró contenido guardado.');
        setSavedContent([]); 
        setEditContent([]);
      }
        
    } catch (err) {
      console.error('Error al obtener los datos:', err);
      setError(`Error al obtener los datos: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("editContent ha cambiado:", editContent);
  }, [editContent]); 
  
 
  useEffect(() => {
    if (storeName) {
      fetchStoreConfig();
    } 
  }, [storeName]); 

  if (error) return <Alert severity="error">{error}</Alert>;

  const handleSave = async (content) => {
    try {
      let parsedContent;
  
      if (typeof content === 'string') {
        try {
          parsedContent = JSON.parse(content);
        } catch (error) {
          console.error('Error al parsear JSON:', error);
          return;
        }
      } else {
        parsedContent = content;
      }
  
      const jsonContent = JSON.stringify(parsedContent);
  
      const storeDocRef = doc(db, storeName, 'blog', 'entry', blogEntryId);
      await setDoc(storeDocRef, { blogContent: jsonContent }, { merge: true });
  
      console.log('Contenido guardado en Firebase:', jsonContent);
      setSavedContent(parsedContent);
    } catch (error) {
      console.error('Error al guardar en Firebase:', error);
    }
  };
  
  const handleEdit = async (updatedContent) => {
    try {
      const storage = getStorage();
      const storeDocRef = doc(db, storeName, 'blog', 'entry', blogEntryId);
      
      let imageUrl = updatedContent.image; 
      
      // ✅ Si el usuario subió una nueva imagen, subirla a Firebase Storage
      if (updatedContent.image instanceof File) {
          const imagePath = `${storeName}/blog/images/${updatedContent.image.name}`;
          const storageRef = ref(storage, imagePath);
          
          const snapshot = await uploadBytes(storageRef, updatedContent.image);
          imageUrl = await getDownloadURL(snapshot.ref);
          console.log('Imagen subida correctamente:', imageUrl);
      }

      // ✅ Asegurar que `tag` es un array
      const formattedTags = Array.isArray(updatedContent.tag)
          ? updatedContent.tag
          : updatedContent.tag
          ? updatedContent.tag.split(",").map(tag => tag.trim())
          : [];

      // ✅ Datos a actualizar en Firestore
      const updatedData = {
          title: updatedContent.title || '',
          titleTextColor: updatedContent.titleTextColor || '',
          image: imageUrl,  // Nueva URL de imagen o la misma
          date: updatedContent.date || '',
          dateTextColor: updatedContent.dateTextColor || '',
          tag: formattedTags,
          tagTextColor: updatedContent.tagTextColor || '',
          tagColor: updatedContent.tagColor || '',
      };

      // ✅ Guardar cambios en Firestore
      await setDoc(storeDocRef, updatedData, { merge: true });

      console.log('Contenido actualizado correctamente en Firebase:', updatedData);

      // ✅ ACTUALIZAR EL ESTADO LOCAL PARA REFLEJAR LOS CAMBIOS EN LA UI
      setEditContent(prev => ({ ...prev, ...updatedData }));
  } catch (error) {
      console.error('Error al actualizar el contenido en Firebase:', error);
  }
};
  
  return (
    <div>
      <MetaTags title={editContent?.title} description={editContent?.shortDescription} image={editContent?.image} favicon={editContent?.image} keywords={editContent?.tags?.join(", ")} tags={editContent?.tags?.join(", ")}/>

      <div className={`splash-screen ${loading ? 'splash-screen--hidden' : 'splash-screen--visible'}`}>
        {error && <Alert severity="error">{error}</Alert>}
        {storeConfig && 
          <>
            <NavbarManager  storeName={storeName} compressedLogo={storeConfig.logoData} compressedNavbar={storeConfig.navbarData} />
            {console.log("Pasando savedContent a UIDetailMain:", savedContent)} 
            <div className="min-h-[700px]">
              <UIDetailMain
                title={editContent?.title || "Título no disponible"}
                titleTextColor={editContent?.titleTextColor || "#000"}
                image={editContent?.image || ""}
                date={editContent?.date || "Fecha no disponible"}
                dateTextColor={editContent?.dateTextColor || "#000"}
                //tag={editContent?.tag || []}
                tag={Array.isArray(editContent?.tag) ? editContent.tag : []}
                tagTextColor={editContent?.tagTextColor} 
                tagColor={editContent?.tagColor}
                isAdminRoute={isAdminRoute}
                savedContent={savedContent}
                onSave={handleSave}
                onEdit={handleEdit}
              />
            </div>

            <Footer storeName={storeName} compressedFooter={storeConfig.footer} />
          </>
        } 
      </div>
      <div className={`splash-screen ${loading ? 'splash-screen--visible' : 'splash-screen--hidden'}`}>
        <SplashScreenManager />
      </div>
    </div>
  );
};

export default Blog;