import {  Button } from '@mui/material';
import DesignServicesIcon from '@mui/icons-material/DesignServices';

const ButtonAdd = ({ onClick }) => {

  return (

      <Button
        variant="contained"
        color="primary"
        startIcon={<DesignServicesIcon />}
        onClick={onClick}
        sx={{
          position: 'absolute',
          top: '3rem',
          right: '-6.1rem',                
          transition: 'right 0.3s ease',
          borderTopRightRadius: 0,  
          zIndex: 2,   
          borderBottomRightRadius: 0, 
          background: 'linear-gradient(to right, #DD69E5, #A55AEE, #62CEFB)', 
          borderRight: 'none',
          '&:hover': {
            right: '0rem',             
            background: 'linear-gradient(to right, #DD69E5, #A55AEE, #62CEFB)', 
          },
        }}
      >
        Reportes
      </Button>
  );
};

export default ButtonAdd;
