import React from 'react';
import { Box, Grid } from '@mui/material';
import LazyLoadImage from '../../../../utils/LazyLoadedImage';
import SkeletonLoader from '../../../Beneficios/components/SkeletonLoader';

const HEIGHTS = {
  image1: "540px",
  image2: "432px",
  image3: "432px",
  image4: "168px",
  image5: "168px",
  image6: "498px",
  image7: "168px",
};

const UI1 = ({ galeriaArray, width = 300, height = 200 }) => {
  if (!galeriaArray) {
    return (
      <Grid container spacing={2}>
        {Array.from({ length: 4 }).map((_, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <SkeletonLoader width={width} height={height} />
          </Grid>
        ))}
      </Grid>
    );
  }

  const { tituloSeccion, tituloColor, fondoColor, galeria } = galeriaArray;

  if (!galeria) {
    return (
      <div className="flex" style={{flexDirection: 'column'}}>
        <div>
          <p className='my-5 text-[32px] text-center font-semibold text-black'>Galeria</p>
        </div>
        <div className='flex justify-center items-center min-h-[15rem]'>
          <p className='text-center'>Parece que no hay imágenes. <br/> ¡Añade una para ver el contenido!</p>
        </div>
      </div>
    );
  }

  const images = [
    { src: galeria.image1, alt: "imagen1", hasLink: galeria.image1HasLink, href: galeria.image1Href, gridArea: "image1", rounded: galeria.rounded },
    { src: galeria.image2, alt: "imagen2", hasLink: galeria.image2HasLink, href: galeria.image2Href, gridArea: "image2", rounded: galeria.rounded },
    { src: galeria.image3, alt: "imagen3", hasLink: galeria.image3HasLink, href: galeria.image3Href, gridArea: "image3", rounded: galeria.rounded },
    { src: galeria.image4, alt: "imagen4", hasLink: galeria.image4HasLink, href: galeria.image4Href, gridArea: "image4", rounded: galeria.rounded },
    { src: galeria.image5, alt: "imagen5", hasLink: galeria.image5HasLink, href: galeria.image5Href, gridArea: "image5", rounded: galeria.rounded },
    { src: galeria.image6, alt: "imagen6", hasLink: galeria.image6HasLink, href: galeria.image6Href, gridArea: "image6", rounded: galeria.rounded },
    { src: galeria.image7, alt: "imagen7", hasLink: galeria.image7HasLink, href: galeria.image7Href, gridArea: "image7", rounded: galeria.rounded },
  ];

  const renderImage = ({ src, alt, hasLink, href }) => {
    if (!src) return null;
    if (hasLink && href) {
      return (
        <button onClick={() => window.open(href, "_blank", "noopener,noreferrer")} className="w-full h-full p-0 border-none bg-transparent">
          <LazyLoadImage 
            src={src}
            alt={alt}
            className="w-full h-full object-fill object-center"
          />
        </button>
      );
    }
    return (
      <LazyLoadImage 
        src={src}
        alt={alt}
        className="w-full h-full object-fill object-center"
      />
    );
  };

  return (
    <Box>
      <div className="rounded-t-[30px] px-3 lg:px-[100px] py-[41px] z-[1] relative" style={{ backgroundColor: fondoColor }}>
        <h2 className="!text-center text-[24px] sm:text-[30px] lg:text-[40px] font-bold leading-9 mb-[45px] whitespace-normal break-words" style={{ color: tituloColor }}>
          {tituloSeccion}
        </h2>

        <div
          className="flex flex-col lg:grid gap-4 container mx-auto"
          style={{
            gridTemplateColumns: "repeat(5, 1fr)",
            gridTemplateAreas: `
              "image1 image1 image1  image1 image4"
              "image1 image1 image1  image1 image5"
              "image1 image1 image1  image1 image6"
              "image2 image3 image3  image3 image6"
              "image2 image3 image3  image3 image7"
            `,
          }}
        >
          {images.map((image, index) => (
            <div
              key={index}
              className={`bg-white overflow-hidden shadow-lg`}
              style={{
                gridArea: image.gridArea,
                borderRadius: image.rounded,
                maxHeight: HEIGHTS[image.gridArea],
              }}
            >
              {renderImage(image)}
            </div>
          ))}
        </div>
      </div>
    </Box>
  );
};

export default UI1;