import React from 'react';
import DynamicStyleForm from '../../common/DynamicStyleForm';

const UI1Form = ({ bannerData, handleChange }) => {
  const styleNameMap = {
    fields: {
      title: {
        type: 'text',
        label: 'Título',
        order: 1,
        value: 'title',
        styles: {
          color: 'titleColor',
          size: 'titleSize',
          fontFamily: 'titleFontFamily',
          bold: 'titleBold',
          italic: 'titleItalic',
          underline: 'titleUnderline',
          lineThrough: 'titleLineThrough',
          align: 'titleAlign',
          letterSpacing: 'titleLetterSpacing',
          lineHeight: 'titleLineHeight'
        }
      },
      subtitle: {
        type: 'text',
        label: 'Titulo Superior',
        order: 2,
        value: 'subtitle',
        styles: {
          color: 'subtitleColor',
          size: 'titleSize',
          fontFamily: 'titleFontFamily',
          bold: 'titleBold',
          italic: 'titleItalic',
          underline: 'titleUnderline',
          lineThrough: 'titleLineThrough',
          align: 'titleAlign',
          letterSpacing: 'titleLetterSpacing',
          lineHeight: 'titleLineHeight'
        }
      },
      description: {
        type: 'text',
        label: 'Descripción',
        order: 3,
        value: 'description',
        styles: {
          color: 'descriptionColor',
          size: 'descriptionSize',
          fontFamily: 'descriptionFontFamily',
          bold: 'descriptionBold',
          italic: 'descriptionItalic',
          underline: 'descriptionUnderline',
          lineThrough: 'descriptionLineThrough',
          align: 'descriptionAlign',
          letterSpacing: 'descriptionLetterSpacing',
          lineHeight: 'descriptionLineHeight'
        }
      },
      buttonText: {
        type: 'button',
        label: 'Texto del Botón',
        order: 4,
        value: 'buttonText',
        styles: {
          color: 'buttonTextColor',
          size: 'buttonTextSize',
          fontFamily: 'buttonTextFontFamily',
          bold: 'buttonTextBold',
          italic: 'buttonTextItalic',
          underline: 'buttonTextUnderline',
          lineThrough: 'buttonTextLineThrough',
          align: 'buttonTextAlign',
          letterSpacing: 'buttonTextLetterSpacing',
          lineHeight: 'buttonTextLineHeight'
        }
      },
      desktopBackground: {
        type: 'image',
        label: 'Imagen Principal',
        group: 'desktop',
        groupLabel: 'Imágenes para Web',
        order: 5,
        title: 'Subir imagen de fondo para desktop',
        properties: {
          image: 'backgroundImage',
          color: 'backgroundColor',
          imageData: 'imageData',
          position: 'backgroundPosition',
          size: 'backgroundSize',
          repeat: 'backgroundRepeat'
        },
        path: 'banners/fondo',
      },
      mobileBackground: {
        type: 'image',
        label: 'Imagen Principal',
        group: 'mobile',
        groupLabel: 'Imágenes para Web Móvil',
        order: 6,
        title: 'Subir imagen de fondo para móvil',
        properties: {
          image: 'mobileBackgroundImage',
          color: 'mobileBackgroundColor',
          imageData: 'mobileImageData',
          position: 'mobileBackgroundPosition',
          size: 'mobileBackgroundSize',
          repeat: 'mobileBackgroundRepeat'
        },
        path: 'banners/fondoMobile',
      }
    }
  };

  return (
    <DynamicStyleForm 
      formData={bannerData} 
      onStyleChange={handleChange} 
      styleConfig={styleNameMap}
    />
  );

};

export default UI1Form;