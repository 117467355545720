import React, { createContext, useContext, useState } from 'react';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modalActivo, setModalActivo] = useState(null);

  const abrirModal = (modalId) => {
    setModalActivo(modalId);
  };

  const cerrarModal = () => {
    setModalActivo(null);
  };

  return (
    <ModalContext.Provider value={{ modalActivo, abrirModal, cerrarModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);