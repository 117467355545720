import React from 'react';
import { DesignSectionsManager } from "@misnpm/design-sections";
import SkeletonLoader from './SkeletonLoader';

const BeneficiosPreview = ({ storeName,beneficiosArray, width = 300, height = 200, user }) => {
  
  console.log("BeneficiosPreview - beneficiosArray:", beneficiosArray);
  
  
  const propsUI = {
    ...beneficiosArray, 
    items: beneficiosArray.beneficios || [], 
  };


  if (!beneficiosArray) {
    return (
      <>
        {Array.from({ length: 4 }).map((_, index) => (
          <SkeletonLoader key={index} width={width} height={height} />
        ))}
      </>
    );
  }

  const isUI4 = beneficiosArray.disenoSeleccionado === "UI4";


  return (
  
  <>

   {!isUI4 && (
      <h1 className="text-[32px]  text-center  font-semibold my-5 "
          style={{ color: beneficiosArray.colorTitulo }}
      >
          { beneficiosArray.tituloSeccion || "Beneficios" }
      </h1>
    )}

    <DesignSectionsManager 
      selectUI={beneficiosArray.disenoSeleccionado || "UI1"} 
      propsUI={propsUI}
      tituloSeccion={beneficiosArray.tituloSeccion || "Title" }
      colorTitulo={beneficiosArray.colorTitulo}
      />

  </>
  );
};

export default BeneficiosPreview;
