import React, { useState } from 'react';
import { Box } from '@mui/material';
import SkeletonLoader from './SkeletonLoader';

const LogoPreview = ({ logo, width = 150, height = 30, className }) => {
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false); // Desactivar el loader cuando la imagen se cargue
  };

  return (
    <Box sx={{ position: 'relative', width, height }} className={className}>
      {/* Loader visible mientras se carga */}
      <SkeletonLoader
        width={width}
        height={height}
        style={{
          display: loading ? 'block' : 'none',
        }}
      />

      {/* Imagen cargada */}
      <img
        src={logo}
        alt="Logo"
        onLoad={handleImageLoad}
        style={{
          display: loading ? 'none' : 'block',
          width: `${width}px`,
          height: `${height}px`,
          objectFit: 'contain',
        }}
      />
    </Box>
  );
};

export default LogoPreview;
