import { createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins, sans-serif', // Esto aplica a todos los botones
        },
      },
      variants: [
        {
          props: { variant: 'btnprimary' },
          style: {
            backgroundImage: 'linear-gradient(to right, #DD69E5, #A55AEE, #62CEFB)',
            color: 'white',
            fontFamily: 'Poppins, sans-serif',
            margin: '0px',
            padding: '6px 8px',
            boxShadow: '0 8px 15px rgba(0, 0, 0, 0.1)',
            transition: 'all 0.3s ease',
            '&:hover': {
              boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
            },
          },
        },{
          props: { variant: 'slideEdgeButton' },
          style: {
            position: 'absolute',
            transition: 'right 0.3s ease',
            color: 'white',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderRight: 'none',
            zIndex: 1400,
            background: 'linear-gradient(to right, #DD69E5, #A55AEE, #62CEFB)',
            '&:hover': {
              right: '0rem',
              background: 'linear-gradient(to right, #DD69E5, #A55AEE, #62CEFB)',
            },
          },
        },
      ],
    },
    MuiDialogTitle: {
      variants: [
        {
          props: { variant: 'titleModal' },
          style: {
            fontSize: '28px',
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 600,
            textAlign: 'center',
            marginBottom: '0rem',
            lineHeight: '2.25rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center', 
            minWidth: '300px',
          },
        },
      ],
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          borderRadius: '15px !important',
        },
        paper: {
          borderRadius: '15px !important',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          color: '#666666',
        },
        paper: {
          fontSize: '14px',
          color: '#666666',

        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '10px !important',
        },
        paper: {
          padding: '10px !important',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins  !important',
        },
        paper: {
          fontFamily: 'Poppins !important',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins  !important',
        },
        paper: {
          fontFamily: 'Poppins !important',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins  !important',
          fontSize: '14px !important',
        },
        paper: {
          fontFamily: 'Poppins !important',
          fontSize: '14px !important',
        },
      },
    },
  },
});

export default theme;
