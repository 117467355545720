import React from 'react';
import { Grid, TextField, InputAdornment } from '@mui/material';
import { ButtonWithModal } from '@misnpm/input-text-kit';

const StylizedTextField = ({ label, name, value, onStyleChange }) => {
  const handleInputChange = (e) => {
    // Handle direct text input changes
    onStyleChange({
      content: { value: e.target.value },
      text: { hasText: true }
    });
  };

  const handleStyleChange = (newStyle) => {
    // Preserve the current text value when changing styles
    onStyleChange({
      ...newStyle,
      content: { value: value }
    });
  };

  return (
    <Grid item xs={12}>
      <TextField
        fullWidth
        label={label}
        name={name}
        value={value}
        onChange={handleInputChange}
        sx={{ mb: 3 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <ButtonWithModal 
                value={{
                  content: { value: value },
                  text: { hasText: true }
                }}
                onChangePreview={handleStyleChange}
              />
            </InputAdornment>
          )
        }}
      />
    </Grid>
  );
};

export default StylizedTextField;