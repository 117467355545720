// src/config/features.js

export const INTEGRACIONES = [
    {
      key: 'inteTictokProducto',
      label: 'TikTok para Productos',
      defaultValue: false,
      description: `Te permite compartir fácilmente los productos creados en tu plataforma 
                    directamente en tu cuenta de TikTok.`,
      chips: [
        { label: 'Integración sujeta a asistencia', color: 'info' }
      ],
      requisitos: [
        'Cuenta de TikTok configurada como cuenta empresarial.',
        'Token de acceso generado desde la API de TikTok para publicar contenido.',
        'Clave API y configuración del cliente autenticado.',
      ],
      xs: 12,
      sm: 12,
      md: 12
    },
    {
      key: 'intePowerBI',
      label: 'Power BI (Normal)',
      defaultValue: false,
      description: `Permite integrar reportes de Power BI mediante links públicos para 
                    visualizar los datos directamente en tu plataforma.`,
      chips: [
        { label: 'Integración sujeta a asistencia', color: 'info' }
      ],
      requisitos: [
        'URL pública del reporte de Power BI.',
        'Configuración del acceso al reporte como público.',
      ],
      xs: 12,
      sm: 12,
      md: 12
    },
    {
      key: 'intePoweBiEmbedded',
      label: 'Power BI Embedded',
      defaultValue: false,
      description: `Permite incrustar reportes de Power BI directamente en tu plataforma. 
                    Ideal para dashboards interactivos y análisis en tiempo real.`,
      chips: [
        { label: 'Integración sujeta a asistencia', color: 'info' }
      ],
      requisitos: [
        'Access Key y Secret Key de Azure.',
        'ID de Workspace y Report.',
        'URL de embed de Power BI.',
        'Roles configurados en Azure para el acceso.',
      ],
      xs: 12,
      sm: 12,
      md: 12
    },
    {
      key: 'intePaypal',
      label: 'Método de Pago: PayPal',
      defaultValue: false,
      description: `Permite procesar pagos de manera segura utilizando PayPal como pasarela de pago.`,
      chips: [
        { label: 'Integración sujeta a asistencia', color: 'info' }
      ],
      requisitos: [
        'Cuenta de PayPal Business.',
        'Client ID y Secret Key generados en el portal de desarrolladores de PayPal.',
      ],
      xs: 12,
      sm: 12,
      md: 12
    },
    {
      key: 'inteStripe',
      label: 'Método de Pago: Stripe',
      defaultValue: false,
      description: `Integra Stripe para procesar pagos directamente en tu plataforma 
                    con soporte para múltiples métodos de pago.`,
      chips: [
        { label: 'Integración sujeta a asistencia', color: 'info' }
      ],
      requisitos: [
        'Cuenta de Stripe.',
        'Clave pública y clave secreta de Stripe.',
        'Webhook configurado para recibir notificaciones de pagos.',
      ],
      xs: 12,
      sm: 12,
      md: 12
    }
  ];
  
  export const PLUGINS = [
    {
      key: 'pluginWhatsAppBtn',
      label: 'WhatsApp como Botón de Acción Flotante',
      defaultValue: false,
      description: `Permite a los usuarios iniciar una conversación de manera rápida 
                    y directa con tu empresa desde cualquier página del sitio web.`,
      chips: [
        { label: 'Próximamente', color: 'primary' }
      ],
      xs: 12,
      sm: 12,
      md: 12
    }
  ];

  export const SEO = [
    {
      key: 'seoPage',
      label: 'SEO',
      defaultValue: true,
      description: 'El SEO de la página que aparece en los buscadores.',
      xs: 12,
      sm: 12,
      md: 12
    }
  ];
  
  export const SECCIONES = [
    {
      key: 'header',
      label: 'Header',
      defaultValue: true,
      description: 'El encabezado principal del sitio web que incluye el menú de navegación y el logo.',
      xs: 12,
      sm: 6,
      md: 4
    },
    {
      key: 'banner',
      label: 'Banner',
      defaultValue: true,
      description: 'La sección destacada que aparece al inicio del sitio con promociones o mensajes clave.',
      xs: 12,
      sm: 6,
      md: 4
    },
    {
      key: 'servicios',
      label: 'Servicios',
      defaultValue: true,
      description: 'Información sobre los servicios que ofrece tu negocio.',
      xs: 12,
      sm: 6,
      md: 4
    },
    {
      key: 'beneficios',
      label: 'Beneficios',
      defaultValue: true,
      description: 'Una lista de ventajas o valores agregados que los usuarios obtienen al elegirte.',
      xs: 12,
      sm: 6,
      md: 4
    },
    {
      key: 'galeria',
      label: 'Galeria de fotos',
      defaultValue: true,
      description: 'Galeria de imágenes para mostrar tus productos o servicios.',
      xs: 12,
      sm: 6,
      md: 4
    },
    {
      key: 'productos',
      label: 'Productos',
      defaultValue: true,
      description: 'Detalles sobre los productos disponibles para la venta.',
      xs: 12,
      sm: 6,
      md: 4
    },
    {
      key: 'blog',
      label: 'Blog',
      defaultValue: true,
      description: 'Artículos o publicaciones relevantes para informar y atraer a tu audiencia.',
      xs: 12,
      sm: 6,
      md: 4
    },
    {
      key: 'politicasPrivacidad',
      label: 'Políticas de Privacidad',
      defaultValue: true,
      description: 'Documento legal que detalla cómo se maneja la información personal de los usuarios.',
      xs: 12,
      sm: 6,
      md: 4
    },
    {
      key: 'footer',
      label: 'Footer',
      defaultValue: true,
      description: 'La parte inferior del sitio web con información como contactos y redes sociales.',
      xs: 12,
      sm: 6,
      md: 4
    },
    {
      key: 'contactanos',
      label: 'Contáctanos',
      defaultValue: false,
      description: 'Sección con información para que los usuarios puedan comunicarse contigo.',
      xs: 12,
      sm: 6,
      md: 4
    },
    {
      key: 'preguntasFrecuentes',
      label: 'Preguntas Frecuentes',
      defaultValue: false,
      description: 'Respuestas a las preguntas más comunes de los usuarios sobre tus servicios o productos.',
      xs: 12,
      sm: 6,
      md: 4
    }
  ];
  
  // Definir el estado inicial combinando todas las features
  export const initialState = {
    ...SEO.reduce((acc, item) => ({ ...acc, [item.key]: item.defaultValue }), {}),
    ...SECCIONES.reduce((acc, item) => ({ ...acc, [item.key]: item.defaultValue }), {}),
    ...PLUGINS.reduce((acc, item) => ({ ...acc, [item.key]: item.defaultValue }), {}),
    ...INTEGRACIONES.reduce((acc, item) => ({ ...acc, [item.key]: item.defaultValue }), {})
  };
  