import React, { useState, useEffect } from 'react';
import { Box, Snackbar, Alert,Button, Tooltip, IconButton } from '@mui/material';
import ItemTiendaPreview from './components/ItemTiendaPreview';
import useStore from '../../hooks/useStore'; 
import AddStoreForm from './components/AddStoreForm';
import AddStoreButton from './components/AddStoreButton';
import DeleteStoreButton from './components/DeleteStoreButton';
import AdminStoreButton from './components/AdminStoreButton';
import SectionStoreButton from './components/configuracion/SectionStoreButton';
import compress from '../../utils/compress'; 
import decompress from '../../utils/decompress'; 
import { Padding } from '@mui/icons-material';
import ModalChoose from './components/ModalChoose';


const TiendaManager = ({searchTerm }) => {

  const { registerStore, getStores, loading, error,authenticatedUser , deleteStore , registerStoreConfig } = useStore(); 
  const [ userStores, setUserStores] = useState([]); 
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const fetchStores = async () => {
      if (authenticatedUser) {
        const stores = await getStores();     
        
        const formattedStores = stores.map((store, index) => ({
          id: index + 1, 
          nombre: store , 
          imagen: 'assets2/img/tiendamanager/item-tienda-fondo.webp', 
        }));
        setUserStores(formattedStores);
      };
    };
    fetchStores();
  }, [authenticatedUser]);


  const handleTiendaClick = (store) => {
    const dominioBase = process.env.REACT_APP_URL_REPLACE;
    const dominioBaseSSL = process.env.REACT_APP_URL_SSL;
    const url = `${dominioBaseSSL}://${store.nombre}.${dominioBase}`; 
    window.open(url, '_blank'); 
  };

  const handleAdminClick = (store) => {
    const dominioBase = process.env.REACT_APP_URL_REPLACE; 
    const dominioBaseSSL = process.env.REACT_APP_URL_SSL;
    const url = `${dominioBaseSSL}://${dominioBase}/${store.nombre}/administrar`; 
    window.open(url, '_blank'); 
  };

  const handleSaveClick = async ({ features, seoDetails }, store) => {
    try {
      const compressedFeatures = compress({ features });
      const compressedSeo = compress({ seoDetails });
  
      await registerStoreConfig(store, compressedFeatures, compressedSeo);
  
      console.log("Store:", store);
      console.log("Features originales:", features);
      console.log("Features comprimidas:", compressedFeatures);
      console.log("SEO originales:", seoDetails);
      console.log("SEO comprimidas:", compressedSeo);
    } catch (error) {
      console.error("Error en handleFeatureSaveClick:", error);
    }
  };
  

  /*const handleAddStore = async (newStoreName) => {
    const isDuplicate = userStores.some((store) => store.nombre.toLowerCase() === newStoreName.toLowerCase());
    
    if (isDuplicate) {
      setSnackbarMessage('Este nombre ya está registrado');
      setOpenSnackbar(true);
      return;
    }
    const newStore = {
      id: userStores.length + 1, 
      nombre: newStoreName,
      imagen: 'assets2/img/tiendamanager/item-tienda-fondo.webp', 
    };

    const result = await registerStore({ title: newStoreName });
    if (!result.success) {
      console.error('Error al registrar la tienda:', result.error || 'Error desconocido');
    }
    
    setUserStores((prevStores) => [...prevStores, newStore]); 
  };*/

  const handleAddStore = async ({ storeName, category }) => {
    const isDuplicate = userStores.some(
      (store) => store.nombre.toLowerCase() === storeName.toLowerCase()
    );
  
    if (isDuplicate) {
      setSnackbarMessage('Este nombre ya está registrado');
      setOpenSnackbar(true);
      return;
    }
  
    const newStore = {
      id: userStores.length + 1,
      nombre: storeName,
      categoria: category, // Añadir la categoría
      imagen: 'assets2/img/tiendamanager/item-tienda-fondo.webp',
    };
  
    try {
      const result = await registerStore({ title: storeName, category }); // Enviar categoría al backend
      if (!result.success) {
        console.error('Error al registrar la tienda:', result.error || 'Error desconocido');
        return;
      }
  
      setUserStores((prevStores) => [...prevStores, newStore]);
    } catch (error) {
      console.error('Error al registrar la tienda:', error);
    }
  };

  const handleDeleteStore = async (newStoreName) => {
    const result = await deleteStore(newStoreName);
    if (!result.success) {
      console.error('Error al eliminar la tienda:', result.error || 'Error desconocido');
    }
    setUserStores((prevStores) => prevStores.filter((store) => store.nombre !== newStoreName));
  };

  

  const handleOpenForm = () => setIsFormOpen(true);
  const handleCloseForm = () => setIsFormOpen(false);
  const handleCloseSnackbar = () => setOpenSnackbar(false);

  const filteredStores = userStores.filter(store =>
    store.nombre.toLowerCase().includes(searchTerm.toLowerCase())
  );


  return (

    <Box >
      <div className="relative pt-3 w-full px-2 mb-4 lg:px-0 mx-auto 2xl:max-w-[1516px] xl:max-w-[1240px] lg:max-w-[1004px] md:max-w-[748px] sm:max-w-[620px]">
        <div className="mb-[2rem] mt-2 flex flex-col md:flex-row md:items-center justify-between  ">
          <div className='w-auto flex flex-col items-start'>
            <h1 className='font-poppins p-0 m-0 text-2xl	text-white  font-bold '> Gestionar Hubs</h1>
            <p className="font-poppins text-base text-white mt-[4px]">Administra y visualiza todos tus hubs desde un solo lugar.</p>
          </div>
          <div style={{ display: 'flex', gap: '10px',alignItems:'center', justifyContent:'flex-end' }}>
            <AddStoreButton onClick={handleOpenForm} />
          </div>
        </div>

      <ModalChoose open={isFormOpen} onClose={handleCloseForm} onSave={handleAddStore}/>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(3, 1fr)',
          },
          gap: 4,
          overflowX: 'hidden',
        }}
        
      >
         {filteredStores.map((store) => (
                    <div key={store.id} style={{ position: 'relative' }}>
                      
            <ItemTiendaPreview
              key={store.id}
              imagen={store.imagen}
              nombre={store.nombre.toLowerCase()}
              onTiendaClick={() => handleTiendaClick(store)}
              onAdminClick={() => handleTiendaClick(store)}
              store={store}
              handleDeleteStore={handleDeleteStore}
              handleAdminClick={handleAdminClick}
              handleSaveClick={handleSaveClick}
            
            />

          </div>
        ))}
      </Box>

      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TiendaManager;

