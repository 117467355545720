import React from 'react';
import { Grid, Typography } from '@mui/material';
import StylizedTextField from './StylizedTextField';
import StyledImageUploadButton from './StyledImageUploadButton';
import StylizedButtonField from './StylizedButtonField';

const DynamicStyleForm = ({ formData, onStyleChange, styleConfig }) => {
  const handleTextStyleChange = (fieldName, newStyle) => {
    const fieldConfig = styleConfig.fields[fieldName];
    if (!fieldConfig || fieldConfig.type !== 'text') return;

    if (newStyle.content?.value !== undefined) {
      onStyleChange({ target: { name: fieldConfig.value, value: newStyle.content.value } });
    }

    if (newStyle.text) {
      Object.entries(newStyle.text).forEach(([property, value]) => {
        const propertyName = fieldConfig.styles[property];
        if (propertyName) {
          onStyleChange({ target: { name: propertyName, value } });
        }
      });
    }
  };

  const handleButtonStyleChange = (fieldName, newStyle) => {
    const fieldConfig = styleConfig.fields[fieldName];
    if (!fieldConfig || fieldConfig.type !== 'button') return;

    if (newStyle.content?.value !== undefined) {
      onStyleChange({ target: { name: fieldConfig.value, value: newStyle.content.value } });
    }

    if (newStyle.button || newStyle.text || newStyle.background) {
      const allStyles = { ...newStyle.button, ...newStyle.text, ...newStyle.background };
      Object.entries(allStyles).forEach(([property, value]) => {
        const propertyName = fieldConfig.styles[property];
        if (propertyName) {
          onStyleChange({ target: { name: propertyName, value } });
        }
      });
    }
  };

  const handleBackgroundStyleChange = (fieldName, newStyle) => {
    const fieldConfig = styleConfig.fields[fieldName];
    if (!fieldConfig || fieldConfig.type !== 'image') return;

    const properties = {
      image: newStyle.background.imageUrl,
      color: newStyle.background.color,
      imageData: newStyle.background.imageData,
      position: newStyle.background.position || 'center',
      size: newStyle.background.size || 'cover',
      repeat: newStyle.background.repeat || 'no-repeat'
    };

    Object.entries(properties).forEach(([property, value]) => {
      const propertyName = fieldConfig.properties[property];
      if (propertyName) {
        onStyleChange({ target: { name: propertyName, value } });
      }
    });
  };

  const renderFields = () => {
    const fields = Object.entries(styleConfig.fields)
      .sort(([, a], [, b]) => a.order - b.order);

    const groupedFields = fields.reduce((acc, [fieldName, fieldConfig]) => {
      if (fieldConfig.type === 'image') {
        const group = fieldConfig.group || 'other';
        if (!acc[group]) {
          acc[group] = {
            label: fieldConfig.groupLabel || group,
            fields: []
          };
        }
        acc[group].fields.push([fieldName, fieldConfig]);
      } else {
        acc.text = acc.text || { fields: [] };
        acc.text.fields.push([fieldName, fieldConfig]);
      }
      return acc;
    }, {});

    return (
      <>
        {groupedFields.text?.fields.map(([fieldName, fieldConfig]) => (
          fieldConfig.type === 'button' ? (
            <StylizedButtonField
              key={fieldName}
              label={fieldConfig.label}
              name={fieldName}
              value={formData[fieldConfig.value]}
              onStyleChange={(newStyle) => handleButtonStyleChange(fieldName, newStyle)}
            />
          ) : (
            <StylizedTextField
              key={fieldName}
              label={fieldConfig.label}
              name={fieldName}
              value={formData[fieldConfig.value]}
              onStyleChange={(newStyle) => handleTextStyleChange(fieldName, newStyle)}
            />
          )
        ))}

        {Object.entries(groupedFields).map(([groupName, group]) => {
          if (groupName === 'text') return null;
          return (
            <React.Fragment key={groupName}>
              <Typography variant="subtitle1" sx={{ mb: 1, mt: 1 }}>
                {group.label}
              </Typography>
              <Grid container spacing={2} justifyContent="left">
                {group.fields.map(([fieldName, fieldConfig]) => (
                  <Grid item key={fieldName}>
                    <StyledImageUploadButton
                      title={fieldConfig.title}
                      onStyleChange={(newStyle) => handleBackgroundStyleChange(fieldName, newStyle)}
                      initialBackground={{
                        color: formData[fieldConfig.properties.color],
                        imageUrl: formData[fieldConfig.properties.image],
                        hasBackground: true
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </React.Fragment>
          );
        })}
      </>
    );
  };

  return renderFields();
};

export default DynamicStyleForm;