import React, { useState } from 'react';
import { IconButton , CircularProgress,Tooltip} from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import FeatureToggleModal from './FeatureToggleModal'; // Importa tu modal
import useStore from '../../../../hooks/useStore';
import decompress from '../../../../utils/decompress';
import IconImage from '../../../images/IconImage'

const SectionStoreButton = ({ onSave , store }) => {

  
  const {
    loading,
    error,
    storeConfig,
    getStoreConfig,
    features,
    seoDetails
  } = useStore();

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const handleOpen = async () => {
    try {
      getStoreConfig(store.nombre.toLowerCase());
    } catch (err) {
      console.error('Error al cargar los datos:', err);
      setOpen(false); // Abre el modal
    } finally {
      setOpen(true); // Abre el modal
    }
  };
   
  

  return (
    <>
      <Tooltip title="Configuracion" arrow>
          <button 
              className="w-8 h-8 rounded-full bg-white/80 backdrop-blur-sm flex items-center justify-center  transition-colors"
              onClick={handleOpen}
              disabled={loading}
            >
            {loading ? <CircularProgress size={20} /> : <IconImage name="config" width="16px" height="16px"  alt="Configuracion secciones hub" />}
          </button>
      </Tooltip> 


      {/* Modal de configuración de features, seoDetails y plugins */}
      {storeConfig && features && seoDetails &&(
        <FeatureToggleModal 
          open={open} 
          onClose={handleClose} 
          onSave={onSave} 
          store={store}
          decompressFeatures={features}
          decompressSeoDetails={seoDetails}
        />
      )}
      
    </>
  );
};

export default SectionStoreButton;