import React from 'react';
import { IconButton ,Tooltip} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import IconImage from '../../images/IconImage'

const AdminStoreButton = ({ onAdminClick }) => {

  return (
    
    <Tooltip title="Administrar hub" className='font-poppins' arrow>
        <button 
            className="w-8 h-8 rounded-full bg-white/80 backdrop-blur-sm flex items-center justify-center  transition-colors"
            onClick={onAdminClick}
          >
          <IconImage name="sectionSettings" width="16px" height="16px" alt="Administrar hub" />
        </button>
    </Tooltip>
  );
};

export default AdminStoreButton;