import React, { useState, useEffect } from 'react';
import { Box, Tooltip } from '@mui/material';
import { ButtonWithModal } from '@misnpm/input-text-kit';

const StyledImageUploadButton = ({ title, onStyleChange, initialBackground }) => {
    // Procesar el initialBackground para asegurar que tenga el formato correcto
    const formatBackground = (bgValue) => {
        if (!bgValue) return 'none';
        
        // Si es un objeto con imageUrl
        if (typeof bgValue === 'object' && bgValue.imageUrl) {
            return `url("${bgValue.imageUrl}")`;
        }
        
        // Si ya es una URL completa
        if (typeof bgValue === 'string' && bgValue.startsWith('url(')) {
            return bgValue;
        }
        
        // Si es una URL simple
        if (typeof bgValue === 'string' && (bgValue.startsWith('http') || bgValue.startsWith('/'))) {
            return `url("${bgValue}")`;
        }
        
        // Si es un color
        return bgValue;
    };

    const [background, setBackground] = useState(formatBackground(initialBackground));
    
    
    
    // Actualizar el background cuando cambia initialBackground
    useEffect(() => {
        setBackground(formatBackground(initialBackground));
    }, [initialBackground]);

    const handleStyleChange = (newStyle) => {
      let newBackground = 'none';
      if (newStyle.background?.imageUrl) {
        newBackground = `url("${newStyle.background.imageUrl}")`;
      } else if (newStyle.background?.color) {
        newBackground = newStyle.background.color;
      }
      setBackground(newBackground);
      onStyleChange(newStyle);
    };


    return (
      <Tooltip title={title} arrow>
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              border: '2px solid rgb(98 206 251)',
              borderRadius: 2,
              width: 100,
              height: 100,
              position: 'relative',
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '&:hover': {
                opacity: 0.75,
              },
              background: background,
              backgroundImage: background,  
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          >
            <ButtonWithModal 
              value={{
                text: { hasText: false },
                background: { hasBackground: true }
              }}
              onChangePreview={handleStyleChange}
              sx={{
                position: 'relative',
                zIndex: 1,
                opacity: 1,
                width: '100%',
                height: '100%',
              }}
            />
          </Box>
        </Box>
      </Tooltip>
    );
};

export default StyledImageUploadButton;