import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Button,
  TextField,
  IconButton,
  Typography,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Tooltip,
  Fab,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  DialogTitle,
  InputAdornment
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import { Delete } from "@mui/icons-material";
import DynamicEditor from '../../common/DynamicEditor';
import IconImage from "../../images/IconImage"
import StylizedButtonField from '../../common/StylizedButtonField';
import StylizedTextField from '../../common/StylizedTextField';
import { ButtonWithModal } from '@misnpm/input-text-kit';




const ReporteForm = ({
  storeName,
  initialTituloSeccion,
  initialDiseñoSeleccionado,
  initialReportes,
  onSave,
  handleClose,
  initialColorTitulo,
  open
}) => {
  const [tituloSeccion, setTituloSeccion] = useState(initialTituloSeccion || "Reportes");
  const [colorTitulo, setColorTitulo] = useState(initialColorTitulo || "");

  const [diseñoSeleccionado, setDiseñoSeleccionado] = useState(initialDiseñoSeleccionado || "UI9");
  const [reportes, setReportes] = useState(initialReportes || []);
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [loading, setLoading] = useState(false);

  const [dataComplete, setDataComplete] = useState({
    tituloSeccion: initialTituloSeccion || "",
    colorTitulo: initialColorTitulo || "",
    diseñoSeleccionado: initialDiseñoSeleccionado || "UI9",
    reportes: initialReportes || [],
  });

  useEffect(() => {
    setDataComplete({
      tituloSeccion,
      colorTitulo,
      diseñoSeleccionado,
      reportes,
    });
  }, [tituloSeccion,colorTitulo, diseñoSeleccionado, reportes]);

  const handleAddReporte = () => {
    setReportes((prev) => [
      ...prev,
      { 
        id: Date.now(), 
        titulo: "", 
        descripcion: "", 
        url: "", 
        buttonContent: "", 
        buttonColor: "", 
        buttonTextColor: "", 
        buttonUrl: "",
        button2Content: "", 
        button2Color: "",  
        button2TextColor: "", 
        button2Url: ""     
      },
    ]);
  };
  
  const handleDeleteReporte = (index) => {
    setReportes((prev) => prev.filter((_, i) => i !== index));
    if (expandedIndex === index) setExpandedIndex(-1);
  };

  const handleSave = () => {
    setLoading(true);
    onSave(dataComplete.tituloSeccion,dataComplete.colorTitulo, dataComplete.diseñoSeleccionado, dataComplete.reportes);
    setLoading(false);
    handleClose();
  };

  const handleAccordionChange = (index) => {
    setExpandedIndex((prev) => (prev === index ? -1 : index));
  };

  return (
    <>
    
      <DynamicEditor
        open={open}
        onClose={handleClose}
        title="Editar Reporte"
        width={{ xs: '100%', sm: 400 }}
        modalWidth={{ xs: "90%", sm: 800 }}
      >


          <TextField
            fullWidth
            label="Título de la Sección"
            value={tituloSeccion}
            onChange={(e) => setTituloSeccion(e.target.value)}
            margin="normal"
          />
      

          

    


          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="select-diseño-label">Seleccionar Diseño</InputLabel>
            <Select
              labelId="select-diseño-label"
              value={diseñoSeleccionado}
              onChange={(e) => setDiseñoSeleccionado(e.target.value)}
              label="Seleccionar Diseño"
            >
              <MenuItem value="UI9">Diseño 1</MenuItem>
              <MenuItem value="UI10">Diseño 2</MenuItem>
              <MenuItem value="UI11">Diseño 3</MenuItem>
            </Select>
          </FormControl>

         
          


          <div className="flex  justify-between items-center">
            <Typography variant="h6" className='!font-poppins' sx={{ my: 3 }}>
              Lista de Reportes
            </Typography>
            {!loading && (
              <Button
                onClick={handleAddReporte}
              >
                    <IconImage name="add" width="20px" height="20px" alt="Icono flecha" /> 
              </Button>


            )}
          </div>



          
          {reportes.length === 0 && (
            <Typography variant="body2" sx={{ mb: 2, color: "gray" }}>
              No hay reportes disponibles.
            </Typography>
          )}
          {reportes.map((reporte, index) => (
            <Accordion
              key={reporte.id || index}
              expanded={expandedIndex === index}
              onChange={() => handleAccordionChange(index)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ width: "100%" }}
                >
                  <Typography sx={{ fontWeight: "semibold" }}>
                    {reporte.titulo || "Nuevo Reporte"}
                  </Typography>
                  <IconButton
                    color="error"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteReporte(index);
                    }}
                    aria-label="delete"
                  >
                      <IconImage name="delete" width="20px" height="20px" alt="Icono flecha" /> 
                  </IconButton>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Título"
                      value={reporte.titulo}
                      onChange={(e) =>
                        setReportes((prev) =>
                          prev.map((item, i) =>
                            i === index ? { ...item, titulo: e.target.value } : item
                          )
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Descripción"
                      value={reporte.descripcion}
                      multiline
                      rows={4}
                      inputProps={{ maxLength: 220 }}
                      onChange={(e) =>
                        setReportes((prev) =>
                          prev.map((item, i) =>
                            i === index
                              ? { ...item, descripcion: e.target.value }
                              : item
                          )
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="URL del Reporte"
                      value={reporte.url}
                      onChange={(e) =>
                        setReportes((prev) =>
                          prev.map((item, i) =>
                            i === index ? { ...item, url: e.target.value } : item
                          )
                        )
                      }
                    />
                  </Grid>


                  <Grid item xs={12}>
                  <StylizedButtonField
                        key={`button1-${reporte.id}`}
                        label="Botón 1"
                        name="button1"
                        value= {reporte.buttonContent}
                        onStyleChange={(newStyle) => {
                          console.log("Nuevo estilo recibido aa:", newStyle); // Depuración
                          setReportes((prev) =>
                            prev.map((item, i) =>
                              i === index
                                ? {
                                    ...item,
                                    buttonContent: newStyle.content.value,
                                    buttonColor: newStyle.background?.color, // Uso de optional chaining
                                    buttonTextColor: newStyle.text?.color,   // Uso de optional chaining
                                  }
                                : item
                            )
                          );
                        }}
                      />
                  </Grid>

                  <Grid item xs={12}>
                <TextField fullWidth label="URL del Botón 1" value={reporte.buttonUrl} onChange={(e) =>
                  setReportes(prev => prev.map((item, i) =>
                    i === index ? { ...item, buttonUrl: e.target.value } : item
                  ))} 
                />
              </Grid> 

              





              

 


          <StylizedButtonField
            key={`button2-${reporte.id}`}
                    label="Botón 2"
                    name="button2"
                    value= {reporte.button2Content}
                    onStyleChange={(newStyle) => {
                      console.log("Nuevo estilo recibido aa:", newStyle); // Depuración
                      setReportes((prev) =>
                        prev.map((item, i) =>
                          i === index
                            ? {
                                ...item,
                                button2Content: newStyle.content.value,
                                button2Color: newStyle.background?.color, // Uso de optional chaining
                                button2TextColor: newStyle.text?.color,   // Uso de optional chaining
                              }
                            : item
                        )
                      );
            }}
          />




          <Grid item xs={12}>
            <TextField fullWidth label="URL del Botón 2" value={reporte.button2Url} onChange={(e) =>
              setReportes(prev => prev.map((item, i) =>
                i === index ? { ...item, button2Url: e.target.value } : item
              ))} 
            />
          </Grid>


                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}

          
          <Button
            variant="btnprimary"
            fullWidth
            sx={{ mt: 2 }}
            onClick={handleSave}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Guardar Todo"}
          </Button>
       

      </DynamicEditor>

    </>
  );
};

export default ReporteForm;
