import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import { auth } from '../../../../firebaseConfig';

const ProductoAddButton = ({ onClick, iconName, buttonText ,top}) => {
  
  const icons = {
    Add: AddIcon,
    DesignServices: DesignServicesIcon,
  };
  
  const IconComponent = icons[iconName];
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);


  return (
    <> 
      {user && (
        <Button
          variant="contained"
          color="primary"
          startIcon={IconComponent ? <IconComponent /> : null}
          onClick={onClick}
          sx={{
            position: 'absolute',
            top: {top},
            right: '-6.2rem',             
            transition: 'right 0.3s ease', 
            borderTopRightRadius: 0,    
            borderBottomRightRadius: 0, 
            background: 'linear-gradient(to right, #DD69E5, #A55AEE, #62CEFB)', 
            borderRight: 'none',
            '&:hover': {
              right: '0rem',               
              background: 'linear-gradient(to right, #DD69E5, #A55AEE, #62CEFB)', 
            },
          }}
        >
          {buttonText}
        </Button>
       )}
    </>
  );
};

export default ProductoAddButton;
