import React, { useState, useEffect } from 'react';
import {
  Box,
  Fab,
  Tooltip,
  Modal,
  TextField,
  Button,
  IconButton,
  Grid,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  MenuItem, 
  Select,
  InputLabel,
  DialogTitle,
  Stack
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { auth } from '../../../firebaseConfig';
import { saveToFirestore, uploadFile } from '../../../utils/firebaseUtils';
import LZString from 'lz-string';
import Diseño1 from '../ui/Diseño1';
import Diseño2 from '../ui/Diseño2';
import FooterAddButton from './button/FooterAddButton';
import DesignCarousel from '../../common/DesignCarousel';
import StyledImageUploadButton from '../../common/StyledImageUploadButton';
import DynamicEditor from '../../common/DynamicEditor';
import IconImage from "../../images/IconImage"


const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  maxHeight: '90vh',
  overflow: 'auto',
  bgcolor: 'background.paper',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

const Footer = ({ storeName, compressedFooter, isAdminRoute }) => {
  
  const [user, setUser] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [footerContent, setFooterContent] = useState({
    diseñoSeleccionado: 'diseño1', // Incluye diseñoSeleccionado aquí
    copyright: '© 2025 [store Name]. All rights reserved.',
    quickLinks: [
      { href: 'https://www.ejemplo.com', text: 'Example' },
      { href: 'https://www.ejemplo.com/acerca', text: 'Example' },
      { href: 'https://www.ejemplo.com/acerca', text: 'Example' },
    ],
    information: [
      { href: 'https://www.ejemplo.com/contacto', text: 'Example', image: 'https://cdn-icons-png.flaticon.com/512/7134/7134131.png' },
      { href: 'https://www.ejemplo.com/contacto', text: 'Example', image: 'https://cdn-icons-png.flaticon.com/512/7134/7134131.png' },
      { href: 'https://www.ejemplo.com/contacto', text: 'Example', image: 'https://cdn-icons-png.flaticon.com/512/7134/7134131.png' },

    ],
    socialLinks: [
      { icon: '/assets2/img/logo/image_11zon (1).jpg', url: 'https://facebook.com', bgColor: '#080a0c63' },
      { icon: 'https://cdn-icons-png.flaticon.com/512/7134/7134131.png', url: 'https://facebook.com', bgColor: '#080a0c63' },
      { icon: 'https://cdn-icons-png.flaticon.com/512/7134/7134131.png', url: 'https://facebook.com', bgColor: '#080a0c63' },

    ],
    footerBackgroundColor: '#0000001f',
    footerTextColor: '#000000',
    footerTextoTodo: '#000000',
  });
  const disenos = [
    {
      value: "diseño1",
      label: "Diseño 1",
      image: "/assets2/img/designs/diseño1_footer.png",
    },
    {
      value: "diseño2",
      label: "Diseño 2",
      image: "/assets2/img/designs/diseño1_footer.png",
    }
  ];


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (compressedFooter) {
      try {
        const decompressedData = LZString.decompressFromBase64(compressedFooter);
        if (decompressedData) {
          const parsedData = JSON.parse(decompressedData);
          if (parsedData) {
            setFooterContent(parsedData); // Recupera diseñoSeleccionado también
          } else {
            console.error('Los datos descomprimidos no son válidos.');
          }
        }
      } catch (error) {
        console.error('Error al descomprimir el footer:', error);
      }
    }
  }, [compressedFooter]);
  
  const handleSave = async () => {
    try {
      const footerJson = JSON.stringify(footerContent); 
      const footer = LZString.compressToBase64(footerJson); 
      await saveToFirestore(storeName, 'config', { footer }); 
      handleCloseModal();
    } catch (error) {
      console.error('Error al guardar el footer:', error);
    }
  };
  
  const handleChange = (index, e) => {
    const { name, value } = e.target;
    setFooterContent((prev) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], [name]: value };
      return updated;
    });
  };

  const handleLinkChange = (category, index, field, value) => {
    if (!footerContent[category]) return;
    const updatedLinks = [...footerContent[category]];
    if (updatedLinks[index]) {
      updatedLinks[index][field] = value;
      setFooterContent((prev) => ({ ...prev, [category]: updatedLinks }));
    }
  };

  const handleAddLink = (category) => {
    if (!footerContent[category]) return;
    setFooterContent((prev) => ({
      ...prev,
      [category]: [...prev[category], { href: '', text: '' }],
    }));
  };

  const handleRemoveLink = (category, index) => {
    if (!footerContent[category]) return;
    const updatedLinks = footerContent[category].filter((_, i) => i !== index);
    setFooterContent((prev) => ({ ...prev, [category]: updatedLinks }));
  };

  const handleSocialLinkChange = (index, field, value) => {
    if (!Array.isArray(footerContent?.socialLinks)) return;
    const updatedSocialLinks = [...footerContent.socialLinks];
    if (updatedSocialLinks[index]) {
      updatedSocialLinks[index][field] = value;
      setFooterContent((prev) => ({ ...prev, socialLinks: updatedSocialLinks }));
    }
  };

  const handleAddSocialLink = () => {
    setFooterContent((prev) => ({
      ...prev,
      socialLinks: [...prev?.socialLinks || [], { icon: '', url: '', bgColor: '#ffffff' }],
    }));
  };

  const handleRemoveSocialLink = (index) => {
    if (!Array.isArray(footerContent?.socialLinks)) return;
    const updatedSocialLinks = footerContent.socialLinks.filter((_, i) => i !== index);
    setFooterContent((prev) => ({ ...prev, socialLinks: updatedSocialLinks }));
  };

  const handleFileUpload = async (event, index, category, key) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const path = `${storeName}/footer/${category}/${file.name}`;
        const uploadedImageUrl = await uploadFile(file, path);
        setFooterContent((prev) => {
          const updatedCategory = [...prev[category] || []];
          if (updatedCategory[index]) {
            updatedCategory[index][key] = uploadedImageUrl;
          }
          return { ...prev, [category]: updatedCategory };
        });
      } catch (error) {
        console.error('Error al subir la imagen:', error);
      }
    }
  };

const handleDesignSelect = (selectedDesign) => {
  console.log('Diseño seleccionado:', selectedDesign);
  setFooterContent((prev) => ({
    ...prev,
    diseñoSeleccionado: selectedDesign, 
  }));
};

const handlePropertyChange = (property) => (e) => {
  const { value } = e.target;
  setFooterContent((prev) => ({
    ...prev,
    [property]: value,
  }));
};


  return (
    
    <div style={{ position: 'relative' }}>
    
        { footerContent.diseñoSeleccionado === 'diseño1' ? (
              
              <Diseño1 footerContent={footerContent} storeName={storeName} isAdminRoute={isAdminRoute} />
        
            ) : (
          <Diseño2 footerContent={footerContent} storeName={storeName} isAdminRoute={isAdminRoute} />
        )}
            
      <FooterAddButton  user={user} onClick={handleOpenModal} />

      <DynamicEditor
        open={openModal}
        onClose={handleCloseModal}
        title="Editar Footer"
        width={{ xs: '100%', sm: 400 }}
        modalWidth={{ xs: "80%", sm: 800 }}
      >
        
        <Box   className="!p-0 mt-2">
           
          <TextField
            fullWidth
            margin="normal"
            label="Copyright"
            name="copyright"
            value={footerContent.copyright}
            onChange={handleChange}
          />

          <Select
              fullWidth
              value={footerContent.diseñoSeleccionado}
              onChange={(e) =>
                setFooterContent((prev) => ({
                  ...prev,
                  diseñoSeleccionado: e.target.value, 
                }))
              }
              label="Seleccionar Diseño"
            >
              <MenuItem value="diseño1">Diseño 1</MenuItem>
              <MenuItem value="diseño2">Diseño 2</MenuItem>
          </Select>


        <Grid container spacing={2} className="!my-3" >
          <Grid item xs={12} sm={4}>
              <StyledImageUploadButton
                title="Color de Texto General"
                initialBackground={footerContent.footerTextoTodo} // Valor inicial del color
                onStyleChange={(newStyle) => {
                  if (newStyle.background?.color) {
                    // Actualizar el color de fondo del footer
                    handlePropertyChange('footerTextoTodo')({ target: { value: newStyle.background.color } });
                  }
                }}
              />
          </Grid>

          <Grid item xs={12} sm={4}>
            <StyledImageUploadButton
                title="Color de los Títulos"
                initialBackground={footerContent.footerTextColor} // Valor inicial del color
                onStyleChange={(newStyle) => {
                  if (newStyle.background?.color) {
                    // Actualizar el color de fondo del footer
                    handlePropertyChange('footerTextColor')({ target: { value: newStyle.background.color } });
                  }
                }}
              />
          </Grid>

          <Grid item xs={12} sm={4}>
            <StyledImageUploadButton
              title="Fondo del Footer"
              initialBackground={footerContent.footerBackgroundColor} // Valor inicial del color
              onStyleChange={(newStyle) => {
                if (newStyle.background?.color) {
                  // Actualizar el color de fondo del footer
                  handlePropertyChange('footerBackgroundColor')({ target: { value: newStyle.background.color } });
                }
              }}
            />
          </Grid>
        </Grid>




          {/* Enlaces Rápidos */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className='!font-poppins'>Enlaces Rápidos</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {footerContent?.quickLinks?.map((link, index) => (
                <Box key={index} display="flex" alignItems="center" mb={1}>
                  <TextField
                    label="Texto"
                    value={link.text}
                    onChange={(e) =>
                      handleLinkChange('quickLinks', index, 'text', e.target.value)
                    }
                    sx={{ mr: 1 }}
                  />
                  <TextField
                    label="URL"
                    value={link.href}
                    onChange={(e) =>
                      handleLinkChange('quickLinks', index, 'href', e.target.value)
                    }
                    sx={{ mr: 1 }}
                  />
                  <IconButton onClick={() => handleRemoveLink('quickLinks', index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <Button color='secondary' startIcon={<AddIcon />} onClick={() => handleAddLink('quickLinks')}>
                Agregar Enlace
              </Button>
            </AccordionDetails>
          </Accordion>

          {/* Contactanos */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className='!font-poppins'>Contáctanos</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {footerContent?.information?.map((info, index) => (
                <Box key={index} display="flex" alignItems="center" mb={2}>
                  
                  {/*
                  <Button variant="btnprimary" component="label" sx={{ mr: 1 }}>
                    Subir Imagen
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={(e) =>
                        handleFileUpload(e, index, 'information', 'image')
                      }
                    />
                  </Button>
                  */}

                  <StyledImageUploadButton
                    title="Icono contactanos"
                    initialBackground={info.image} // Valor inicial del ícono
                    onStyleChange={(newStyle) => {
                      if (newStyle.background?.imageUrl) {
                        // Actualizar el ícono de la red social
                        handleSocialLinkChange(index, 'information', newStyle.background.imageUrl);
                      }
                    }}

                  />

                  <TextField
                    label="Texto"
                    value={info.text}
                    onChange={(e) =>
                      handleLinkChange('information', index, 'text', e.target.value)
                    }
                    sx={{ mr: 1 , ml: 2}}
                  />
                  <TextField
                    label="URL"
                    value={info.href}
                    onChange={(e) =>
                      handleLinkChange('information', index, 'href', e.target.value)
                    }
                    sx={{ mr: 1 }}
                  />
                  <IconButton onClick={() => handleRemoveLink('information', index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <Button color='secondary' startIcon={<AddIcon />} onClick={() => handleAddLink('information')}>
                Agregar Enlace
              </Button>
            </AccordionDetails>
          </Accordion>
          
          {/* Centro Ayuda 
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Centro Ayuda</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {footerContent?.centroAyuda?.map((item, index) => (
                <Box key={index} display="flex" alignItems="center" mb={1}>
                  <TextField
                    label="Texto"
                    value={item.text}
                    onChange={(e) =>
                      handleLinkChange('centroAyuda', index, 'text', e.target.value)
                    }
                    sx={{ mr: 1 }}
                  />
                  <TextField
                    label="URL"
                    value={item.href}
                    onChange={(e) =>
                      handleLinkChange('centroAyuda', index, 'href', e.target.value)
                    }
                    sx={{ mr: 1 }}
                  />
                  <IconButton onClick={() => handleRemoveLink('centroAyuda', index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <Button startIcon={<AddIcon />} onClick={() => handleAddLink('centroAyuda')}>
                Agregar Enlace
              </Button>
            </AccordionDetails>
          </Accordion>
        */}

          {/* Redes Sociales */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className='!font-poppins' >Redes Sociales</Typography>
            </AccordionSummary>
            <AccordionDetails>
            {footerContent?.socialLinks?.map((social, index) => (
  <Box
    key={index}
    component={Paper}
    elevation={3}
    sx={{ p: 2, mb: 3, borderRadius: 2 }}
  >
    <Grid container spacing={2}>
      <Grid item xs={12} sm={10}>
        <TextField
          fullWidth
          label="URL"
          className='!font-poppins'
          value={social.url}
          onChange={(e) =>
            handleSocialLinkChange(index, 'url', e.target.value)
          }
        />
      </Grid>
   
      <Grid item xs={12} sm={2}>
        <IconButton
          onClick={() => handleRemoveSocialLink(index)}
          color="error"
          sx={{ mt: 2 }}
        >
          <IconImage name="delete" width="20px" height="20px" alt="Icono flecha" /> 
        </IconButton>
      </Grid>
      <Grid item xs={12} md={6}>
       
        <StyledImageUploadButton
          title="Cargar Icono de Red Social"
          initialBackground={social.icon} // Valor inicial del ícono
          onStyleChange={(newStyle) => {
            if (newStyle.background?.imageUrl) {
              // Actualizar el ícono de la red social
              handleSocialLinkChange(index, 'icon', newStyle.background.imageUrl);
            }
          }}
        />
        
      </Grid>
      <Grid item xs={12} md={3}>

        <StyledImageUploadButton
          title="Fondo icono"
          initialBackground={social.bgColor} // Valor inicial del ícono
          onStyleChange={(newStyle) => {
            if (newStyle.background?.color) {
              // Actualizar el ícono de la red social
              handleSocialLinkChange(index, 'bgColor', newStyle.background.color);
            }
          }}
        />

      </Grid>

    </Grid>
  </Box>
))}
              <Button color='secondary' startIcon={<AddIcon />} onClick={handleAddSocialLink} variant="btnprimary">
                Agregar Red Social
              </Button>
            </AccordionDetails>
          </Accordion>

          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button onClick={handleCloseModal} color='secondary' sx={{ mr: 1 }}>
              Cancelar
            </Button>
            <Button variant="btnprimary" onClick={handleSave}>
              Guardar
            </Button>
          </Box>

          
          </Box>
        

      </DynamicEditor>

    </div>
  );
};

export default Footer;
