import React, { useState, useEffect } from 'react';
import { Box, DialogContent, Typography, CircularProgress } from '@mui/material';
import { AccessTime, BarChart, Build, Settings } from '@mui/icons-material';
import './AnimatedLoader.css';

const AnimatedLoader = ({ messages: customMessages, intervalTime = 2000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Mensajes por defecto
  const defaultMessages = [
    { text: 'Trabajando en crear los componentes', icon: <Build fontSize="large" /> },
    { text: 'Analizando la información', icon: <BarChart fontSize="large" /> },
    { text: 'Configurando el sistema', icon: <Settings fontSize="large" /> },
    { text: 'Esperando respuesta', icon: <AccessTime fontSize="large" /> },
  ];

  const messages = customMessages || defaultMessages;

  // Cambiar al siguiente mensaje cada X segundos
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, intervalTime);

    return () => clearInterval(interval); // Limpiar el intervalo al desmontar
  }, [messages.length, intervalTime]);

  const currentMessage = messages[currentIndex];

  return (
    <DialogContent
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: '2rem',
        fontFamily: 'Poppins, sans-serif',
        backgroundColor: '#f9fafb', // Fondo claro
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}
      aria-live="polite"
    >
      {/* Ícono animado */}
      <Box
        sx={{
          fontSize: '48px',
          color: '#6200ea', // Color primario
          marginBottom: '1rem',
          animation: 'pulse 1.5s infinite ease-in-out',
        }}
      >
        {currentMessage.icon}
      </Box>

      {/* Texto animado */}
      <Typography
        variant="h6"
        sx={{
          fontWeight: 'bold',
          color: '#333',
          animation: 'fadeInOut 1.5s ease-in-out infinite',
        }}
      >
        {currentMessage.text}
      </Typography>

      {/* Loader */}
      <CircularProgress
        size={24}
        thickness={4}
        sx={{
          marginTop: '1.5rem',
          color: '#6200ea',
        }}
      />

      {/* Indicación para el usuario */}
      <Typography
        variant="caption"
        sx={{
          marginTop: '1rem',
          color: '#666',
          fontSize: '0.875rem',
        }}
      >
        No cierres el modal
      </Typography>
    </DialogContent>
  );
};

// Exportar componente
export default AnimatedLoader;