import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const MetaTags = ({
  title,
  description,
  image,
  imageAlt,
  url,
  type = 'website',
  locale = 'es_ES',
  twitterCard = 'summary_large_image',
  twitterSite,
  twitterCreator,
  keywords = '',
  author = '',
  publishedTime,
  modifiedTime,
  section,
  tags = '',
  appleTouchIcon,
  msTileImage,
  msTileColor,
  googleSiteVerification,
  facebookAppId,
  favicon,
  googleSearchConsoleScript,
  googleAnalyticsScript
}) => {
  // Generar datos estructurados basados en las props
  const structuredData = {
    '@context': 'https://schema.org',
    '@type': type === 'article' ? 'Article' : 'WebPage',
    'name': title,
    'headline': title,
    'description': description,
    'url': url,
    'inLanguage': locale,
    'mainEntityOfPage': url,
    'image': image ? [image] : undefined,
    'author': author ? {
      '@type': 'Person',
      'name': author,
    } : undefined,
    'datePublished': publishedTime,
    'dateModified': modifiedTime,
    'articleSection': section,
    'keywords': keywords,
    'publisher': {
      '@type': 'Organization',
      'name': 'Tu Sitio Web',
      'logo': {
        '@type': 'ImageObject',
        'url': 'https://tusitio.com/logo.png', // Reemplaza con el URL de tu logo
      },
    },
  };

  // Eliminar propiedades undefined para limpiar el objeto
  Object.keys(structuredData).forEach(
    key => structuredData[key] === undefined && delete structuredData[key]
  );

  useEffect(() => {
    if (!googleAnalyticsScript || !googleSearchConsoleScript) return; 
    const script1 = document.createElement('script');
    script1.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsScript}`;
    script1.async = true;
    document.head.appendChild(script1);

    script1.onload = () => {
      const script2 = document.createElement('script');
      script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${googleAnalyticsScript}');
      `;
      document.head.appendChild(script2);
    };

    const script3 = document.createElement('script');
    script3.src = `https://www.googletagmanager.com/gtag/js?id=${googleSearchConsoleScript}`;
    script3.async = true;
    document.head.appendChild(script3);

    script3.onload = () => {
      const script4 = document.createElement('script');
      script4.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${googleSearchConsoleScript}');
      `;
      document.head.appendChild(script4);
    };

    return () => {
      const scripts = document.querySelectorAll(`script[src="https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsScript}"]`);
      scripts.forEach(script => script.remove());
      const searchConsoleScripts = document.querySelectorAll(`script[src="https://www.googletagmanager.com/gtag/js?id=${googleSearchConsoleScript}"]`);
      searchConsoleScripts.forEach(script => script.remove());
    };
  }, []);

  return (
    <Helmet>
      {/* Meta etiquetas comunes */}
      <title>{title}</title>
      {/* Favicons */}
      <link rel="icon" type="image/png" sizes="32x32" href={image} />
      <link rel="icon" type="image/png" sizes="16x16" href={image} />
      <link rel="apple-touch-icon" href={image} />
      {/* Meta información */}
      <meta name="description" content={description} />
      {keywords && <meta name="keywords" content={keywords} />}
      {author && <meta name="author" content={author} />}
      {/* Open Graph / Facebook */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {image && <meta property="og:image" content={image} />}
      {image && <meta property="og:image:alt" content={imageAlt} />}
      <meta property="og:url" content={url} />
      <meta property="og:type" content={type} />
      <meta property="og:locale" content={locale} />
      {publishedTime && <meta property="article:published_time" content={publishedTime} />}
      {modifiedTime && <meta property="article:modified_time" content={modifiedTime} />}
      {section && <meta property="article:section" content={section} />}
      {tags && <meta property="article:tag" content={tags} />}
      {facebookAppId && <meta property="fb:app_id" content={facebookAppId} />}
      {/* Twitter */}
      <meta name="twitter:card" content={twitterCard} />
      {twitterSite && <meta name="twitter:site" content={twitterSite} />}
      {twitterCreator && <meta name="twitter:creator" content={twitterCreator} />}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {image && <meta name="twitter:image" content={image} />}
      {imageAlt && <meta name="twitter:image:alt" content={imageAlt} />}
      {/* Pinterest */}
      <meta name="pinterest-rich-pin" content="true" />
      {/* Apple Touch Icon */}
      {appleTouchIcon && <link rel="apple-touch-icon" href={appleTouchIcon} />}
      
      {/* Favicon */}
      {favicon && <link rel="icon" type="image/png" href={favicon} />}
      {/* Microsoft Tiles */}
      {msTileColor && <meta name="msapplication-TileColor" content={msTileColor} />}
      {msTileImage && <meta name="msapplication-TileImage" content={msTileImage} />}
      {/* Google Site Verification */}
      {googleSiteVerification && <meta name="google-site-verification" content={googleSiteVerification} />}
      {/* Dublin Core */}
      <meta name="DC.title" content={title} />
      <meta name="DC.description" content={description} />
      {/* Datos estructurados en JSON-LD */}
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
};

export default MetaTags;