import React from 'react';
import DynamicStyleForm from '../../common/DynamicStyleForm';

const UI2Form = ({ bannerData, handleChange }) => {
  const styleNameMap = {
    fields: {
      title2: {
        type: 'text',
        label: 'Segundo Título',
        order: 1,
        value: 'title2',
        styles: {
          color: 'title2Color',
          size: 'title2Size',
          fontFamily: 'title2FontFamily',
          bold: 'title2Bold',
          italic: 'title2Italic',
          underline: 'title2Underline',
          lineThrough: 'title2LineThrough',
          align: 'title2Align',
          letterSpacing: 'title2LetterSpacing',
          lineHeight: 'title2LineHeight'
        }
      },
      description: {
        type: 'text',
        label: 'Descripción',
        order: 2,
        value: 'description',
        styles: {
          color: 'descriptionColor',
          size: 'descriptionSize',
          fontFamily: 'descriptionFontFamily',
          bold: 'descriptionBold',
          italic: 'descriptionItalic',
          underline: 'descriptionUnderline',
          lineThrough: 'descriptionLineThrough',
          align: 'descriptionAlign',
          letterSpacing: 'descriptionLetterSpacing',
          lineHeight: 'descriptionLineHeight'
        }
      },
      buttonText: {
        type: 'text',
        label: 'Texto del Botón',
        order: 3,
        value: 'buttonText',
        styles: {
          color: 'buttonTextColor',
          size: 'buttonTextSize',
          fontFamily: 'buttonTextFontFamily',
          bold: 'buttonTextBold',
          italic: 'buttonTextItalic',
          underline: 'buttonTextUnderline',
          lineThrough: 'buttonTextLineThrough',
          align: 'buttonTextAlign',
          letterSpacing: 'buttonTextLetterSpacing',
          lineHeight: 'buttonTextLineHeight'
        }
      },
      buttonText2: {
        type: 'text',
        label: 'Texto del Botón 2',
        order: 4,
        value: 'buttonText2',
        styles: {
          color: 'buttonText2Color',
          size: 'buttonText2Size',
          fontFamily: 'buttonText2FontFamily',
          bold: 'buttonText2Bold',
          italic: 'buttonText2Italic',
          underline: 'buttonText2Underline',
          lineThrough: 'buttonText2LineThrough',
          align: 'buttonText2Align',
          letterSpacing: 'buttonText2LetterSpacing',
          lineHeight: 'buttonText2LineHeight'
        }
      },
      desktopBackground: {
        type: 'image',
        label: 'Imagen Principal',
        group: 'desktop',
        groupLabel: 'Imágenes para Web',
        order: 5,
        title: 'Subir imagen de fondo para desktop',
        properties: {
          image: 'backgroundImage',
          color: 'backgroundColor',
          imageData: 'imageData',
          position: 'backgroundPosition',
          size: 'backgroundSize',
          repeat: 'backgroundRepeat'
        },
        path: 'banners/fondo',
      },
      mobileBackground: {
        type: 'image',
        label: 'Imagen Principal',
        group: 'mobile',
        groupLabel: 'Imágenes para Web Móvil',
        order: 6,
        title: 'Subir imagen de fondo para móvil',
        properties: {
          image: 'mobileBackgroundImage',
          color: 'mobileBackgroundColor',
          imageData: 'mobileImageData',
          position: 'mobileBackgroundPosition',
          size: 'mobileBackgroundSize',
          repeat: 'mobileBackgroundRepeat'
        },
        path: 'banners/fondoMobile',
      },
      secondaryImage: {
        type: 'image',
        label: 'Imagen Secundaria',
        group: 'desktop',
        order: 7,
        title: 'Subir imagen secundaria',
        properties: {
          image: 'secondImage',
          color: 'secondaryBackgroundColor',
          imageData: 'secondaryImageData',
          position: 'secondaryBackgroundPosition',
          size: 'secondaryBackgroundSize',
          repeat: 'secondaryBackgroundRepeat'
        },
        path: 'banners/secundario',
      }
    }
  };

  return (
    <DynamicStyleForm 
      formData={bannerData} 
      onStyleChange={handleChange} 
      styleConfig={styleNameMap}
    />
  );
};

export default UI2Form;