import { useEffect, useState } from "react";
import  LoginForm  from "./LoginForm";
import { Box, Typography, Container } from "@mui/material";
import Aurora from "./components/aurora.tsx";
import './Login.css'

const Login = () => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);

    // Preload the logo image
    const img = new Image();
    img.src = "/assets2/img/logo/miCanvaLogoMin.png";

    return () => {
      setMounted(false);
    };
  }, []);

  if (!mounted) return null;

  return (
    <Box className="min-h-screen w-full flex flex-col md:flex-row overflow-hidden relative">
      <Aurora colorStops={["#CC74ED", "#A55AEE", "#62CEFB"]} blend={0.96} amplitude={1.7} speed={1.2}/>
      {/* Left side - Login form */}
      <Box className="hidden md:flex md:w-1/2 xl:w-7/12  overflow-hidden">
        <Box className="relative z-10 flex flex-col items-center justify-center w-full h-full p-12 text-center">
            <img src="/assets2/img/logo/logo letra blanca 1.png" alt="Micanva Logo" className="w-[80%] md:w-[55%] xl:w-[300px] mb-6" />
            <div className="w-[80%] xl:w-[60%]">
                <h4 className="text-[#f7f7f7] text-lg xl:text-2xl font-normal font-poppins">
                    Acceda a su espacio de trabajo creativo y colabore con su equipo en un solo lugar.
                </h4>
            </div>
        </Box>
      </Box>

      {/* Right side - Login form */}
      <Box className="w-full md:w-1/2 xl:w-5/12 flex flex-col items-center !justify-center p-4 lg:p-12">
        <Box className="flex md:hidden mb-6">
          <img src="/assets2/img/logo/logo letra blanca 1.png" alt="Micanva Logo" className="w-[9rem] mx-auto" />
        </Box>

        <LoginForm />

        <Typography variant="caption" className="text-center text-[#f7f7f7] font-poppins mt-4">
          © {new Date().getFullYear()} Micanva Portal. All rights reserved.
        </Typography>
      </Box>
    </Box>

  );
};

export default Login;