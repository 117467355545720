import React, { useState, useEffect } from "react";
import { db ,auth} from "../../firebaseConfig";
import { doc, setDoc,getDoc } from "firebase/firestore";
import decompressReporte from "./utils/decompressReporte";
import compressReporte from "./utils/compressReporte";
import ReporteForm from "./componentes/ReporteForm";
import { DesignSectionsManager } from "@misnpm/design-sections";
import ButtonAdd from "./componentes/ButtonAdd"; 




const PowerBIManager = ({ storeName, isAdminRoute, compressedReportes }) => {

  const [reportes, setReportes] = useState([]);
  const [tituloSeccion, setTituloSeccion] = useState("");
  const [diseñoSeleccionado, setDiseñoSeleccionado] = useState("UI9"); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const decompressedReportes = decompressReporte(compressedReportes || "");
  
        const configRef = doc(db, `${storeName}/config`);
        const configSnap = await getDoc(configRef);
  
        if (configSnap.exists()) {
          const configData = configSnap.data();
          setTituloSeccion(configData.tituloSeccion || "");
          setDiseñoSeleccionado(configData.diseñoSeleccionado || "UI9"); 
          setReportes(Array.isArray(decompressedReportes) ? decompressedReportes : []);
        }
      } catch (err) {
        console.error("Error al procesar los datos:", err);
        setError("Error al procesar los reportes.");
      } finally {
        setLoading(false);
      }
    };
  
    fetchConfig();
  }, [compressedReportes, storeName]);  

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSaveConfig = async (newTitulo, newDiseño, newReportes) => {
    try {
      const dataToSave = {
        tituloSeccion: newTitulo,
        diseñoSeleccionado: newDiseño,  // 🔹 Aseguramos que se guarda correctamente
        reportes: compressReporte(newReportes),
      };
  
      await setDoc(doc(db, `${storeName}/config`), dataToSave, { merge: true });
  
      setTituloSeccion(newTitulo);
      setDiseñoSeleccionado(newDiseño);  // 🔹 Se actualiza el estado con el nuevo diseño
      setReportes(newReportes);
    } catch (err) {
      console.error("Error al guardar la configuración:", err);
    }
  };
  
  const handleDeleteReporte = async (index) => {
    try {
      const updatedReportes = reportes.filter((_, i) => i !== index);
      await setDoc(
        doc(db, `${storeName}/config`),
        { reportes: compressReporte(updatedReportes) },
        { merge: true }
      );
      setReportes(updatedReportes);
    } catch (err) {
      console.error("Error al eliminar el reporte:", err);
    }
  };

  const formattedReportes = reportes.map((reporte) => ({
    title: reporte.titulo,
    description: reporte.descripcion,
    button: reporte.buttonContent,
    button2: reporte.button2Content, // Puedes agregar otro botón fijo o dinámico
    url1: reporte.buttonUrl,
    url2: reporte.button2Url, // Puedes asignar una URL predeterminada
    buttonColor: reporte.buttonColor,
    buttonColor2: reporte.button2Color,
    fontColor1: reporte.buttonTextColor ,
    fontColor2: reporte.button2TextColor,
    reportUrl: reporte.url, // URL del reporte en Power BI
  }));

  const propsUI = {
    tituloSeccion,
    items: formattedReportes.length > 0 ? formattedReportes : [], // Evitar valores nulos
  };

  return (
    
    <div className="mb-2 relative">
      
      <div className="flex justify-center items-center">
        <h1
          className="font-semibold my-5"
          style={{ color: "#000000", fontSize: "32px" }}
        >
          {tituloSeccion || "Reportes"}
        </h1>
      </div>

      {loading && <p>Cargando...</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}

      {user && (
      <ButtonAdd onClick={handleOpen} />
      )}

      {!loading && (
        <ReporteForm
          storeName={storeName}
          initialTituloSeccion={tituloSeccion}
          initialDiseñoSeleccionado={diseñoSeleccionado}
          initialReportes={reportes}
          onSave={handleSaveConfig}
          open={open} 
          handleClose={handleClose}
        />
      )}

      <DesignSectionsManager selectUI={diseñoSeleccionado} propsUI={propsUI} />

    </div>
  );
};

export default PowerBIManager;
