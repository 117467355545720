import React, { useState, useEffect } from 'react';
import decompressWsp from './utils/decompressWsp';
import compressWsp from './utils/compressWsp';
import { saveToFirestore } from '../../utils/firebaseUtils';
import { WhatsAppButtonManager } from '@misnpm/customizable-whatsapp-button'

const WhatsAppManager = ({ storeName, isAdminRoute, compressedWsp }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [whatsApp, setWhatsApp] = useState({});
  // Al montar, descomprimimos la data si existe
  useEffect(() => {
    if (compressedWsp) {
      try {
        const decompressedData = decompressWsp(compressedWsp);
        setWhatsApp(decompressedData);
      } catch (error) {
        console.error('Error al descomprimir datos de WhatsApp:', error);
      }
    }
  }, []);

  const handleSave = async (newPhoneNumber, newMessage, newPosition) => {
    try {
      // Ahora tenemos los valores actualizados
      const whatsApp = {
        phone: newPhoneNumber,  // El número de teléfono actualizado
        message: newMessage,    // El mensaje actualizado
        buttonPosition: newPosition, // La posición del botón
      };
  
  
      // Comprimir el objeto completo
      const newCompressed = compressWsp(whatsApp);
  
      // Guardar en Firestore
      await saveToFirestore(storeName, 'config', { whatsApp: newCompressed });
  
      // Descomprimir y actualizar el estado
      const decompressedData = decompressWsp(newCompressed);
  
      setWhatsApp(decompressedData);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error al guardar el servicio:', error);
    }
  };
  
  

  // Cuando el usuario suelta el botón arrastrable
  const handleDragStop = async (newPos) => {
    try {
      // Actualizamos el objeto completo
      const updatedWhatsApp = {
        ...whatsApp,
        buttonPosition: newPos,
      };
      // Lo comprimimos
      const newCompressed = compressWsp(updatedWhatsApp);
      // Lo guardamos
      await saveToFirestore(storeName, 'config', { whatsApp: newCompressed });
      // Actualizamos estado local
      setWhatsApp(updatedWhatsApp);
    } catch (error) {
      console.error('Error al guardar posición del botón:', error);
    }
  };
  // Extraemos la posición actual (o la inicial, si no existe)
  const buttonPosition = whatsApp?.buttonPosition || { x: 0, y: 0 };

  return (
    <>
      {/* Botón flotante y arrastrable */}
      <WhatsAppButtonManager
        selectUI="UI"
        propsUI={{
          isAdminRoute: isAdminRoute,
          phoneNumber: whatsApp?.phone || '',
          message: whatsApp?.message || '',
          position: buttonPosition, 
          onSave: handleSave,
          onPositionChange: handleDragStop,
        }}
      />
    </>
  );
};

export default WhatsAppManager;
