import React, { useState, useEffect } from 'react';
import TiendaManager from '../components/Tienda/TiendaManager';
import HeaderStore from '../components/Tienda/components/HeaderStore';
import { auth, googleProvider } from '../firebaseConfig';
import Aurora from "../components/NewLogin/components/aurora.tsx";


const Tiendas = () => {

  const [username, setUsername] = useState("");
  const [searchTerm, setSearchTerm] = useState(""); 
  
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUsername(user.displayName || "Usuario");
      } else {
        setUsername("Invitado");
      }
    });

    return () => unsubscribe(); // Limpiar suscripción al desmontar
  }, []);


  return (
<>

      <Aurora colorStops={["#CC74ED", "#A55AEE", "#62CEFB"]} blend={0.96} amplitude={1.7} speed={1.2}/>
    
    <div className=" bg-black w-screen min-h-screen pb-10 pt-4  ">
      
      <div className="w-full px-2 lg:px-0 mx-auto 2xl:max-w-[1516px] xl:max-w-[1240px] lg:max-w-[1004px] md:max-w-[748px] sm:max-w-[620px]">
        <HeaderStore username={username} onSearch={setSearchTerm} />
      </div>
      <TiendaManager searchTerm={searchTerm} />
      

    </div>
    </>
);
};
export default Tiendas;
