import React, { useState } from 'react';
import {
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import TrashIconBlack from "../../images/iconBlack/TrashIconBlack.webp"
import IconImage from '../../images/IconImage'

const DeleteStoreButton = ({ onDelete }) => {
  const [open, setOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    try {
      await onDelete(); 
    } catch (error) {
      console.error('Error al eliminar la tienda:', error);
    } finally {
      setIsDeleting(false);
      handleClose();
    }
  };

  return (
    <>
      <Tooltip title="Eliminar hub" className='!font-poppins' arrow>
          <button 
            className="w-8 h-8 rounded-full font-poppins bg-white/80 backdrop-blur-sm flex items-center justify-center  transition-colors"
            onClick={handleOpen}
          >
            <IconImage name="delete" width="16px" height="16px" alt="Eliminar hub" />
          </button>

      </Tooltip>

      <Dialog open={open} onClose={handleClose}>
        <Box sx={{ background: 'linear-gradient(to right, #DD69E5, #A55AEE, #62CEFB)', 
                           height:'14px',  
                           witdh:'100%' }}  ></Box>  
        <DialogTitle variant="titleModal" >Confirmar Eliminación</DialogTitle>
        <DialogContent>
          
            ¿Estás seguro de que deseas eliminar esta tienda? Esta acción no se puede deshacer.
         
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" disabled={isDeleting}>
            Cancelar
          </Button>
          <Button onClick={handleConfirmDelete}  variant="btnprimary" disabled={isDeleting}>
            {isDeleting ? 'Eliminando...' : 'Sí'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteStoreButton;