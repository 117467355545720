import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import { auth } from '../../../../firebaseConfig';

const FooterAddButton = ({  onClick }) => {

  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);



  return (
    <>
        {user && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<DesignServicesIcon />}
            onClick={onClick}
            className='z-10'
            sx={{
              position: 'absolute',
              top: '2rem',
              right: '-5rem', 
              transition: 'right 0.3s ease', 
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderRight: 'none',
              background: 'linear-gradient(to right, #DD69E5, #A55AEE, #62CEFB)', 
              color: 'white', 
              '&:hover': {
                right: '0rem', 
                background: 'linear-gradient(to right, #DD69E5, #A55AEE, #62CEFB)', 
              },
            }}
          >
            Footer
          </Button>
        )}
    </>
  );
};

export default FooterAddButton;
