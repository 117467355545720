import React, { useState, useEffect } from 'react';
import { db, auth } from '../../firebaseConfig';
import { Box } from '@mui/material';
import { saveToFirestore } from '../../utils/firebaseUtils';
import decompressGaleria from './utils/decompressGaleria';
import SlideEdgeButton from '../common/SlideEdgeButton';
import UI1 from './components/desings/UI1';
import GaleriaModal from './components/GaleriModal';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import { useModal } from '../../context/ModalContext';

const GaleriaManager = ({storeName, isAdminRoute, compressedGaleria}) => {
    const { modalActivo, abrirModal, cerrarModal } = useModal();
    const MODAL_ID = 'galeria-modal';

    const [user, setUser] = useState(null);
    const [galeriaArray, setGaleriaArray] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
          setUser(currentUser);
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchGaleria = async () => {
          if (!compressedGaleria) {
            console.error('No se proporcionaron datos comprimidos para la galeria.');
            setError('Datos de servicio no disponibles.');
            setLoading(false);
            return;
          }
          try {
            const decompressedData = decompressGaleria(compressedGaleria);
            if (!decompressedData) {
              throw new Error('Descompresión fallida.');
            }
            setGaleriaArray(decompressedData);
          } catch (err) {
            console.error('Error al procesar la galeria:', err);
            setError('Error al cargar la galeria.');
          } finally {
            setLoading(false);
          }
        };
        fetchGaleria();
    }, [storeName]);

    const handleSaveGaleria = async (compressedGaleriaData) => {
        try {
            const decompressedData = decompressGaleria(compressedGaleriaData);
            setGaleriaArray(decompressedData);
            await saveToFirestore(storeName, 'config', { galeria: compressedGaleriaData });
            cerrarModal();
        } catch (error) {
            console.error('Error al guardar imagenes de galeria:', error);
        }
    };

    const handleTempUpdate = (updatedData) => {
      // Este updatedData es el mismo shape que "decompressedData" o que se llama "dataToCompress"
      setGaleriaArray(updatedData);
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <UI1 user={user} galeriaArray={galeriaArray}/>

   
            {user && <SlideEdgeButton 
              onClick={() => abrirModal(MODAL_ID)}
              icon={DesignServicesIcon}
              label="GALERIA"
              position={{ 
                top: '3.7rem', 
                right: '-5rem' 
              }} 
            />}

            <GaleriaModal 
              open={modalActivo === MODAL_ID}
              onClose={cerrarModal}
              onSave={handleSaveGaleria}
              storeName={storeName}
              initialGaleria={galeriaArray}
              onTempUpdate={handleTempUpdate}
            />
        </Box>
    );
}

export default GaleriaManager;