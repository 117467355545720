import React, { useState, useEffect, useRef } from "react";
import LogoPreview from '../../Logo/components/LogoPreview';
import { Search } from 'lucide-react';
import { signOut } from 'firebase/auth'; 
import { auth } from '../../../firebaseConfig';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button,Box } from '@mui/material';

const HeaderStore = ({ username, onSearch }) => {
    const [iniciales, setIniciales] = useState("");
    const [searchTerm, setSearchTerm] = useState(""); // Estado del término de búsqueda
    const [menuVisible, setMenuVisible] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false); // Estado para el diálogo de confirmación
    const menuRef = useRef(null); // Referencia para detectar clics fuera del menú

    useEffect(() => {
        if (username) {
            const obtenerIniciales = (username) => {
                const nombres = username.trim().split(" ");
                if (nombres.length >= 2) {
                    return nombres[0][0].toUpperCase() + nombres[1][0].toUpperCase();
                }
                return nombres[0][0]?.toUpperCase() || "";
            };
            setIniciales(obtenerIniciales(username));
        }
    }, [username]);

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        onSearch(value);
    };

    const handleLogout = async () => {
        try {
            await signOut(auth);
            console.log('Sesión cerrada');
            window.location.href = '/';
        } catch (error) {
            console.error('Error al cerrar sesión:', error.message);
        }
    };

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    // Abre el diálogo de confirmación
    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    // Cierra el diálogo sin cerrar sesión
    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    // Cierra sesión al confirmar
    const handleConfirmLogout = () => {
        setDialogOpen(false);
        handleLogout();
    };

    // Cierra el menú si se hace clic fuera de él
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <header className="relative w-full bg-white flex items-center justify-between my-3 py-5 px-6 shadow-lg rounded-2xl">
            <LogoPreview logo="assets2/img/logo/micanva.png" width="150" height="40" className="hidden md:flex"/>
            <LogoPreview logo="assets2/img/logo/miCanvaLogoMin.png" width="40" height="40" className="flex md:hidden"/>
            <div className="flex items-center gap-4">
                <div className="relative">
                    <input
                        type="text"
                        placeholder="Buscar hubs..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="pl-10 pr-4 py-2 font-poppins rounded-lg bg-white/50 border border-slate-200 focus:outline-none focus:ring-2 focus:ring-micanva-purple/20 transition-all w-[165px] sm:!w-[250px]"
                    />
                    <Search className="absolute left-3 !top-[70%] transform -translate-y-1/2 text-slate-400 w-4 h-4" />
                </div>
                <div className="flex items-center  relative" ref={menuRef}>
                    <div className="flex  items-center gap-1.5" onClick={toggleMenu}>
                        <div
                            className="w-9 h-9 rounded-full bg-[#e9ecfb] flex items-center justify-center cursor-pointer"
                            
                        >
                            <span className="font-poppins text-[#6162F8] font-semibold">{iniciales || "U"}</span>
                        </div>
                        <img src="assets2/img/logo/dropdown.png" alt="Arrow Down" 
                             className={`!transform transition-transform duration-300 rotate-90 opacity-75 ${menuVisible ? 'rotate-270' : 'rotate-90'} w-[8px] h-[12px]`}  />
                    </div>

                    {/* Menú desplegable */}
                    {menuVisible && (
                        <div className="font-poppins z-10 absolute top-12 right-0 bg-white shadow-md rounded-[4px] w-40 border">
                            <p className="text-black text-sm px-4 py-2 mb-0">{username}</p> 
                            <button
                                className="w-full flex items-center gap-2 text-left px-4 py-3 text-sm text-gray-700 hover:bg-gray-100"
                                onClick={handleOpenDialog}
                            >
                                Cerrar sesión
                                <img src="assets2/img/logo/logOut.png" alt="Cerrar sesión" className="w-4 h-4 opacity-50" />
                            </button>
                        </div>
                    )}
                </div>
            </div>

            {/* Diálogo de confirmación de cierre de sesión */}
            
            

            <Dialog open={dialogOpen} onClose={handleCloseDialog} >
                <Box sx={{ background: 'linear-gradient(to right, #DD69E5, #A55AEE, #62CEFB)', 
                           height:'14px',  
                           witdh:'100%' }}  ></Box>    
                <DialogTitle variant="titleModal" className="!font-poppins">Cerrar sesión</DialogTitle>
                <DialogContent className="!font-poppins">
                    ¿Estás seguro de que deseas cerrar sesión?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="secondary" className="!font-poppins">
                        Cancelar
                    </Button>
                    <Button variant="btnprimary" onClick={handleConfirmLogout}  className="!font-poppins">
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>

        </header>
    );
};

export default HeaderStore;
