import React, { useState, useContext } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconImage from "../../images/IconImage";
import CustomAlert from "../../CustomAlert/CustomAlert";
import AnimatedLoader from "./AnimatedLoader";
import { getAuthenticatedUser, } from '../../../utils/firebaseUtils';

const AiForm = ({ open, onClose }) => {
  const email = getAuthenticatedUser();
  const [formData, setFormData] = useState({
    storeNameIA: "",
    descriptionIA: "",
    colors: ["", "", ""],
    services: [{ title: "", description: "" }],
  });
  const [logoWeb, setLogoWeb] = useState(null);
  const [logoMobile, setLogoMobile] = useState(null);
  const [error, setError] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("");
  const storeNameRegex = /^[a-zA-Z0-9]*$/;
  const [storeNameError, setStoreNameError] = useState("");
  // Manejar cambios en los campos del formulario
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "storeNameIA") {
      if (!storeNameRegex.test(value)) {
        setStoreNameError("Solo se permiten letras y números.");
        setError(true);
        return;
      }
      setStoreNameError("");
      setError(false);
    }

    setFormData({
      ...formData,
      [name]: value,
    });


    if (name.startsWith("color")) {
      const index = parseInt(name.split("-")[1], 10);
      const newColors = [...formData.colors];
      newColors[index] = value;
      setFormData({
        ...formData,
        colors: newColors,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // Manejar cambios en los servicios
  const handleServiceChange = (index, field, value) => {
    const newServices = [...formData.services];
    newServices[index][field] = value;
    setFormData({
      ...formData,
      services: newServices,
    });
  };

  // Agregar un nuevo servicio
  const handleAddService = () => {
    setFormData({
      ...formData,
      services: [...formData.services, { title: "", description: "" }],
    });
  };

  // Eliminar un servicio
  const handleRemoveService = (index) => {
    if (formData.services.length <= 1) return;
    const newServices = formData.services.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      services: newServices,
    });
  };

  // Subir archivos (logos)
  const handleFileUpload = (type, file) => {
    if (!["image/svg+xml", "image/png", "image/webp"].includes(file.type)) {
      setAlertMessage("Formato no válido. Solo se permiten archivos SVG, PNG y WEBP.");
      setAlertType("error");
      setShowAlert(true);
      return;
    }
    if (file.size > 500 * 1024) {
      setAlertMessage("El archivo excede el tamaño máximo permitido de 500 KB.");
      setAlertType("warning");
      setShowAlert(true);
      return;
    }
    if (type === "web") {
      setLogoWeb(URL.createObjectURL(file));
    } else if (type === "mobile") {
      setLogoMobile(URL.createObjectURL(file));
    }
    setShowAlert(false);
  };

  // Resetear el formulario
  const resetForm = () => {
    setFormData({
      storeNameIA: "",
      descriptionIA: "",
      colors: ["", "", ""],
      services: [{ title: "", description: "" }],
    });
    setLogoWeb(null);
    setLogoMobile(null);
    setError(false);
    setSelectedCategory("");
    setStep(1);
  };

  // Enviar el formulario
  const handleSubmit = async () => {
    if (
      !formData.storeNameIA ||
      !formData.descriptionIA ||
      formData.colors.includes("") ||
      formData.services.some((service) => !service.title || !service.description)
    ) {
      setError(true);
      return;
    }
    setIsLoading(true);
    setStep(3);
    const transformedStoreName = formData.storeNameIA.toLowerCase().replace(/\s+/g, "");
    const payload = {
      nombre_web: transformedStoreName,
      descripcion_web: formData.descriptionIA,
      colores_web: formData.colors,
      servicios_web: formData.services.map((service) => ({
        titulo: service.title,
        descripcion: service.description,
      })),
      secciones_web: ["banner"],
      diseño_web: selectedCategory,
      correo_usuario: email,
    };

    try {
      const response = await fetch(
        "https://langchatserver-production-06fe.up.railway.app/api/openai-design/generateDesign",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        console.log("Datos enviados correctamente:", payload);
        resetForm();
        onClose();
      } else {
        console.error("Error al enviar los datos:", response.statusText);
        setAlertMessage("Hubo un error al enviar los datos. Por favor, intenta nuevamente.");
        setAlertType("error");
        setShowAlert(true);
        //alert("Hubo un error al enviar los datos. Por favor, intenta nuevamente.");
      }
    } catch (error) {
      console.error("Error de red:", error);
      setAlertMessage("Ocurrió un error de red. Por favor, verifica tu conexión e intenta nuevamente.");
      setAlertType("error");
      setShowAlert(true);
      //alert("Ocurrió un error de red. Por favor, verifica tu conexión e intenta nuevamente.");
    } finally {
      setIsLoading(false);
      window.location.reload();
    }
  };

  // Renderizado condicional según el paso
  const renderStep = () => {
    if (step === 1) {
      // Array de categorías
      const categories = [
        //{ value: "LMS", label: "LMS (Learning Management System)" },
        { value: "e-commerce", label: "E-commerce (Comercio Electrónico)" },
        { value: "landing page", label: "Landing Pages (Páginas de Aterrizaje)" },
        { value: "portafolio", label: "Portafolio" },
        { value: "blog", label: "Blog" },
        //{ value: "PaginasCorporativas", label: "Páginas Corporativas" },
      ];
    
      return (
        <>
          <Box sx={{ background: 'linear-gradient(to right, #DD69E5, #A55AEE, #62CEFB)', height:'14px', witdh:'100%' }} ></Box>
          <DialogTitle className="font-poppins text-center font-bold">
            Selecciona una categoría
          </DialogTitle>
          <DialogContent className="!font-poppins" style={{ padding: "10px 14px" }}>
            <RadioGroup
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              sx={{
                display: { xs: 'flex', md: 'grid' }, // Cambia entre flex y grid según el breakpoint
                flexDirection: { xs: 'column', md: 'unset' }, // Solo aplicar column en dispositivos pequeños
                gridTemplateColumns: { md: 'repeat(2, 1fr)' }, // Aplicar grid solo en md o superior
                gap: '10px',
              }}
            >
              {/* Usar .map() para renderizar las opciones */}
              {categories.map((category) => (
                <div
                  key={category.value}
                  className="p-2 rounded-[15px] hover:bg-[#e5e7eb] transition duration-200 ease-in-out"
                >
                  <FormControlLabel
                    value={category.value}
                    control={<Radio />}
                    label={category.label}
                    className="w-full cursor-pointer"
                  />
                </div>
              ))}
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onClose}
              color="secondary"
              sx={{ fontSize: "14px", fontFamily: "Poppins!important" }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              disabled={!selectedCategory}
              onClick={() => setStep(2)}
              sx={{background: "linear-gradient(to right, #DD69E5, #A55AEE, #62CEFB)",fontSize: "14px",fontFamily: "Poppins!important",}}>
              Siguiente
            </Button>
          </DialogActions>
        </>
      );
    } else if (step === 2) {
      return (
        <>
          <Box sx={{ background: 'linear-gradient(to right, #DD69E5, #A55AEE, #62CEFB)',height:'14px', witdh:'100%' }} ></Box>
          {/* Título con ícono de regreso */}
          
          <DialogTitle className="font-poppins flex items-center justify-center relative">
            {/* Ícono de regreso */}
            <IconButton
              onClick={() => setStep(1)}
              sx={{
                position: "absolute",
                left: 8,
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              <IconImage name="arrow" width="20px" height="20px" alt="Icono flecha" style={{transform: 'rotate(270deg)'}}/>
            </IconButton>
            {/* Texto del título */}
            <span className="w-[141px] md:w-auto text-center">Formulario de Registro</span>
          </DialogTitle>
          <DialogContent className="!font-poppins" style={{ padding: "10px 14px" }}>
            <Box className="flex flex-col gap-4 p-2">
              {/* Nombre de Tienda */}
              <TextField
                name="storeNameIA"
                label="Nombre de Tienda"
                value={formData.storeNameIA}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                className="!font-poppins"
                //error={error && !formData.storeNameIA}
                //helperText={error && !formData.storeNameIA ? "Este campo es obligatorio" : ""}
                error={!!storeNameError}
                helperText={storeNameError}
              />
              {/* Descripción de la tienda */}
              <TextField
                name="descriptionIA"
                label="Descripción de la tienda"
                value={formData.descriptionIA}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                className="!font-poppins"
                error={error && !formData.descriptionIA}
                helperText={error && !formData.descriptionIA ? "Este campo es obligatorio" : ""}
                multiline
                rows={4}
              />
              {/* Botones para subir logos */}
              <Box className="flex gap-4 items-center">
                <Button
                  variant="outlined"
                  component="label"
                  sx={{ fontSize: "12px", fontFamily: "Poppins!important" }}
                  color="secondary"
                >
                  Logo Web
                  <input
                    type="file"
                    accept=".svg,.png,.webp"
                    hidden
                    onChange={(e) => handleFileUpload("web", e.target.files[0])}
                  />
                </Button>
                <Button
                  variant="outlined"
                  component="label"
                  sx={{ fontSize: "12px", fontFamily: "Poppins!important" }}
                  color="secondary"
                >
                  Logo Mobile
                  <input
                    type="file"
                    accept=".svg,.png,.webp"
                    hidden
                    onChange={(e) => handleFileUpload("mobile", e.target.files[0])}
                  />
                </Button>
              </Box>
              {/* Vista previa de los logos */}
              <Box className="flex gap-4">
                {logoWeb && (
                  <Box className="relative" style={{ background: "linear-gradient(to right, #D3D3D3,rgb(184, 184, 184),rgb(145, 145, 145))" }}>
                    <IconButton
                      size="small"
                      className="absolute top-0 right-0 bg-white rounded-full"
                      onClick={() => setLogoWeb(null)}
                    >
                      <IconImage name="close" width="13px" height="13px" alt="Editar manualmente" />
                    </IconButton>
                    <img src={logoWeb} alt="Logo Web" className="w-20 h-20 object-contain p-1" />
                  </Box>
                )}
                {logoMobile && (
                  <Box className="relative" style={{ background: "linear-gradient(to right, #D3D3D3,rgb(184, 184, 184),rgb(145, 145, 145))" }}>
                    <IconButton
                      size="small"
                      className="absolute top-0 right-0 bg-white rounded-full"
                      onClick={() => setLogoMobile(null)}
                    >
                      <IconImage name="close" width="13px" height="13px" alt="Editar manualmente" />
                    </IconButton>
                    <img src={logoMobile} alt="Logo Mobile" className="w-20 h-20 object-contain p-1" />
                  </Box>
                )}
              </Box>
              {/* Resto del formulario */}
              <Box>
                <h3 className="font-poppins mb-2">Selecciona tres colores de tu preferencia</h3>
                <div className="flex gap-4">
                  {formData.colors.map((color, index) => (
                    <div
                      key={index}
                      className="w-10 h-10 rounded-full flex flex-col items-center justify-center border border-gray-300"
                      style={{ backgroundColor: color }}
                    >
                      <input
                        type="color"
                        name={`color-${index}`}
                        value={color}
                        onChange={handleChange}
                        className="w-full h-full cursor-pointer opacity-0 mt-2"
                      />
                    </div>
                  ))}
                </div>
              </Box>
              {/* Accordion para Servicios */}
              <Box>
                <h3 className="font-poppins mb-2">Coméntanos sobre los servicios que ofreces</h3>
                {formData.services.map((service, index) => (
                  <Accordion key={index} className="font-poppins">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      <Box className="flex justify-between w-full items-center">
                        <Typography>{service.title || `Servicio ${index + 1}`}</Typography>
                        <IconButton
                          size="small"
                          color="error"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveService(index);
                          }}
                          style={{ display: formData.services.length <= 1 ? "none" : "inline-flex" }}
                          disabled={formData.services.length <= 1}
                        >
                          <IconImage name="delete" width="18px" height="18px" alt="Icono eliminar" />
                        </IconButton>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails className="flex flex-col md:flex-row gap-4">
                      <TextField
                        label="Título"
                        value={service.title}
                        onChange={(e) => handleServiceChange(index, "title", e.target.value)}
                        fullWidth
                        variant="outlined"
                        className="!font-poppins mb-2"
                        error={error && !service.title}
                        helperText={error && !service.title ? "Este campo es obligatorio" : ""}
                      />
                      <TextField
                        label="Descripción corta"
                        value={service.description}
                        onChange={(e) => handleServiceChange(index, "description", e.target.value)}
                        fullWidth
                        variant="outlined"
                        className="!font-poppins mb-2"
                        error={error && !service.description}
                        helperText={error && !service.description ? "Este campo es obligatorio" : ""}
                      />
                    </AccordionDetails>
                  </Accordion>
                ))}
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 2, fontSize: "12px", fontFamily: "Poppins!important" }}
                  onClick={handleAddService}
                >
                  Agregar Servicio
                </Button>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleSubmit}
              //onClick={() => setStep(3)}
              variant="contained"
              sx={{
                background: "linear-gradient(to right, #DD69E5, #A55AEE, #62CEFB)",
                fontSize: "14px",
                fontFamily: "Poppins!important",
              }}
            >
              {/*{isLoading ? <CircularProgress size={24} color="inherit" /> : "Enviar"}*/}
              Enviar
            </Button>
          </DialogActions>
        </>
      );
    } else if (step === 3){
      return (
        <>
          <Box sx={{ background: 'linear-gradient(to right, #DD69E5, #A55AEE, #62CEFB)',height:'14px', witdh:'100%' }} ></Box>
          <AnimatedLoader/>
        </>
      );
    }
  };

  return (
    <>
      {showAlert && (
        <CustomAlert
          tipo={alertType}
          mensaje={alertMessage}
          open={showAlert}
          onClose={() => setShowAlert(false)}
        />
      )}
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        {renderStep()}
      </Dialog>
    </>
  );
};

export default AiForm;