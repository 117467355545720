import React, { useState , useEffect } from 'react';
import { Grid, Typography,Button } from '@mui/material';
import DynamicEditor from '../common/DynamicEditor';
import DesignCarousel from '../common/DesignCarousel';
import UI1Form from './UIForm/UI1Form';
import UI2Form from './UIForm/UI2Form';
import UI3Form from './UIForm/UI3Form';

const BannerEditorModal = ({ open, handleClose, bannerData, handleChange, handleSubmit, loading }) => {
  const [currentBannerType, setCurrentBannerType] = useState(bannerData.bannerType || 'UI1');
  useEffect(() => {
    if (bannerData.bannerType) {
      setCurrentBannerType(bannerData.bannerType);
    }
  }, [bannerData.bannerType]);

  const disenos = [
    { value: "UI1", label: "Diseño 1", image: "/assets2/img/designs/diseño1_banner.png" },
    { value: "UI2", label: "Diseño 2", image: "/assets2/img/designs/diseño2_banner.png" },
    { value: "UI3", label: "Diseño 3", image: "/assets2/img/designs/diseño3_banner.png" }
  ];

  const renderForm = () => {
    switch(currentBannerType) {
      case 'UI1':
        return <UI1Form bannerData={bannerData} handleChange={handleChange} />;
      case 'UI2':
        return <UI2Form bannerData={bannerData} handleChange={handleChange} />;
      case 'UI3':
        return <UI3Form bannerData={bannerData} handleChange={handleChange} />;
      default:
        return <UI1Form bannerData={bannerData} handleChange={handleChange} />;
    }
  };

  return (
    <DynamicEditor
      open={open}
      onClose={handleClose}
      title="Diseño del Banner"
      width={{ xs: '100%', sm: 400 }}
      modalWidth={{ xs: "90%", sm: 800 }}
    >
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <DesignCarousel
              designs={disenos}
              selectedDesign={currentBannerType}
              onSelect={(selectedDesign) => {
                setCurrentBannerType(selectedDesign);
                handleChange({ target: { name: 'bannerType', value: selectedDesign } });
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Contenido del Banner
            </Typography>
            {renderForm()}
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              className="w-full px-4 py-2 mt-4 text-white rounded-md bg-gradient-to-r from-[#DD69E5] via-[#A55AEE] to-[#62CEFB]"
              disabled={loading}
              variant="btnprimary"
            >
              {loading ? 'Guardando...' : 'Guardar Cambios'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </DynamicEditor>
  );
};

export default BannerEditorModal;