import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent } from '@mui/material';
import LZString from 'lz-string';
import { db, collection, addDoc, getDocs } from '../../../../firebaseConfig';
import PoliticaPrivacidadEditButton from '../PoliticaPrivacidadEditButton';
import PoliticaPrivacidadAddButton from '../PoliticaPrivacidadAddButton';
import PoliticaSolid from '../../../../pages/PoliticaSolid';
import PoliticaPrivacidadModal from '../PoliticaPrivacidadModal'

const PoliticaPrivacidadDesing1 = ({ storeName, isAdminRoute }) => {
  const [politicas, setPoliticas] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPolitica, setSelectedPolitica] = useState(null);

  // Fetch policies from Firebase
  useEffect(() => {
    const fetchPoliticas = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, `${storeName}/politicas/entry`));
        const politicasFromFirestore = querySnapshot.docs.map((doc) => {
          const compressedContent = doc.data().content;
          const decompressedContent = LZString.decompressFromBase64(compressedContent);
          return { id: doc.id, content: decompressedContent };
        });
        setPoliticas(politicasFromFirestore);
      } catch (error) {
        console.error('Error al obtener las políticas:', error);
      }
    };

    fetchPoliticas();
  }, [storeName]);

  // Open modal for editing
  const handleEditPolitica = (politica) => {
    setSelectedPolitica(politica);
    setIsModalOpen(true);
  };

  // Close modal
  const handleCloseModal = () => {
    setSelectedPolitica(null);
    setIsModalOpen(false);
  };

  return (
    <div className="relative px-[30px] lg:px-[81px] mt-4">

      {politicas.length > 0 ? (
        politicas.map((politica) => (
          <div
          key={politica.id}
          className="mb-4"
          >
            <div
              className="text-base text-gray-800 text-left"
              dangerouslySetInnerHTML={{ __html: politica.content }}
              ></div>
              {isAdminRoute && (
                <PoliticaPrivacidadEditButton onClick={() => handleEditPolitica(politica)}/>
              )}
          </div>
        ))
      ) : (
        <div className="h-[70vh] flex items-center justify-center">
          <p>No tienes politicas de proteccion de datos</p>
          <PoliticaPrivacidadAddButton onClick={() => handleEditPolitica(null)}/>
        </div>
      )}

      {isModalOpen && (
        <PoliticaPrivacidadModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          politica={selectedPolitica}
          storeName={storeName}
          setPoliticas={setPoliticas}
        />
      )}
    </div>

  );
};

export default PoliticaPrivacidadDesing1;