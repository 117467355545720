import React, { useState, useEffect } from 'react';
import { Box, MobileStepper, Button, Typography } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const DesignCarousel = ({ designs, selectedDesign, onSelect }) => {


  const initialStep = () => {
    const index = designs.findIndex(design => design.value === selectedDesign);
    if (index === -1) {
      // Si no encuentra el diseño, selecciona el primero y notifica
      onSelect(designs[0].value);
      return 0;
    }
    return index;
  };
  const [activeStep, setActiveStep] = useState(initialStep());
  
  useEffect(() => {
    const newIndex = designs.findIndex(design => design.value === selectedDesign);
    if (newIndex !== -1 && newIndex !== activeStep) {
      setActiveStep(newIndex);
    }
  }, [selectedDesign]);

  const handleNext = () => {
    setActiveStep((prevStep) => {
      const nextStep = prevStep + 1;
      onSelect(designs[nextStep].value);
      return nextStep;
    });
  };

  const handleBack = () => {
    setActiveStep((prevStep) => {
      const nextStep = prevStep - 1;
      onSelect(designs[nextStep].value);
      return nextStep;
    });
  };

  return (
    <Box sx={{ maxWidth: '100%', height: '300px', position: 'relative' ,  marginBottom: '2rem' }}>
      <Typography 
        variant="h6" 
        sx={{ 
          textAlign: 'left',
          mb: 1,
          fontWeight: 'medium',
          color: '#333',
          fontSize: '16px'
        }}
      >
        Elige diseño {activeStep + 1}
      </Typography>
      
      <Box sx={{ 
        height: '250px',
        overflow: 'hidden',
        '& img': {
          width: '100%',
          height: '250px',
          objectFit: 'scale-down', 
          display: 'block',
          backgroundColor: "#cccccc2e"
        }
      }}>
        <img
          src={designs[activeStep]?.image}
          alt={designs[activeStep]?.label}
          width="100%"
        />
      </Box>

      <MobileStepper
        steps={designs.length}
        position="static"
        activeStep={activeStep}
        sx={{
          background: 'transparent',
          padding: '8px 0'
        }}
        nextButton={
          <Button 
            size="small" 
            onClick={handleNext} 
            disabled={activeStep === designs.length - 1}
          >
            Siguiente
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button 
            size="small" 
            onClick={handleBack} 
            disabled={activeStep === 0}
          >
            <KeyboardArrowLeft />
            Anterior
          </Button>
        }
      />
    </Box>
  );
};

export default DesignCarousel;