import React, { useEffect, useState } from 'react';
import LZString from 'lz-string';
import { auth } from '../../firebaseConfig';
import { DesignManager } from '@misnpm/design-banners';
import SlideEdgeButton from '../common/SlideEdgeButton';
import BannerEditorModal from './BannerEditorModal';
import {saveToFirestore,uploadFile} from '../../utils/firebaseUtils';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import UI1Form from './UIForm/UI1Form';
import UI2Form from './UIForm/UI2Form';
import UI3Form from './UIForm/UI3Form';
import { useModal } from '../../context/ModalContext';

const BannerManager = ({ storeName, compressedBanner }) => {
  const { modalActivo, abrirModal, cerrarModal } = useModal();
  const MODAL_ID = 'banner-modal';

  const [user, setUser] = useState(null);
  const [bannerData, setBannerData] = useState({});
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (compressedBanner) {
      const decompressedData = LZString.decompressFromBase64(compressedBanner);
      if (decompressedData) {
        const parsedBannerData = JSON.parse(decompressedData);
        setBannerData(parsedBannerData);
      } else {
        console.error('Error al descomprimir los datos del banner.');
      }
    }
  }, [compressedBanner]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setBannerData((prev) => ({ ...prev, [name]: value }));
  };

  const getFormConfig = (type) => {
    const forms = {
      UI1: UI1Form.styleNameMap,
      UI2: UI2Form.styleNameMap,
      UI3: UI3Form.styleNameMap
    };
    return forms[type]?.fields || {};
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const updatedData = { ...bannerData };
      const currentFormConfig = getFormConfig(bannerData.bannerType);
      
      // Obtener campos de imagen y sus rutas del formulario actual
      const imageFields = Object.values(currentFormConfig)
        .filter(field => field.type === 'image')
        .reduce((acc, field) => {
          if (field.properties?.image) {
            acc[field.properties.image] = field.properties.path || `banners/${field.properties.image}`;
          }
          return acc;
        }, {});

      // Procesar solo las imágenes definidas en el formulario
      await Promise.all(
        Object.entries(bannerData)
          .filter(([key, value]) => value instanceof File && imageFields[key])
          .map(async ([key, file]) => {
            updatedData[key] = await uploadFile(
              file,
              `${storeName}/${imageFields[key]}`
            );
          })
      );

      const bannerJson = JSON.stringify(updatedData);
      const banner = LZString.compressToBase64(bannerJson);
      
      await saveToFirestore(storeName, 'config', { banner });
      setBannerData(updatedData);
      cerrarModal();

    } catch (error) {
      console.error('Error al guardar el banner:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative">
      <DesignManager
        selectUI={bannerData.bannerType}
        propsUI={{ ...bannerData, storeName, borrardeliberia: () => {} }}
      />

      {user && <SlideEdgeButton 
        onClick={() => abrirModal(MODAL_ID)}
        icon={DesignServicesIcon}
        label="BANNER"
        position={{ 
          top: '1rem', 
          right: '-4.5rem' 
        }} 
      />}

      <BannerEditorModal
        open={modalActivo === MODAL_ID}
        handleClose={cerrarModal}
        bannerData={bannerData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        loading={loading}
      />
    </div>
  );
};

export default BannerManager;