import React, { useState, useEffect } from 'react';
import { Box, IconButton, Typography, Grid, Drawer, Modal, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import WebAssetIcon from '@mui/icons-material/WebAsset';

const DynamicEditor = ({ 
  open, 
  onClose, 
  title,
  children,
  initialDisplayMode = "push",
  width = { xs: '100%', sm: 400 },
  modalWidth = { xs: "90%", sm: 800 }
}) => {
  const [displayMode, setDisplayMode] = useState(initialDisplayMode);



  useEffect(() => {
    if (open && displayMode === "push") {
      document.body.classList.add('drawer-open');
    } else {
      document.body.classList.remove('drawer-open');
    }
    
    return () => document.body.classList.remove('drawer-open');
  }, [open, displayMode]);

  const toggleDisplayMode = () => {
    if (displayMode === "push") setDisplayMode("float");
    else if (displayMode === "float") setDisplayMode("modal");
    else setDisplayMode("push");
  };

  const getModeIcon = () => {
    switch (displayMode) {
      case "push": return {
        icon: <PushPinIcon />,
        tooltip: "Cambiar a modo flotante"
      };
      case "float": return {
        icon: <PushPinOutlinedIcon />,
        tooltip: "Cambiar a modo modal"
      };
      case "modal": return {
        icon: <WebAssetIcon />,
        tooltip: "Cambiar a modo que empuja contenido"
      };
    }
  };

  const modeIcon = getModeIcon();
  
  const content = (
    <>
      <Box sx={{ 
        background: 'linear-gradient(to right, #DD69E5, #A55AEE, #62CEFB)', 
        height:'14px',
        width:'100%'
      }}/>

      <Box sx={{ position: 'relative', height: '100%', overflowY: 'auto' }}>
        <Box sx={{ position: 'absolute', right: 8, top: 8, display: 'flex', gap: 1 }}>
          <Tooltip title={modeIcon.tooltip}>
            <IconButton onClick={toggleDisplayMode} sx={{ color: 'grey.500' }}>
              {modeIcon.icon}
            </IconButton>
          </Tooltip>
          <IconButton onClick={onClose} sx={{ color: 'grey.500' }}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box className="px-[32px] pb-[32px] pt-[24px]">
          {title && (
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {title}
            </Typography>
          )}
          {children}
        </Box>
      </Box>
    </>
  );

  if (displayMode === "modal") {
    return (
      <Modal open={open} onClose={onClose}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: modalWidth,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 0,
          maxHeight: '94vh',
          display: 'flex',
          flexDirection: 'column'
        }}>
          {content}
        </Box>
      </Modal>
    );
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      variant={displayMode === "push" ? "persistent" : "temporary"}
      ModalProps={{
        keepMounted: true,
        disableScrollLock: displayMode === "push",
      }}
      sx={{
        zIndex: 1300,
        width: open ? width : 0,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: width,
          boxSizing: 'border-box',
          boxShadow: '-5px 0 15px rgba(0, 0, 0, 0.1)',
          border: 'none',
          transition: 'width 0.3s ease-in-out'
        },
      }}
    >
      {content}
    </Drawer>
  );
};

export default DynamicEditor;