import React from 'react';
import { Box, Grid } from '@mui/material';
import SkeletonLoader from './SkeletonLoader';
import { DesignSectionsManager } from "@misnpm/design-sections";

const ServicioPreview = ({ serviciosArray, width = 300, height = 200, user }) => {
  if (!serviciosArray) {
    return (
      <Grid container spacing={2}>
        {Array.from({ length: 4 }).map((_, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <SkeletonLoader width={width} height={height} />
          </Grid>
        ))}
      </Grid>
    );
  }


  {/*
    const diseno = serviciosArray?.disenoSeleccionado || "UI5";
    const showImage = diseno === "UI7" && serviciosArray.image;
    const image = showImage ? serviciosArray.image : null;
  */}

  const diseno = serviciosArray?.disenoSeleccionado || "UI5";
  const showImage = diseno === "UI7"; 
  const image = serviciosArray?.image || "https://dummyimage.com/800x900/d4d4d4/000000";

  const propsUI = {
    ...serviciosArray,
    items: serviciosArray?.servicios || [], 
  };


 

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        minHeight: height,
        padding: 2,
        borderRadius: 2,
        backgroundColor: 'white',
      }}
    >
        <h1 className="my-5  font-semibold  text-center" style={{ color: serviciosArray.tituloSeccionColor , fontSize: '32px' }}>
          {serviciosArray.tituloSeccion || "Servicios"}
        </h1>
      

      <Grid container spacing={4} alignItems="flex-start" sx={{ maxWidth: '1300px', margin: '0 auto', width: '100%' }}>

        {showImage && (
          <Grid item xs={12} lg={6}  >
            <Box
              component="img"
              src={image}
              alt="feature"
              sx={{
                width: '100%',
                height: { xs: '200px', sm: '250px', md: '300px', lg: '694px' },
                borderRadius: 2,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                border: '1px solid #ddd',
              }}
            />
          </Grid>
        )}

        <Grid item xs={12} lg={showImage ? 6 : 12} sx={{ paddingLeft: '0 !important'}}>
          <DesignSectionsManager 
              selectUI={diseno} 
              propsUI={propsUI} 
              
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServicioPreview;
