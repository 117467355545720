import React, { useState, useEffect } from 'react';
import BlogTiktokShare from "./BlogTiktokShare";
import BlogSkeleton from "./BlogSkeleton"
import { auth, db  } from '../../../firebaseConfig';
import Slider from "react-slick";
import { doc, updateDoc } from 'firebase/firestore';
import {deleteDocument} from '../../../utils/firebaseUtils';
import { saveToFirestore, uploadFile } from '../../../utils/firebaseUtils';
import {DesignBlogManager, UI1Title, AddModal, AddButton} from '@misnpm/design-blog';
import './BlogDesing.css'





const BlogDesing = ({ posts, storeName, setPosts, isAdminRoute, isTikTokEnabled }) => {
    
    const { generateSlug } = require('../../Producto/utils/slugGenerator');
    const [user, setUser] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [postToEdit, setPostEdit] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            setUser(currentUser);
        });
        return () => unsubscribe();
    }, []);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => {
        setPostEdit(null);
        setIsModalOpen(false);
    };

    const handleSubmit = async (data: BlogDesignData) => {
        setLoading(true);
        try {
            let imageUrl = data.image;
    
            if (data.image instanceof File) {
                const uploadPath = `${storeName}/blog/images`;
                imageUrl = await uploadFile(data.image, uploadPath);
            }
    
            // Excluir `buttonAction` antes de guardar en Firestore
            const { buttonAction, ...postData } = {
                ...data,
                image: imageUrl,
            };
    
            const collectionPath = `${storeName}/blog/entry`;
            const existingPostIndex = posts.findIndex((post) => post.id === data.id);
    
            if (existingPostIndex !== -1) {
                const docRef = doc(db, collectionPath, data.id);
                await updateDoc(docRef, postData); // Guardamos sin `buttonAction`
                setPosts((prevPosts) =>
                    prevPosts.map((post) => (post.id === data.id ? postData : post))
                );
            } else {
                await saveToFirestore(collectionPath, data.id, postData);
                setPosts((prevPosts) => [...prevPosts, postData]);
            }
    
            closeModal();
        } catch (error) {
            console.error("Error al guardar el post:", error.message);
        } finally {
            setLoading(false);
        }
    };
    
    const handleDelete = async (postId) => {
        try {
            setLoading(true);
            const collectionPath = `${storeName}/blog/entry`;
            await deleteDocument(collectionPath, postId);
            setPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));
        } catch (error) {
            console.error("Error al eliminar el post:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = (postId) => {
        const postToEdit = posts.find((post) => post.id === postId);
        if (postToEdit) {
            setPostEdit(postToEdit);
            openModal();
        } else {
            console.error("Post no encontrado para editar");
        }
    };

    const settings = {
        dots: posts.length > 3,
        infinite: false,
        speed: 500,
        slidesToShow: Math.min(posts.length, 3),
        slidesToScroll: Math.min(posts.length, 3),
        responsive: [
            { breakpoint: 1024, settings: { slidesToShow: Math.min(posts.length, 2), slidesToScroll: 2, dots: true, infinite: false } },
            { breakpoint: 768, settings: { slidesToShow: Math.min(posts.length, 1), slidesToScroll: 1, dots: true, infinite: false } },
        ],
    };

    return loading ? (
        <BlogSkeleton />
    ) : (
       
        <div style={{ position: 'relative' }}>
            <div className="my-4 text-center">
                <h1 className="  font-semibold  text-center text-[32px] " >
                    Blog
                </h1>
            </div>

            {user && <AddButton onClick={openModal} />}

            <AddModal open={isModalOpen} onSubmit={handleSubmit} onCancel={closeModal} initialData={postToEdit} />

            {posts.length === 0 ? (
                <div className='min-h-[15rem] flex justify-center items-center'>
                    <p className="text-center  text-darkgrey text-[14px] ">Aún no hay blogs publicados <br/> ¡Anímate a crear y compartir tu primer blog!</p>
                </div>
            ) : (
                <section className="py-10">
                    <div className="container mx-auto">
                        <Slider {...settings}>
                            {posts.map((post) => {
                                const slug = generateSlug(post.title);
                                const productLink = post.id
                                    ? isAdminRoute
                                        ? `/${storeName}/administrar/blog/${post.id}/${slug}`
                                        : `/blog/${post.id}/${slug}`
                                    : '#';
                                return (
                                    <DesignBlogManager
                                        key={post.id}
                                        selectUI="UI1"
                                        propsUI={{ ...post, }}
                                        mode={user ? "edit" : "view"}
                                        onSubmit={handleSubmit}
                                        onDelete={() => handleDelete(post.id)}
                                        onEdit={() => handleEdit(post.id)}
                                        buttonAction={() => {
                                            window.open(productLink, "_blank");
                                        }}
                                        onClick={() => console.log("Ejecutando Botón clickeado manualmente")}
                                    />
                                );
                            })}
                        </Slider>
                    </div>
                </section>
            )}
        </div> 
    );
};

export default BlogDesing;