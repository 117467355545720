import React, { useState } from "react";
import { Box, Typography, Slider, Stack, TextField } from "@mui/material";

/**
 * @param {string} initialStartColor - Color inicial por defecto.
 * @param {string} initialEndColor   - Color final por defecto.
 * @param {number} initialAngle      - Ángulo por defecto.
 * @param {function} onChange        - Se invoca con { startColor, endColor, angle }
 *                                      cada vez que algo cambie.
 */
const GradientPicker = ({
  initialStartColor = "#d9ddf1",
  initialEndColor = "#ffffff",
  initialAngle = 180,
  onChange = () => {},
}) => {
  // -----------------------------
  // Estados locales
  // -----------------------------
  const [startColor, setStartColor] = useState(initialStartColor);
  const [endColor, setEndColor] = useState(initialEndColor);
  const [angle, setAngle] = useState(initialAngle);

  // -----------------------------
  // Funciones auxiliares
  // -----------------------------
  const isValidHex = (value) =>
    /^#([A-Fa-f0-9]{3}|[A-Fa-f0-9]{6})$/.test(value);

  // -----------------------------
  // Handlers (Color Inicial)
  // -----------------------------
  const handleStartColorChange = (e) => {
    const newColor = e.target.value;
    setStartColor(newColor);
    onChange({ startColor: newColor, endColor, angle });
  };

  const handleStartColorTextChange = (e) => {
    const { value } = e.target;
    // Solo actualizamos si es válido
    if (isValidHex(value)) {
      setStartColor(value);
      onChange({ startColor: value, endColor, angle });
    } else {
      // Si prefieres, podrías permitir que el usuario vea
      // el valor inválido momentáneamente y no guardarlo.
      setStartColor(value);
    }
  };

  // -----------------------------
  // Handlers (Color Final)
  // -----------------------------
  const handleEndColorChange = (e) => {
    const newColor = e.target.value;
    setEndColor(newColor);
    onChange({ startColor, endColor: newColor, angle });
  };

  const handleEndColorTextChange = (e) => {
    const { value } = e.target;
    if (isValidHex(value)) {
      setEndColor(value);
      onChange({ startColor, endColor: value, angle });
    } else {
      setEndColor(value);
    }
  };

  // -----------------------------
  // Handler (Slider Ángulo)
  // -----------------------------
  const handleAngleChange = (e, newValue) => {
    setAngle(newValue);
    onChange({ startColor, endColor, angle: newValue });
  };

  // -----------------------------
  // Estilo inline para vista previa del gradiente
  // -----------------------------
  const previewStyle = {
    width: "100%",
    height: 80,
    borderRadius: 4,
    border: "1px solid #ccc",
    background: `linear-gradient(${angle}deg, ${startColor}, ${endColor})`,
  };

  // -----------------------------
  // Render del componente
  // -----------------------------
  return (
    <Box className="mb-5">
      <Typography variant="body1" sx={{ mb: 1, fontWeight: "bold" }}>
        Selecciona tu degradado
      </Typography>

      <Stack direction="row"  spacing={2} sx={{ mb: 2 }}>
        {/* 1) Sección de Color Inicial */}
        <Box sx={{ flex: 1 }}>
          <Typography variant="body2" sx={{ mb: 1 }}>
            Color Inicial
          </Typography>

          {/* Input color + Input texto */}
          <Stack direction="row" spacing={1} alignItems="center">
            <input
              type="color"
              value={startColor}
              onChange={handleStartColorChange}
              style={{
                width: 30,
                height: 30,
                border: "none",
                padding: 0,
                cursor: "pointer",
              }}
            />
            <TextField
              variant="outlined"
              size="small"
              value={startColor}
              onChange={handleStartColorTextChange}
              sx={{ width: 100 }}
            />
          </Stack>
        </Box>

        {/* 2) Sección de Color Final */}
        <Box sx={{ flex: 1 }}>
          <Typography variant="body2" sx={{ mb: 1 }}>
            Color Final
          </Typography>

          <Stack direction="row" spacing={1} alignItems="center">
            <input
              type="color"
              value={endColor}
              onChange={handleEndColorChange}
              style={{
                width: 30,
                height: 30,
                border: "none",
                padding: 0,
                cursor: "pointer",
              }}
            />
            <TextField
              variant="outlined"
              size="small"
              value={endColor}
              onChange={handleEndColorTextChange}
              sx={{ width: 100 }}
            />
          </Stack>
        </Box>
      </Stack>

      {/* Slider para Ángulo */}
      <Box >
        <Typography variant="body2" >
          Ángulo: {angle}°
        </Typography>
        <Slider
          value={angle}
          onChange={handleAngleChange}
          aria-labelledby="angle-slider"
          step={1}
          min={0}
          max={360}
        />
      </Box>

      {/* Vista previa del gradiente */}
      <Box sx={previewStyle} />
    </Box>
  );
};

export default GradientPicker;
