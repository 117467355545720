import React from 'react';
import FooterWidget from '../components/FooterWidget';
import {
  Box,
  Fab,
  Tooltip,
  Modal,
  TextField,
  Button,
  IconButton,
  Grid,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';




const Diseño1 = ({ footerContent, storeName, isAdminRoute,user,handleOpenModal}) => (
  
  <footer
    className="footer-area py-[30px] px-[20px]"
    style={{
      backgroundColor: footerContent.footerBackgroundColor,
      color: footerContent.footerTextColor,
    }}
  >
    <div className="w-full mx-auto 2xl:max-w-[1536px] xl:max-w-[1280px] lg:max-w-[1024px] md:max-w-[768px] sm:max-w-[640px]">
      <div className="flex-col  md:flex-row flex gap-[30px] md:gap-0">
        
      <div className='w-full sm:w-1/2 md:w-1/2 lg:w-1/4 '>
        <div className='single-footer-widget mb-0 md:mb-[30px]'>

            <h3 className='text-left !mb-[20px] !pb-0 !normal-case text-lg font-bold before:!bg-transparent'>Redes Sociales</h3>

        </div>

        <div className='flex gap-[20px] items-center'>
        {footerContent?.socialLinks?.map((link, index) => (
          <a key={index} href={link.url} target="_blank" rel="noreferrer">
            <div className='text-white rounded-[50%] w-[40px] h-[40px] flex p-2 justify-center  items-center' style={{
                backgroundColor: link.bgColor }}>
                <img src={link.icon} alt='redes sociales' width="35px"  height="35px" className=''/>
            </div>  
            </a>
        ))}

        </div>
        
      </div>





        <FooterWidget
          title="Enlaces Rápidos"
          links={footerContent.quickLinks}
          footerTextColor={footerContent.footerTextColor}
          footerTextoTodo={footerContent.footerTextoTodo}
        />

        <FooterWidget
          title="Contáctenos"
          links={footerContent.information}
          footerTextColor={footerContent.footerTextColor}
          footerTextoTodo={footerContent.footerTextoTodo}


        />

  {/*
        <FooterWidget
          title="Noticias nuevas"
          links={footerContent.centroAyuda}
          footerTextColor={footerContent.footerTextColor}
          footerTextoTodo={footerContent.footerTextoTodo}
        />
  */}

       

      </div>
    </div>
    <div
      className=" !bg-transparent p-4"
      style={{
        color: footerContent.footerTextoTodo,
      }}
    >
      <div className="w-full mx-auto 2xl:max-w-[1536px] xl:max-w-[1280px] lg:max-w-[1024px] md:max-w-[768px] sm:max-w-[640px]">
        <div className="align-items-center">
          <div className="text-[15px] text-center md:text-left">
            <p style={{ color: footerContent.footerTextoTodo }}>{footerContent.copyright}</p>
          </div>
         
        </div>
      </div>
    </div>

    
      
  </footer>
);

export default Diseño1;
