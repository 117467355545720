import React from 'react';

const getImagePath = (name) => {
  switch (name) {
    case 'add':
        return require('./iconBlack/AddIconBlack.webp');
    case 'logout':
        return require('./iconBlack/LogOutIconBlack.webp');
    case 'delete':
        return require('./iconBlack/TrashIconBlack.webp');
    case 'config':
        return require('./iconBlack/ConfigIconBlack.webp');
    case 'sectionSettings':
        return require('./iconBlack/SectionSettingsIconBlack.webp');
    case 'mail':
        return require('./iconBlack/MailIconBlack.webp');
    case 'password':
        return require('./iconBlack/PadlockIconBlack.webp');
    case 'visibleEye':
        return require('./iconBlack/VisibleEyeIconBlack.webp');
    case 'disableEye':
        return require('./iconBlack/DisableEyeIconBlack.webp');
    case 'pencil':
        return require('./iconBlack/PencilIconBlack.webp');
    case 'pencilAI':
        return require('./iconBlack/PencilAIIconBlack.webp');
    case 'close':
        return require('./iconBlack/CloseIconBlack.webp');
    case 'arrow':
        return require('./iconBlack/ArrowIconBlack.webp');
    default:
        console.warn(`Icono "${name}" no encontrado`);
        return null; 
  }
};

const IconImage = ({ name, alt = '', width = 20, height = 20, style = {} , className={}}) => {
  const src = getImagePath(name);

  if (!src) {
    return null;
  }

  return <img src={src} alt={alt || name} width={width} height={height} style={{ ...style }} className={className}/>;
};

export default IconImage;