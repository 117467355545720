import { useEffect, useState } from "react";

const LazyLoadedImage = ({ src, alt, className }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // Carga la imagen en segundo plano después de que la página haya cargado
    const handleLoad = () => setImageLoaded(true);
    
    if (document.readyState === "complete") {
      setImageLoaded(true);
    } else {
      window.addEventListener("load", handleLoad);
      return () => window.removeEventListener("load", handleLoad);
    }
  }, []);

  return (
    <img
      src={imageLoaded ? src : ""}
      alt={alt}
      className={className}
      loading="lazy"
      style={{ opacity: imageLoaded ? 1 : 0, transition: "opacity 0.5s ease-in-out" }}
    />
  );
};

export default LazyLoadedImage;