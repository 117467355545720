import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Button,
  TextField,
  Typography,
  Stack,
  CircularProgress,
  Grid,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Paper,
  DialogTitle,
  InputAdornment,
  FormLabel
} from "@mui/material";
import LZString from "lz-string";
import { uploadFile } from "../../../utils/firebaseUtils";
import { ButtonWithModal } from '@misnpm/input-text-kit';
import DynamicEditor from '../../common/DynamicEditor';
import StyledImageUploadButton from '../../common/StyledImageUploadButton';


const GaleriaModal = ({
  open,
  onClose,
  onSave,
  initialGaleria,
  storeName,
  onTempUpdate,
}) => {
  const [galeria, setGaleria] = useState({});
  const [loading, setLoading] = useState(true);

  const [tituloSeccion, setTituloSeccion] = useState("");
  const [tituloColor, setTituloColor] = useState("");
  const [fondoColor, setFondoColor] = useState("");
  const [disenoSeleccionado, setDisenoSeleccionado] = useState("GaleriaDesing1");

  const [isInitialized, setIsInitialized] = useState(false);

  const disenos = [
    {
      value: "GaleriaDesing1",
      label: "Diseño 1",
      image: "https://via.placeholder.com/80x40/ff0000/ffffff?text=1",
    },
  ];

  useEffect(() => {
    if (!isInitialized && open) {
      const data = initialGaleria || {};

      setTituloSeccion(data.tituloSeccion || "");
      setTituloColor(data.tituloColor || "#000000");
      setFondoColor(data.fondoColor || "#ffffff");
      setDisenoSeleccionado(data.disenoSeleccionado || "GaleriaDesing1");

      // Galería inicial
      const singleGaleriaObj = data.galeria || {
        id: Date.now(),
        // Imágenes
        image1: "",
        image1HasLink: false,
        image1Href: "",
        image2: "",
        image2HasLink: false,
        image2Href: "",
        image3: "",
        image3HasLink: false,
        image3Href: "",
        image4: "",
        image4HasLink: false,
        image4Href: "",
        image5: "",
        image5HasLink: false,
        image5Href: "",
        image6: "",
        image6HasLink: false,
        image6Href: "",
        image7: "",
        image7HasLink: false,
        image7Href: "",
        // Nuevo campo para border-radius
        rounded: "",
      };

      setGaleria(singleGaleriaObj);
      setLoading(false);
      setIsInitialized(true);
    }
  }, [initialGaleria, open, isInitialized]);

  /**
   * Actualiza la galería y notifica al padre si se requiere vista previa en tiempo real.
   */
  const updateGaleriaState = (field, value) => {
    setGaleria((prev) => {
      const updated = { ...prev, [field]: value };

      if (onTempUpdate) {
        onTempUpdate({
          tituloSeccion,
          tituloColor,
          fondoColor,
          disenoSeleccionado,
          galeria: updated,
        });
      }

      return updated;
    });
  };


  
  /**
   * Maneja la subida de archivos a Firebase y actualiza el state
   */
  const handleFileChange = async (fieldName, e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      try {
        setLoading(true);
        const filePath = `${storeName}/galeria/imagenes`;
        const fileUrl = await uploadFile(selectedFile, filePath);
        setLoading(false);

        updateGaleriaState(fieldName, fileUrl);
      } catch (error) {
        console.error("Error al subir el archivo:", error);
        alert("Hubo un problema al subir la imagen. Inténtalo nuevamente.");
        setLoading(false);
      }
    }
  };




  const handleImageUpload = async (fieldName, imageUrl) => {
    if (!imageUrl) return;
    
    try {
      setLoading(true);
      updateGaleriaState(fieldName, imageUrl);
    } catch (error) {
      console.error("Error al actualizar la imagen:", error);
      alert("Hubo un problema al actualizar la imagen. Inténtalo nuevamente.");
    } finally {
      setLoading(false);
    }
  };
  
  

  /**
   * Guarda todos los cambios de la galería en formato comprimido (base64)
   */
  const handleSaveAll = async () => {
    try {
      const dataToCompress = {
        tituloSeccion,
        tituloColor,
        fondoColor,
        disenoSeleccionado,
        galeria,
      };

      const compressedData = LZString.compressToBase64(
        JSON.stringify(dataToCompress)
      );
      onSave(compressedData);
      onClose();
    } catch (error) {
      console.error("Error al guardar la galería:", error);
    }
  };

  return (
    <DynamicEditor
      open={open}
      onClose={onClose}
      title="Editar Galería"
      width={{ xs: '100%', sm: 400 }}
      modalWidth={{ xs: "90%", sm: 800 }}
    >
   
        
        <Box  >

        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>

            <Paper sx={{ p: 2, mb: 2 }} elevation={1}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={12}>
                  
                    <TextField
                      fullWidth
                      label="Título de la Sección"
                      className="!font-poppins"
                      value={tituloSeccion}
                      onChange={(e) => setTituloSeccion(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <ButtonWithModal 
                              value={{
                                content: {
                                  value: tituloSeccion,  // Muestra el valor actual de 'tituloSeccion'
                                  hasText: true  // Indica que tiene texto, lo cual puede ser útil para la lógica del modal
                                }
                              }}
                              onChange={(newStyle) => {
                                console.log(newStyle);  // Imprime en consola el nuevo estilo que se seleccionó (probablemente un objeto con propiedades de estilo)
                                // Actualiza el color del título de sección basado en el nuevo estilo
                                setTituloColor(newStyle.text.color);  // 'setTituloSeccionColor' es una función de actualización del estado para el color del título
                              }}
                            />
                          </InputAdornment>
                        ),
                        style: { color: tituloColor  }  // Aplica el color del texto del título de sección (se usa 'tituloSeccionColor')
                      }}
                    />

                </Grid>
                
                
                


                <Grid item xs={12}>
                  

                    <StyledImageUploadButton
                            title="Seleccionar Fondo"
                            initialBackground={fondoColor} // Valor inicial del color o imagen
                            onStyleChange={(newStyle) => {
                              if (newStyle.background?.color) {
                                // Si se selecciona un color
                                setFondoColor(newStyle.background.color);
                              } else if (newStyle.background?.imageUrl) {
                                // Si se selecciona una imagen
                                setFondoColor(newStyle.background.imageUrl);
                              }
                            }}
                          />



                </Grid>
              </Grid>
            </Paper>

            {/** SECCIÓN: Lista de galerías */}
            <Paper sx={{ p: 2, mb: 2 }} elevation={1}>
              <Typography variant="h6" className='!font-poppins' sx={{ mb: 1, fontWeight: "semibold" }}>
                Lista de Imágenes
              </Typography>

              {/**
               * NUEVO: Campo para "border-radius" de todas las imágenes
               * Agregamos un Box de previsualización para que el usuario
               * vea en tiempo real cómo se redondearían los bordes.
               */}
              <Box sx={{ mb: 2 }}>
                <Typography variant="body2" className='!font-poppins' sx={{ fontWeight: "semibold", mb: 1 }}>
                  Bordes redondeados para las imágenes
                </Typography>
                <Stack direction="row" spacing={2} alignItems="center">
                  <TextField
                    fullWidth
                    placeholder="Ej: 10px o 50%"
                    value={galeria.rounded || ""}
                    onChange={(e) =>
                      updateGaleriaState("rounded", e.target.value)
                    }
                  />
                  {/* Previsualización del border-radius */}
                  <Box
                    sx={{
                      width: 50,
                      height: 50,
                      border: "1px solid #ccc",
                      borderRadius: galeria.rounded || "0px",
                      backgroundColor: "#f0f0f0",
                      flexShrink: 0,
                      transition: "border-radius 0.3s",
                      // Podrías incluso mostrar una imagen real aquí en vez de un color
                      // backgroundImage: 'url("...")',
                      // backgroundSize: "cover",
                    }}
                  />
                </Stack>
              </Box>

              {/* -- Imagen #1 -- */}
              
              <Typography variant="subtitle1" className='!font-poppins' sx={{ mt: 1, fontWeight: 600 }}>
                Imagen 1
              </Typography>
              <Grid container spacing={2} alignItems="center">
                {/* Botón Subir Imagen */}
                <Grid item xs="auto">
                    <StyledImageUploadButton
                      title="Subir Imagen 1"
                      initialBackground={galeria.image1 }
                      onStyleChange={(newStyle) => {
                        if (newStyle.background?.imageUrl) {
                          updateGaleriaState("image1", newStyle.background.imageUrl);
                        }
                      }}
                    />

                </Grid>

                {/* Checkbox */}
                <Grid item xs="auto">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={galeria.image1HasLink || false}
                        onChange={(e) =>
                          updateGaleriaState("image1HasLink", e.target.checked)
                        }
                      />
                    }
                    label="¿Activar enlace?"
                  />
                </Grid>

                {/* TextField que ocupa el espacio restante */}
                <Grid item xs>
                  <TextField
                    fullWidth
                    label="Destino (URL) #1"
                    value={galeria.image1Href || ""}
                    onChange={(e) => updateGaleriaState("image1Href", e.target.value)}
                    disabled={!galeria.image1HasLink}
                  />
                </Grid>
              </Grid>


              {/* -- Imagen #2 -- */}

              <Typography variant="subtitle1" className='!font-poppins' sx={{ mt: 1, fontWeight: 600 }}>
                Imagen 2
              </Typography>
              <Grid container spacing={2} alignItems="center">
                {/* Botón Subir Imagen */}
                <Grid item xs="auto">
                <StyledImageUploadButton
                      title="Subir Imagen 2"
                      initialBackground={galeria.image2 }
                      onStyleChange={(newStyle) => {
                        if (newStyle.background?.imageUrl) {
                          updateGaleriaState("image2", newStyle.background.imageUrl);
                        }
                      }}
                    />
                </Grid>

                {/* Checkbox */}
                <Grid item xs="auto">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={galeria.image2HasLink || false}
                        onChange={(e) =>
                          updateGaleriaState("image2HasLink", e.target.checked)
                        }
                      />
                    }
                    label="¿Activar enlace?"
                  />
                </Grid>

                {/* TextField que ocupa el espacio restante */}
                <Grid item xs>
                  <TextField
                    fullWidth
                    label="Destino (URL) #2"
                    value={galeria.image2Href || ""}
                    onChange={(e) => updateGaleriaState("image2Href", e.target.value)}
                    disabled={!galeria.image2HasLink}
                  />
                </Grid>
              </Grid>

              {/* -- Imagen #3 -- */}
              <Typography variant="subtitle1" sx={{ mt: 1, fontWeight: 600 }}>
                Imagen 3
              </Typography>
              <Grid container spacing={2} alignItems="center">
                {/* Botón Subir Imagen */}
                <Grid item xs="auto">
                  <StyledImageUploadButton
                      title="Subir Imagen 3"
                      initialBackground={galeria.image3 }
                      onStyleChange={(newStyle) => {
                        if (newStyle.background?.imageUrl) {
                          updateGaleriaState("image3", newStyle.background.imageUrl);
                        }
                      }}
                    />
                </Grid>

                {/* Checkbox */}
                <Grid item xs="auto">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={galeria.image3HasLink || false}
                        onChange={(e) =>
                          updateGaleriaState("image3HasLink", e.target.checked)
                        }
                      />
                    }
                    label="¿Activar enlace?"
                  />
                </Grid>

                {/* TextField que ocupa el espacio restante */}
                <Grid item xs>
                  <TextField
                    fullWidth
                    label="Destino (URL) #3"
                    value={galeria.image3Href || ""}
                    onChange={(e) => updateGaleriaState("image3Href", e.target.value)}
                    disabled={!galeria.image3HasLink}
                  />
                </Grid>
              </Grid>


              {/* -- Imagen #4 -- */}
              <Typography variant="subtitle1" sx={{ mt: 1, fontWeight: 600 }}>
                Imagen 4
              </Typography>
              <Grid container spacing={2} alignItems="center">
                {/* Botón Subir Imagen */}
                <Grid item xs="auto">
                    <StyledImageUploadButton
                      title="Subir Imagen 4"
                      initialBackground={galeria.image4 }
                      onStyleChange={(newStyle) => {
                        if (newStyle.background?.imageUrl) {
                          updateGaleriaState("image4", newStyle.background.imageUrl);
                        }
                      }}
                    />
                </Grid>

                {/* Checkbox */}
                <Grid item xs="auto">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={galeria.image4HasLink || false}
                        onChange={(e) =>
                          updateGaleriaState("image4HasLink", e.target.checked)
                        }
                      />
                    }
                    label="¿Activar enlace?"
                  />
                </Grid>

                {/* TextField que ocupa el espacio restante */}
                <Grid item xs>
                  <TextField
                    fullWidth
                    label="Destino (URL) #4"
                    value={galeria.image4Href || ""}
                    onChange={(e) => updateGaleriaState("image4Href", e.target.value)}
                    disabled={!galeria.image4HasLink}
                  />
                </Grid>
              </Grid>

              {/* -- Imagen #5 -- */}
              <Typography variant="subtitle1" sx={{ mt: 1, fontWeight: 600 }}>
                Imagen 5
              </Typography>
              <Grid container spacing={2} alignItems="center">
                {/* Botón Subir Imagen */}
                <Grid item xs="auto">
                <StyledImageUploadButton
                      title="Subir Imagen 5"
                      initialBackground={galeria.image5 }
                      onStyleChange={(newStyle) => {
                        if (newStyle.background?.imageUrl) {
                          updateGaleriaState("image5", newStyle.background.imageUrl);
                        }
                      }}
                    />
                </Grid>

                {/* Checkbox */}
                <Grid item xs="auto">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={galeria.image5HasLink || false}
                        onChange={(e) =>
                          updateGaleriaState("image5HasLink", e.target.checked)
                        }
                      />
                    }
                    label="¿Activar enlace?"
                  />
                </Grid>

                {/* TextField que ocupa el espacio restante */}
                <Grid item xs>
                  <TextField
                    fullWidth
                    label="Destino (URL) #5"
                    value={galeria.image5Href || ""}
                    onChange={(e) => updateGaleriaState("image5Href", e.target.value)}
                    disabled={!galeria.image5HasLink}
                  />
                </Grid>
              </Grid>

              {/* -- Imagen #6 -- */}
              <Typography variant="subtitle1" sx={{ mt: 1, fontWeight: 600 }}>
                Imagen 6
              </Typography>
              <Grid container spacing={2} alignItems="center">
                {/* Botón Subir Imagen */}
                <Grid item xs="auto">
                <StyledImageUploadButton
                      title="Subir Imagen 6"
                      initialBackground={galeria.image6 }
                      onStyleChange={(newStyle) => {
                        if (newStyle.background?.imageUrl) {
                          updateGaleriaState("image6", newStyle.background.imageUrl);
                        }
                      }}
                    />
                </Grid>

                {/* Checkbox */}
                <Grid item xs="auto">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={galeria.image6HasLink || false}
                        onChange={(e) =>
                          updateGaleriaState("image6HasLink", e.target.checked)
                        }
                      />
                    }
                    label="¿Activar enlace?"
                  />
                </Grid>

                {/* TextField que ocupa el espacio restante */}
                <Grid item xs>
                  <TextField
                    fullWidth
                    label="Destino (URL) #6"
                    value={galeria.image6Href || ""}
                    onChange={(e) => updateGaleriaState("image6Href", e.target.value)}
                    disabled={!galeria.image6HasLink}
                  />
                </Grid>
              </Grid>

              {/* -- Imagen #7 -- */}
              <Typography variant="subtitle1" sx={{ mt: 1, fontWeight: 600 }}>
                Imagen 7
              </Typography>
              <Grid container spacing={2} alignItems="center">
                {/* Botón Subir Imagen */}
                <Grid item xs="auto">
                    <StyledImageUploadButton
                      title="Subir Imagen 7"
                      initialBackground={galeria.image7 }
                      onStyleChange={(newStyle) => {
                        if (newStyle.background?.imageUrl) {
                          updateGaleriaState("image7", newStyle.background.imageUrl);
                        }
                      }}
                    />
                </Grid>

                {/* Checkbox */}
                <Grid item xs="auto">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={galeria.image7HasLink || false}
                        onChange={(e) =>
                          updateGaleriaState("image7HasLink", e.target.checked)
                        }
                      />
                    }
                    label="¿Activar enlace?"
                  />
                </Grid>

                {/* TextField que ocupa el espacio restante */}
                <Grid item xs>
                  <TextField
                    fullWidth
                    label="Destino (URL) #7"
                    value={galeria.image7Href || ""}
                    onChange={(e) => updateGaleriaState("image7Href", e.target.value)}
                    disabled={!galeria.image7HasLink}
                  />
                </Grid>
              </Grid>
            </Paper>

          
            <Button
              variant="btnprimary"
              color="primary"
              fullWidth
              sx={{ mt: 2, }}
              onClick={handleSaveAll}
            >
              Guardar Todo
            </Button>
          </>
        )}
        </Box>
  
    </DynamicEditor>
  );
};

export default GaleriaModal;