import React, { useState, useEffect } from "react";
import {Modal,Box,Button,TextField,IconButton,Typography,Stack,Accordion,AccordionSummary,AccordionDetails,CircularProgress,Grid,FormControlLabel,Checkbox, DialogTitle,InputAdornment} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Delete } from "@mui/icons-material";
import LZString from "lz-string";
import { uploadFile } from '../../../../utils/firebaseUtils'; 
import PreviewDesign from "../../../PreviewDesign/PreviewDesign";
import { ButtonWithModal } from '@misnpm/input-text-kit';
import DesignCarousel from '../../../../components/common/DesignCarousel';
import StyledImageUploadButton from '../../../../components/common/StyledImageUploadButton';
import IconImage from "../../../images/IconImage"
import DynamicEditor from '../../../common/DynamicEditor';


const ServicioForm = ({ open, onClose, onSave, initialServicios, storeName }) => {

  const [servicios, setServicios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [tituloSeccion, setTituloSeccion] = useState("");
  const [tituloSeccionColor, setTituloSeccionColor] = useState(""); 

  const [disenoSeleccionado, setDisenoSeleccionado] = useState("UI5"); 
  const [image, setImage] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");


  const disenos = [
    {
      value: "UI5",
      label: "Diseño 1",
      image: "/assets2/img/designs/diseño5_servicios.png",
    },
    {
      value: "UI6",
      label: "Diseño 2",
      image: "/assets2/img/designs/diseño6_servicios.png",
    },
    {
      value: "UI7",
      label: "Diseño 3",
      image: "/assets2/img/designs/diseño7_servicios.png",
    },
    {
      value: "UI8",
      label: "Diseño 4",
      image: "/assets2/img/designs/diseño8_servicios.png",
    },
    {
      value: "UI12",
      label: "Diseño 5",
      image: "/assets2/img/designs/UI12.png",
    },
  ];
  
  useEffect(() => {
    const isArray = Array.isArray(initialServicios);
    const arrayServicios = isArray
      ? initialServicios || []
      : initialServicios?.servicios || [];
    setServicios(arrayServicios);
    setTituloSeccion(initialServicios?.tituloSeccion || "");
    setTituloSeccionColor(initialServicios?.tituloSeccionColor || ""); // Asegúrate de que esté bien inicializado
    setDisenoSeleccionado(initialServicios?.disenoSeleccionado || disenos[0].value);
    setUploadedImageUrl(initialServicios?.image || "");
    setLoading(false);
  }, [initialServicios]);
  
  const handleChange = (index, e) => {
    const { name, value } = e.target;
    setServicios((prev) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], [name]: value };
      return updated;
    });
  };

  const handleAddServicio = () => {
    setServicios((prev) => [
      ...prev,
      {
        id: Date.now(),
        titulo: "",
        descripcion: "",
        icono: "",
        color: "#d9ddf1",
        image: "",
        buttonText: "",
        buttonBackground: "",
        textColorButton: "",
      },
    ]);
  };

  const handleDeleteServicio = (index) => {
    setServicios((prev) => prev.filter((_, i) => i !== index));
    if (expandedIndex === index) {
      setExpandedIndex(-1);
    } else if (expandedIndex > index) {
      setExpandedIndex((prev) => prev - 1);
    }
  };

  const handleSaveAll = async () => {
    try {
      const dataToCompress = {
        tituloSeccion,
        tituloSeccionColor,
        disenoSeleccionado,
        image: uploadedImageUrl,
        servicios,
      };

      const compressedData = LZString.compressToBase64(JSON.stringify(dataToCompress));
      onSave(compressedData);
      onClose();
    } catch (error) {
      console.error("Error al guardar el servicio:", error);
    }
  };

  const handleMainImageChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {setImage(reader.result);};
      reader.readAsDataURL(selectedFile);

      try {
        const filePath = `${storeName}/servicio/imgPrincipal`;
        const uploadedUrl = await uploadFile(selectedFile, filePath);
        setUploadedImageUrl(uploadedUrl);
      } catch (error) {
        console.error("Error al subir la imagen principal:", error);
        alert("Hubo un problema al subir la imagen principal. Inténtalo nuevamente.");
      }
    }
  };
  
  const handleFileChange = async (index, e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      try {
        const filePath = `${storeName}/servicio/iconos`;
        const iconUrl = await uploadFile(selectedFile, filePath);
        setServicios((prev) => {
          const updated = [...prev];
          updated[index] = { ...updated[index], icono: iconUrl };
          return updated;
        });
      } catch (error) {
        console.error("Error al subir el archivo:", error);
        alert("Hubo un problema al subir el ícono. Inténtalo nuevamente.");
      }
    }
  };

  const handleAccordionChange = (panelIndex) => {
    setExpandedIndex((prevIndex) => (prevIndex === panelIndex ? -1 : panelIndex));
  };

  const handleDesignSelect = (selectedDesign) => {
    console.log('Diseño seleccionado:', selectedDesign);
    setDisenoSeleccionado(selectedDesign); 
  };


  return (


    <DynamicEditor
      open={open}
      onClose={onClose}
      title="Editar Servicios"
      width={{ xs: '100%', sm: 400 }}
      modalWidth={{ xs: "80%", sm: 800 }}
    >

        {loading ? (

          <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
            <CircularProgress />
          </Box>
          
        ) : (
          <>
          
           
            <Stack direction="row" spacing={2} alignItems="center" className="mb-[3rem]">
                <DesignCarousel
                  designs={disenos}
                  selectedDesign={disenoSeleccionado}
                  onSelect={handleDesignSelect}
                />
            </Stack>

            <TextField
            fullWidth
            label="Título de Sección"
            value={tituloSeccion}  // Este es el valor del título de sección
            onChange={(e) => setTituloSeccion(e.target.value)}  // Actualiza el valor de 'tituloSeccion'
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ButtonWithModal 
                    value={{
                      content: {
                        value: tituloSeccion,  // Muestra el valor actual de 'tituloSeccion'
                        hasText: true  // Indica que tiene texto, lo cual puede ser útil para la lógica del modal
                      }
                    }}
                    onChange={(newStyle) => {
                      console.log(newStyle);  // Imprime en consola el nuevo estilo que se seleccionó (probablemente un objeto con propiedades de estilo)
                      // Actualiza el color del título de sección basado en el nuevo estilo
                      setTituloSeccionColor(newStyle.text.color);  // 'setTituloSeccionColor' es una función de actualización del estado para el color del título
                    }}
                  />
                </InputAdornment>
              ),
            }}
            />
          
            {disenoSeleccionado === "UI7" && (
              <>
                <Typography variant="body1" className='!font-poppins' sx={{ mt: 2, mb: 1 }}>
                  Subir Imagen Principal
                </Typography>
               
                <StyledImageUploadButton
                  title="Imagen principal"
                  onStyleChange={(newStyle) => {
                    console.log('New Style:', newStyle);

                    // Guardar la URL de la imagen seleccionada para la previsualización
                    setImage(newStyle.background.imageUrl);

                    // Actualizar la URL de la imagen subida sin necesidad de cargarla nuevamente
                    setUploadedImageUrl(newStyle.background.imageUrl); // Esto guarda la URL final
                  }}
                />
                
              </>
            )}

          <div className="flex  justify-between items-center">

         
            <Typography variant="h6" className='!font-poppins' sx={{ my: 3 }}>
              Lista de Servicios
            </Typography>

            {!loading && (
            <Button
             
              onClick={handleAddServicio}
              
            >
                        <IconImage name="add" width="20px" height="20px" alt="Icono flecha" /> 

            </Button>
          )}


            </div>


            {servicios.length === 0 && (
              <Typography variant="body2" className='!font-poppins' sx={{ mb: 2, color: "gray" }}>
                No hay servicios disponibles.
              </Typography>
            )}

            {servicios.map((servicio, index) => (
              <Accordion
                key={servicio.id || index}
                expanded={expandedIndex === index}
                onChange={() => handleAccordionChange(index)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: "100%" }}
                  >
                    <Typography sx={{ fontWeight: "semibold" }} className='!font-poppins'>
                      {servicio.titulo || "Nuevo Servicio"}
                    </Typography>
                    <IconButton
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation(); 
                        handleDeleteServicio(index);
                      }}
                      aria-label="delete"
                    >
                      
                      <IconImage name="delete" width="20px" height="20px" alt="Icono flecha" /> 
                    </IconButton>
                  </Stack>
                </AccordionSummary>

                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Stack spacing={2}>
                        {/* Título */}
                        <TextField
                          fullWidth
                          label="Título"
                          name="titulo"
                          value={servicio.titulo}
                          onChange={(e) => handleChange(index, e)}
                        />

                        {/* Descripción */}
                        <TextField
                          fullWidth
                          label="Descripción"
                          name="descripcion"
                          multiline
                          rows={4}
                          value={servicio.descripcion}
                          onChange={(e) => handleChange(index, e)}
                        />
                        
                        {disenoSeleccionado == "UI12" && (
                          <>
                          
                          <Stack spacing={2} direction={{ xs: "column", sm: "row" }} sx={{  alignItems: "center" }}>
                            {/* Checkbox ocupa 2 columnas */}
                            <Box sx={{ flex:4, display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <TextField
                                  fullWidth
                                  label="Texto Botón"
                                  type="color"
                                  name="textColorButton"
                                  value={servicio.textColorButton || "#ffffff"}
                                  onChange={(e) => handleChange(index, e)}
                                  InputLabelProps={{ shrink: true }}
                                  sx={{
                                    "& input": {
                                      padding: 0,
                                      height: "40px",
                                      cursor: "pointer",
                                    },
                                  }}
                                />
                            </Box>

                            {/* Texto del enlace ocupa 5 columnas */}
                            <Box sx={{ flex: 4}}>
                            <TextField
                                  fullWidth
                                  label="Fondo del Botón"
                                  type="color"
                                  name="buttonBackground"
                                  value={servicio.buttonBackground || "#ffffff"}
                                  onChange={(e) => handleChange(index, e)}
                                  InputLabelProps={{ shrink: true }}
                                  sx={{
                                    "& input": {
                                      padding: 0,
                                      height: "40px",
                                      cursor: "pointer",
                                    },
                                  }}
                                />
                            </Box>
                            <Box sx={{ flex: 4}}>

                                <TextField
                                  fullWidth
                                  label="Contenido del Botón"
                                  name="buttonText"
                                  value={servicio.buttonText}
                                  onChange={(e) => handleChange(index, e)}
                                />
                              </Box>
                          </Stack>
                          

                          </>
                        )}      

                      
                        {disenoSeleccionado != "UI8"  && (
                          <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                            {/* Checkbox ocupa 2 columnas */}
                            <Box sx={{ flex: 2, display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={!!servicio.hasLink}
                                    onChange={(e) => {
                                      const isChecked = e.target.checked;
                                      setServicios((prev) => {
                                        const updated = [...prev];
                                        updated[index] = { ...updated[index], hasLink: isChecked };
                                        return updated;
                                      });
                                    }}
                                  />
                                }
                                label="¿Activar enlace?"
                              />
                            </Box>

                            {/* Texto del enlace ocupa 5 columnas */}
                            <Box sx={{ flex: 5 }}>
                              <TextField
                                label="Texto del enlace"
                                name="textoEnlace"
                                value={servicio.textoEnlace || ""}
                                onChange={(e) => handleChange(index, e)}
                                fullWidth
                                disabled={!servicio.hasLink}
                              />
                            </Box>

                            {/* Destino URL ocupa 5 columnas */}
                            <Box sx={{ flex: 5 }}>
                              <TextField
                                label="Destino (URL)"
                                name="href"
                                value={servicio.href || ""}
                                onChange={(e) => handleChange(index, e)}
                                fullWidth
                                disabled={!servicio.hasLink}
                              />
                            </Box>
                          </Stack>
                        )}

                        <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                          
                          {!disenoSeleccionado !== "UI8" && (
                          <>
                          

                          <StyledImageUploadButton
                              title="Color de fondo"
                              onStyleChange={(newStyle) => {
                                console.log('New Style:', newStyle);
                                handleChange(index, {
                                  target: { name: 'color', value: newStyle.background.color }
                                });
                              }}
                              style={{ background: servicio.color  }}
                          />

</>


                        )}

                            

                            <StyledImageUploadButton
                              title={`Sube el ícono `}
                              onStyleChange={(newStyle) => {
                                console.log('New Style:', newStyle);
                                // Actualizamos el valor de 'icono' con la URL de la imagen
                                handleChange(index, {
                                  target: { name: 'icono', value: newStyle.background.imageUrl }
                                });
                              }}
                            />
                            


                          
                          <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Box 
                              mt={{ xs: 2, sm: 0 }} 
                              textAlign="center"
                              sx={{
                                width: 60,          
                                height: 60,           
                                margin: "0 auto",     
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                //backgroundColor: servicio.color || "#ffffff",
                                backgroundColor: disenoSeleccionado !== "UI8" ? servicio.color || "#ffffff" : "transparent", // Condición aplicada aquí
                                borderRadius: "50%",
                              }}
                            >
                              {servicio.icono && (
                                <img
                                  src={servicio.icono}
                                  alt="Ícono del servicio"
                                  style={{ 
                                    width: "50%", 
                                    height: "50%", 
                                    objectFit: "contain" 
                                  }}
                                />
                              )}
                            </Box>
                          </Box>
                        </Stack>

                      </Stack>
                    </Grid>

                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
          </>
        )}

        {/* Botón para agregar nuevo servicio */}
          {!loading && (
            <Button
              variant="btnprimary"
              fullWidth
              sx={{ mt: 2 }}
              onClick={handleSaveAll}
            >
              Guardar Todo
            </Button>
          )}
      

    
     
    </DynamicEditor>
  );
};

export default ServicioForm;
