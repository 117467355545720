import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Button,
  TextField,
  IconButton,
  Typography,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Grid,
  Radio,
  FormControlLabel,
  Checkbox,
  DialogTitle,
  InputAdornment
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LZString from "lz-string";
import GradientPicker from "./GradientPicker";
import PreviewDesign from "../../../PreviewDesign/PreviewDesign";
import { uploadFile } from '../../../../utils/firebaseUtils'; 
import { ButtonWithModal } from '@misnpm/input-text-kit';
import DesignCarousel from '../../../../components/common/DesignCarousel';
import IconImage from "../../../images/IconImage"
import StyledImageUploadButton from '../../../../components/common/StyledImageUploadButton';
import DynamicEditor from '../../../common/DynamicEditor';

const BeneficiosForm = ({ open, onClose, initialBeneficios, onSave ,storeName}) => {
  const [beneficios, setBeneficios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [tituloSeccion, setTituloSeccion] = useState("");
  const [colorFondo, setColorFondo] = useState("");
  const [colorTexto, setColorTexto] = useState("");
  const [colorTitulo, setColorTitulo] = useState("");

  const disenos = [
    {
      value: "UI1",
      label: "Diseño 1",
      image: "/assets2/img/designs/diseño1_beneficios.png",
    },
    {
      value: "UI2",
      label: "Diseño 2",
      image: "/assets2/img/designs/diseño2_beneficios.png",
    },
    {
      value: "UI3",
      label: "Diseño 3",
      image: "/assets2/img/designs/diseño3_beneficios.png",
    },
    {
      value: "UI4",
      label: "Diseño 4",
      image: "/assets2/img/designs/diseño4_beneficios.png",
    },
    {
      value: "UI12",
      label: "Diseño 5",
      image: "/assets2/img/designs/UI12.png",
    },
  ];
  
  const [disenoSeleccionado, setDisenoSeleccionado] = useState(disenos[0].value);
  const isArray = Array.isArray(initialBeneficios);
  const arrayBeneficios = isArray 
    ? initialBeneficios || []
    : initialBeneficios?.beneficios || [];


  useEffect(() => {
  if (initialBeneficios) {
      setBeneficios(arrayBeneficios);
      setLoading(false);
    } else {
      setBeneficios([]);
      setLoading(false);
    }
    }, [initialBeneficios]);

  const handleFileChange = async (index, e) => {
        const beneficioFile = e.target.files[0];
        if (beneficioFile) {
          try {
            const filePath = `${storeName}/beneficios/iconos`;
            const iconUrl = await uploadFile(beneficioFile, filePath);
            setBeneficios((prev) => {
              const updated = [...prev];
              updated[index] = { ...updated[index], icono: iconUrl };
              return updated;
            });
          } catch (error) {
            console.error("Error al subir el archivo:", error);
            alert("Hubo un problema al subir el ícono. Inténtalo nuevamente.");
          }
        }
    };

    const handleChange = (index, e) => {
    const { name, value } = e.target;
    setBeneficios((prev) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], [name]: value };
      return updated;
    });
  };

  const handleAddBeneficio = () => {
    setBeneficios((prev) => [
      ...prev,
      {
        id: Date.now(), 
        titulo: "",
        descripcion: "",
        porcentaje: 20,
        colorStart: "#d9ddf1",
        colorEnd: "#ffffff",
        angle: "180deg",
        textColorButton: "#ffffff", 
        buttonBackground: "#000000",
        icono:"",
        href:"/example",
        hasLink: true,
        buttonText: "See more",
      },
    ]);
   
  };

  const handleDeleteBeneficio = (index) => {
    setBeneficios((prev) => prev.filter((_, i) => i !== index));
    if (expandedIndex === index) {
      setExpandedIndex(-1); // Cierra el panel si era el que estaba abierto
    } else if (expandedIndex > index) {
      setExpandedIndex((prev) => prev - 1);
    }
  };

  const handleSaveAll = () => {
    const dataToCompress = {
      tituloSeccion,
      disenoSeleccionado,
      beneficios,
      colorTitulo ,
      ...(disenoSeleccionado === "UI4" && { 
        colorFondo,  
      }), 
    };
  
    const compressedData = LZString.compressToBase64(
      JSON.stringify(dataToCompress)
    );
    onSave(compressedData);
  };
  

  /**
   * Maneja la expansión de cada Accordion.
   * Si el usuario hace clic en un Accordion que ya está abierto, lo cierra (pone -1).
   * Si hace clic en otro Accordion, cierra el anterior y abre el nuevo.
   */

  const handleAccordionChange = (panelIndex) => {
    setExpandedIndex((prevIndex) => (prevIndex === panelIndex ? -1 : panelIndex));
  };

  const handleUpdateGradient = (index, startColor, endColor, angle) => {
    setBeneficios((prev) => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        colorStart: startColor,
        colorEnd: endColor,
        angle: angle.toString(), 
      };
      return updated;
    });
  };

  const handleDesignSelect = (selectedDesign) => {
    console.log('Diseño seleccionado:', selectedDesign);
    setDisenoSeleccionado(selectedDesign); 
  };



  return (
    
    <DynamicEditor
      open={open}
      onClose={onClose}
      title="Editar Beneficios"
      width={{ xs: '100%', sm: 400 }}
      modalWidth={{ xs: "80%", sm: 800 }}
    >


        <Box className="mt-6" >
        {loading ? (
          // Spinner de carga
          <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
                
            <TextField
              fullWidth
              label="Título de la Sección"
              value={tituloSeccion}
              onChange={(e) => setTituloSeccion(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ButtonWithModal 
                      value={{
                        content: {
                          value: tituloSeccion,  // Muestra el valor actual de 'tituloSeccion'
                          hasText: true  // Indica que tiene texto, lo cual puede ser útil para la lógica del modal
                        }
                      }}
                      onChange={(newStyle) => {
                        console.log(newStyle);  // Imprime en consola el nuevo estilo que se seleccionó (probablemente un objeto con propiedades de estilo)
                        // Actualiza el color del título de sección basado en el nuevo estilo
                        setColorTitulo(newStyle.text.color);  // 'setTituloSeccionColor' es una función de actualización del estado para el color del título
                      }}
                    />
                  </InputAdornment>
                ),
                // Aplica el color del texto del título de sección (se usa 'tituloSeccionColor')
              }}
            />

           


        
            
            <Stack direction="row" spacing={2} alignItems="center" className="my-[3rem]">

                  
                  <DesignCarousel
                    designs={disenos}
                    selectedDesign={disenoSeleccionado}
                    onSelect={handleDesignSelect}
                  />

            </Stack>

            <div className="flex  justify-between items-center">
              <Typography variant="h6" className='!font-poppins' sx={{ my: 3 }}>
              Lista de Beneficios
              </Typography>

              {!loading && (
              <Button
              
                onClick={handleAddBeneficio}
                
              >
                          <IconImage name="add" width="20px" height="20px" alt="Icono flecha" /> 

              </Button>
              )}
            </div>




            {beneficios.length === 0 && (
              <Typography variant="body2" className='!font-poppins' sx={{ mb: 2,mt:2, color: "gray" }}>
                No hay beneficios disponibles.
              </Typography>
            )}

            {beneficios.map((beneficio, index) => (
              <Accordion
            key={beneficio.id || index} 
            expanded={expandedIndex === index}
            onChange={() => handleAccordionChange(index)}
          >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: "100%" }}
                  >
                    <Typography sx={{ fontWeight: "semibold" }} className='!font-poppins'>
                      {beneficio.titulo || "Nuevo Beneficio"}
                    </Typography>
                    <IconButton
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation(); 
                        handleDeleteBeneficio(index);
                      }}
                      aria-label="delete"
                    >
                       <IconImage name="delete" width="20px" height="20px" alt="Icono flecha" /> 
                    </IconButton>
                  </Stack>
                </AccordionSummary>

                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} lg={open ? 12 : 6}>
                      <Stack spacing={2}>

                        <TextField
                          fullWidth
                          label="Título"
                          name="titulo"
                          value={beneficio.titulo}
                          onChange={(e) => handleChange(index, e)}
                        />

                        <TextField
                          fullWidth
                          label="Descripción"
                          name="descripcion"
                          multiline
                          rows={4}
                          value={beneficio.descripcion}
                          onChange={(e) => handleChange(index, e)}
                        />


                        <TextField
                          fullWidth
                          margin="normal"
                          label="Porcentaje"
                          name="porcentaje"
                          value={beneficio.porcentaje}
                          onChange={(e) => handleChange(index, e)}
                          type="number"
                          inputProps={{ min: 0, max: 100 }}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <GradientPicker
                        initialStartColor={beneficio.colorStart}
                        initialEndColor={beneficio.colorEnd}
                        initialAngle={parseInt(beneficio.angle, 10)}
                        onChange={({ startColor, endColor, angle }) => {
                          handleUpdateGradient(index, startColor, endColor, angle);
                        }}
                      />
                    </Grid>
                  </Grid>
                  
                  {disenoSeleccionado == "UI12" && (
                         <>
                        <Stack spacing={2} direction="row" sx={{ alignItems: "center" }}>
                          {/* Activar Enlace */}
                          <Box sx={{ flex: 1 }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!!beneficio.hasLink}
                                  onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    setBeneficios((prev) => {
                                      const updated = [...prev];
                                      updated[index] = { ...updated[index], hasLink: isChecked };
                                      return updated;
                                    });
                                  }}
                                />
                              }
                              label="¿Activar enlace?"
                            />
                          </Box>

                          {/* Contenido del Botón */}
                          <Box sx={{ flex: 3 }}>
                            <TextField
                              fullWidth
                              disabled={!beneficio.hasLink}  // Se habilita solo si 'hasLink' es verdadero
                              label="Contenido del Botón"
                              name="buttonText"
                              value={beneficio.buttonText}
                              onChange={(e) => handleChange(index, e)}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <ButtonWithModal
                                      value={{
                                        content: {
                                          value: beneficio.buttonText,  // Muestra el valor actual del texto del botón
                                          hasText: true,  // Indica que hay texto en el botón
                                        },
                                      }}
                                      onChange={(newStyle) => {
                                        console.log(newStyle);  // Muestra en consola el nuevo estilo
                                        handleChange(index, { target: { name: 'textColorButton', value: newStyle.text.color } });
                                      }}
                                    />
                                  </InputAdornment>
                                ),
                                 // Aplica el color actual del texto en el botón
                              }}
                            />
                          </Box>

                          {/* Destino (URL) */}
                          <Box sx={{ flex: 3 }}>
                            <TextField
                              label="Destino (URL)"
                              name="href"
                              value={beneficio.href || ""}
                              onChange={(e) => handleChange(index, e)}
                              fullWidth
                              disabled={!beneficio.hasLink}  // Se habilita solo si 'hasLink' es verdadero
                            />
                          </Box>
                        </Stack>

                       
                         {/* Contenido del botón y fondo */}
                         <Stack spacing={2} direction={{ xs: "column", sm: "row" }} sx={{ alignItems: "center", marginTop: 2 }}>
                           
                          <StyledImageUploadButton
                             title="Sube un icono"
                             onStyleChange={(newStyle) => {
                               console.log('New Style:', newStyle);
                               // Actualizamos el valor de 'icono' con la URL de la imagen
                               handleChange(index, {
                                 target: { name: "icono", value: newStyle.background.imageUrl }
                               });
                             }}
                           />

                          {beneficio.hasLink && (
                            <StyledImageUploadButton
                              title="Fondo del Boton"
                              onStyleChange={(newStyle) => {
                                console.log('New Style:', newStyle);
                                // Actualizamos el valor de 'icono' con la URL de la imagen
                                handleChange(index, {
                                  target: { name: "buttonBackground", value: newStyle.background.color }  // 'icono' actualizará el valor con la URL
                                });
                              }}
                            />
                          )}


                          {/*                       
                           <TextField
                             fullWidth
                             label="Fondo del Botón"
                             type="color"
                             name="buttonBackground"
                             value={beneficio.buttonBackground || "#ffffff"}
                             onChange={(e) => handleChange(index, e)}
                             InputLabelProps={{ shrink: true }}
                             sx={{
                               "& input": {
                                 padding: 0,
                                 height: "40px",
                                 cursor: "pointer",
                               },
                             }}
                             disabled={!beneficio.hasLink}
                           />
                          */}


                         </Stack>

                       </>
                       
                        )}




                </AccordionDetails>
              </Accordion>
            ))}

             
          </>
        )}

        
       

       
        {!loading && (
          <Button
            variant="btnprimary"
            fullWidth
            sx={{ mt: 2 }}
            onClick={handleSaveAll}
          >
            Guardar Todo
          </Button>
        )}
      </Box >



    </DynamicEditor>
    
  );
};

export default BeneficiosForm;
