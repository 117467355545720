import React from 'react';
import FooterWidget from '../components/FooterWidget';
import {
  Box,
  Fab,
  Tooltip,
  Modal,
  TextField,
  Button,
  IconButton,
  Grid,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const Diseño2 = ({ footerContent, storeName, isAdminRoute, user, handleOpenModal }) => (
  <footer
    className="footer-area"
    style={{
      backgroundColor: footerContent.footerBackgroundColor,
      color: footerContent.footerTextColor,
    }}
  >
    <div className="w-full mx-auto 2xl:max-w-[1536px] xl:max-w-[1280px] lg:max-w-[1024px] md:max-w-[768px] sm:max-w-[640px]">
      <div className="flex flex-row p-paddingdefault">
        <div className="w-full flex justify-center my-[1rem] md:gap-[30px] ">
          {footerContent?.socialLinks?.map((link, index) => (
            
            <a href={link.url} target="_blank" rel="noreferrer">
                
                <div
                    key={index}
                    className="text-white p-2 rounded-[50%] mx-2"
                    style={{
                      backgroundColor: link.bgColor,
                      width: '50px',
                      height: '50px',
                      
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    
                    <img src={link.icon} alt="redes sociales" width="30px" height="30px" style={{ maxWidth: '30px', maxHeight: '30px' }} />
                </div>
                
            </a>
          ))}
        </div>
      </div>

      {/* Footer Widgets */}
      <div className="flex flex-column  md:!flex-row  justify-between">
        <FooterWidget
          title="Enlaces Rápidos"
          links={footerContent.quickLinks}
          footerTextColor={footerContent.footerTextColor}
          footerTextoTodo={footerContent.footerTextoTodo}
           diseño2="true"
        />

        <FooterWidget
          title="Contáctenos"
          links={footerContent.information}
          footerTextColor={footerContent.footerTextColor}
          footerTextoTodo={footerContent.footerTextoTodo}
           diseño2="true"
        />


        {/* 
        <FooterWidget
          title="Centro de Ayuda"
          links={footerContent.centroAyuda}
          footerTextColor={footerContent.footerTextColor}
          footerTextoTodo={footerContent.footerTextoTodo}
          diseño2="true"
        />
        */}

      </div>
    </div>

    {/* Copyright Section */}
    <div
      className="copyright-area"
      style={{
        backgroundColor: footerContent.footerBackgroundColor,
        color: footerContent.footerTextoTodo,
      }}
    >
      <div className="container">
        <div className="align-items-center text-center text-[15px] p-paddingdefault" >
          <p style={{ color: footerContent.footerTextoTodo }}>{footerContent.copyright}</p>
        </div>
      </div>
    </div>

   
  </footer>
);

export default Diseño2;
