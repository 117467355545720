import React from 'react';
import { IconButton,Tooltip } from '@mui/material';

const AddStoreButton = ({ onClick }) => (
    
        <button className="px-4 flex items-center gap-2 font-poppins py-2 rounded-lg  duration-200 ease-in-out hover:bg-black/50 " onClick={onClick}>
            <p className="text-white m-0">Nuevo Hub</p>
            <img src="assets2/img/logo/addStore.png" alt="Nuevo Hub" className="w-5 h-5 " />
        </button>
        
);

export default AddStoreButton;
