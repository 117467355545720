import React, { useState } from "react";
import { Snackbar, Alert, AlertTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CustomAlert = ({ tipo, mensaje, open, onClose }) => {
  // Mapear el tipo de alerta a colores específicos
  const getAlertSeverity = () => {
    switch (tipo) {
      case "success":
        return "success";
      case "warning":
        return "warning";
      case "error":
        return "error";
      case "info":
        return "info";
      default:
        return "info";
    }
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }} // Posición de la alerta
      open={open} // Controla si la alerta está visible
      autoHideDuration={6000} // Tiempo en milisegundos antes de cerrarse automáticamente
      onClose={onClose} // Función para cerrar la alerta
    >
      <Alert
        severity={getAlertSeverity()} // Tipo de alerta (success, warning, error, info)
        onClose={onClose} // Cerrar al hacer clic en la "X"
        sx={{
          width: "100%",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
          borderRadius: "8px",
        }}
      >
        <AlertTitle>{tipo.charAt(0).toUpperCase() + tipo.slice(1)}</AlertTitle>
        {mensaje}
      </Alert>
    </Snackbar>
  );
};

export default CustomAlert;