import React, { useState } from "react";
import { Dialog, Box, DialogTitle } from "@mui/material";
import AddStoreForm from "./AddStoreForm";
import AiForm from "./AiForm";
import IconImage from '../../images/IconImage';

const ModalChoose = ({ open, onClose, onSave }) => {
    const [showAiForm, setShowAiForm] = useState(false);
    const [isFormOpen, setIsFormOpen] = useState(false);

    // Abre el formulario de IA y cierra el modal principal
    const handleOpenAiForm = () => {
        onClose();
        setShowAiForm(true);
    };

    // Cierra el formulario de IA
    const handleCloseAiForm = () => {
        setShowAiForm(false);
    };

    // Abre el formulario manual y cierra el modal principal
    const handleOpenForm = () => {
        onClose();
        setIsFormOpen(true);
    };

    // Cierra el formulario manual
    const handleCloseForm = () => {
        setIsFormOpen(false);
    };

    return (
        <>
            {/* Modal Principal */}
            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                <Box sx={{ background: 'linear-gradient(to right, #DD69E5, #A55AEE, #62CEFB)', 
                        height:'14px',  
                        witdh:'100%' }} ></Box> 
                <Box className="bg-white rounded-lg p-6 font-poppins">

                    <h2 className=" text-center !font-semibold">
                        Selecciona una opción
                    </h2>
                    
                    <div className="flex flex-col md:grid grid-cols-2 gap-4">
                        {/* Usando IA */}
                        <div
                            className="p-4 bg-white text-black text-center cursor-pointer flex flex-col items-center gap-2 hover:bg-[#e5e7eb] hover:rounded-[0.5rem]"
                            onClick={handleOpenAiForm}
                        >
                            <IconImage name="pencilAI" width="30px" height="30px" alt="Editar usando AI" />
                            <h3 className="text-lg font-semibold !font-poppins">Usando IA</h3>
                        </div>

                        {/* Forma Manual */}
                        <div
                            className="p-4 bg-white text-black text-center cursor-pointer flex flex-col items-center gap-2 hover:bg-[#e5e7eb] hover:rounded-[0.5rem]"
                            onClick={handleOpenForm}
                        >
                            <IconImage name="pencil" width="30px" height="30px" alt="Editar manualmente" />
                            <h3 className="text-lg font-semibold ! font-poppins">Forma Manual</h3>
                        </div>
                    </div>
                </Box>
            </Dialog>

            {/* Formulario de IA */}
            {showAiForm && (
                <AiForm open={showAiForm} onClose={handleCloseAiForm} />
            )}

            {/* Formulario Manual */}
            {isFormOpen && (
                <AddStoreForm open={isFormOpen} onClose={handleCloseForm} onSave={onSave} />
            )}
        </>
    );
};

export default ModalChoose;