import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import ServicioForm from './components/form/ServicioForm';
import ServicioPreview from './components/ServicioPreview';
import decompressServicio from './utils/decompressServicio';
import { saveToFirestore } from '../../utils/firebaseUtils';
import { doc, getDoc } from 'firebase/firestore'; // Importar desde Firebase Firestore
import { db, auth } from '../../firebaseConfig'; // Ajusta la ruta según tu proyecto
import LZString from 'lz-string';
import SlideEdgeButton from '../common/SlideEdgeButton';
import { useModal } from '../../context/ModalContext';
import DesignServicesIcon from '@mui/icons-material/DesignServices';


const ServicioManager = ({ storeName,compressedServicios  }) => {
  
  const { modalActivo, abrirModal, cerrarModal } = useModal();
  const MODAL_ID = 'servicios-modal';
  const [loading, setLoading] = useState(true);
  const [serviciosArray, setServiciosArray] = useState([]);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newServicio, setNewServicio] = useState({
    titulo: '',
    descripcion: '',
    icono: '',
    color: '#ffffff',
  });
  const [user, setUser] = useState(null);
  
  useEffect(() => {
    const fetchServicios = async () => {
      if (!compressedServicios) {
        console.error('No se proporcionaron datos comprimidos para servicios.');
        setError('Datos de servicio no disponibles.');
        setLoading(false);
        return;
      }
      try {
        const decompressedData = decompressServicio(compressedServicios);
        if (!decompressedData) {
          throw new Error('Descompresión fallida.');
        }
        setServiciosArray(decompressedData);
      } catch (err) {
        console.error('Error al procesar los servicios:', err);
        setError('Error al cargar los servicios.');
      } finally {
        setLoading(false);
      }
    };
    fetchServicios();
  }, [storeName]);
  
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const handleSaveServicio = async (compressedServicios) => {
    try {
      const decompressedData = decompressServicio(compressedServicios);
      setServiciosArray(decompressedData);
      await saveToFirestore(storeName, 'config', { servicios: compressedServicios });
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error al guardar el servicio:', error);
    }
  };


  return (
    <Box sx={{ position: 'relative' }}>
      
      <ServicioPreview 
        serviciosArray={serviciosArray}
        user={user}
      />

      {user && <SlideEdgeButton 
        onClick={() => abrirModal(MODAL_ID)}
        icon={DesignServicesIcon}
        label="SERVICIOS"
        position={{ 
          top: '1rem', 
          right: '-5.6rem' 
        }} 
      />}


      <ServicioForm
        open={modalActivo === MODAL_ID}
        onClose={cerrarModal}
        onSave={handleSaveServicio}
        newServicio={newServicio}
        initialServicios={serviciosArray}
        storeName={storeName}
      />
      
    </Box>
  );
};

export default ServicioManager;
