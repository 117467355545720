import React, { useState } from 'react';
import SkeletonLoader from './SkeletonLoader';
import DeleteStoreButton from '../components/DeleteStoreButton';
import AdminStoreButton from '../components/AdminStoreButton';
import SectionStoreButton from '../components/configuracion/SectionStoreButton';

const ItemTiendaPreview = ({ 
  imagen, 
  nombre, 
  onTiendaClick, 
  onAdminClick,
  store, 
  handleDeleteStore,
  handleAdminClick,
  handleSaveClick,
  url }) => {
    
  const [loading, setLoading] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  const handleImageLoad = () => setLoading(false);

  return (
    <div 
      className="group relative overflow-hidden rounded-xl transition-all duration-300 animate-scale-in hover:shadow-lg"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ cursor: 'pointer' }}
    >
      {/* Imagen principal con loader */}
      <div className="aspect-video w-full overflow-hidden rounded-xl cursor-pointer relative">
        {loading && (
          <SkeletonLoader width="100%" height="100%" style={{ position: 'absolute', top: 0, left: 0}} />
        )}

          
          <img 
            src={imagen} 
            alt={`Imagen de la tienda ${nombre}`} 
            className={`w-full h-full object-cover transition-transform duration-500 group-hover:scale-105 ${loading ? 'hidden' : 'block'}`}
            onLoad={handleImageLoad}
          />

        {/*
          <iframe
                src={imagen}  // URL del hub que quieres previsualizar
                title={nombre}
                sandbox="allow-scripts allow-forms allow-same-origin"
                style={{ border: "none" }}
          />
        */}

        <div className={`absolute top-1 right-1 flex  gap-2 transition-opacity duration-300 ${isHovered ? 'opacity-100' : 'opacity-0'}`}>
              <div style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 10 }}>
                <DeleteStoreButton onDelete={() => handleDeleteStore(store.nombre)} />
              </div>
              <div style={{ position: 'absolute', top: '10px', right: '55px', zIndex: 10 }}>
                <AdminStoreButton onAdminClick={() => handleAdminClick(store)} />
              </div>

              <div style={{ position: 'absolute', top: '10px', right: '55px', zIndex: 10 }}>
                <SectionStoreButton onSave={handleSaveClick}  store={store}/>
              </div>

              <div style={{ position: 'absolute', top: '10px', right: '100px', zIndex: 10 }}>
                <AdminStoreButton onAdminClick={() => handleAdminClick(store)} />
              </div>
          </div>  
      </div>


      <div 
  className="absolute bottom-0 left-0 right-0 p-3 bg-black/60 backdrop-blur-sm flex items-center justify-between text-white"
  onClick={(event) => {
    // Evita que el clic en botones dentro de este div active onTiendaClick
    if (event.target.tagName === "BUTTON") {
      event.stopPropagation();
      return;
    }
    onTiendaClick();
  }}
>
  <span className="font-poppins font-medium truncate">{nombre}</span>
  <button className="opacity-70 hover:opacity-100 transition-opacity">
    🔗
  </button>
</div>

    </div>
  );
};

export default ItemTiendaPreview;
