import React from 'react';
import {  Button } from '@mui/material';
import DesignServicesIcon from '@mui/icons-material/DesignServices';

const NavbarEditButton = ({ onClick }) => {
  return (
      <Button
        variant="contained"
        color="primary"
        startIcon={<DesignServicesIcon />}
        onClick={onClick}
        sx={{
          position: 'absolute',
          top: '1rem',
          right: '-4.9rem',                
          transition: 'right 0.3s ease',
          borderTopRightRadius: 0,    
          borderBottomRightRadius: 0, 
          background: 'linear-gradient(to right, #DD69E5, #A55AEE, #62CEFB)', 
          borderRight: 'none',
          '&:hover': {
            right: '0rem',             
            background: 'linear-gradient(to right, #DD69E5, #A55AEE, #62CEFB)', 
          },
        }}
      >
        Header
      </Button>
  );
};

export default NavbarEditButton;

