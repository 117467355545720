import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  FormHelperText,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';

import CustomAlert from '../../CustomAlert/CustomAlert';

const AddStoreForm = ({ open, onClose, onSave }) => {
  const [storeName, setStoreName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Estado para controlar la alerta personalizada
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('error'); // Tipo de alerta: 'error', 'warning', etc.
  const [alertMessage, setAlertMessage] = useState('');

  // Expresión regular: solo a-z y 0-9; cualquier otro carácter es inválido
  const invalidCharsPattern = /[^a-zA-Z0-9]/;

  const categories = [
    //{ value: "LMS", label: "LMS (Learning Management System)" },
    { value: "e-commerce", label: "E-commerce (Comercio Electrónico)" },
    { value: "portafolio", label: "Portafolio" },
    { value: "landing page", label: "Landing Pages (Páginas de Aterrizaje)" },
    { value: "blog", label: "Blog" },
    //{ value: "PaginasCorporativas", label: "Páginas Corporativas" },
  ];

  const handleSave = async () => {
    // Validación: campo vacío
    if (!storeName.trim()) {
      setError(true);
      return;
    }

    // Validación: caracteres no permitidos
    if (invalidCharsPattern.test(storeName)) {
      setAlertType('warning'); // Tipo de alerta: error
      setAlertMessage('Por favor, ingresa correctamente el nombre de tu hub'); // Mensaje de la alerta
      setShowAlert(true)
      //setWarning(true);
      return;
    }

    if (!selectedCategory) {
      setAlertType('warning'); // Tipo de alerta: error
      setAlertMessage('Por favor, selecciona una categoría.'); // Mensaje de la alerta
      setShowAlert(true); // Mostrar la alerta
      return;
    }

    // Activa el estado de cargando y deshabilita el botón
    setIsLoading(true);

    try {
      const lowerCaseStoreName = storeName.toLowerCase();
      await onSave({ storeName: lowerCaseStoreName, category: selectedCategory });
    } catch (error) {
      console.error('Error al guardar la tienda:', error);
    } finally {
      // Una vez finalizada la operación, se reinician los estados y se cierra el modal
      setIsLoading(false);
      setStoreName('');
      setSelectedCategory('');
      setError(false);
      setWarning(false);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{
      '& .MuiDialog-paper': {
        borderRadius: '15px',
      }
    }}>
      <Box sx={{background: 'linear-gradient(to right, #DD69E5, #A55AEE, #62CEFB)', height: '15px',width: '100%'}} ></Box>
      <DialogTitle variant="titleModal"  >Agregar Hub</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ padding: '5px' }}>
          <TextField
            className="!font-poppins"
            label="Nombre del Hub"
            value={storeName}
            onChange={(e) => {
              const value = e.target.value;
              setStoreName(value);
              setError(false);

              // Si el campo está vacío, se quita la advertencia
              if (!value.trim()) {
                setWarning(false);
              } else {
                // Se muestra la advertencia si se detectan caracteres no permitidos
                setWarning(invalidCharsPattern.test(value));
              }
            }}
            fullWidth
            error={error}
          />
          {error && (
            <FormHelperText sx={{ color: 'red',fontFamily:'Poppins !important' }}>
              Por favor, ingresa un nombre para la tienda.
            </FormHelperText>
          )}
          {warning && (
            <FormHelperText sx={{ color: 'red' ,fontFamily:'Poppins !important' }}>
              No se permiten caracteres especiales.
            </FormHelperText>
          )}

          <FormControl fullWidth>
            <InputLabel id="category-label">Tipo de Hub</InputLabel>
            <Select
              labelId="category-label"
              value={selectedCategory}
              label="Categoría"
              onChange={(e) => setSelectedCategory(e.target.value)}
              fullWidth
            >
              {categories.map((category) => (
                <MenuItem key={category.value} value={category.value}>
                  {category.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" disabled={isLoading} className='!font-poppins'>
          Cancelar
        </Button>
        <Button onClick={handleSave}  variant="btnPrimary" className='!font-poppins' disabled={isLoading}>
          {isLoading ? 'Guardando...' : 'Guardar'}
        </Button>
      </DialogActions>
      <CustomAlert
        tipo={alertType}
        mensaje={alertMessage}
        open={showAlert}
        onClose={() => setShowAlert(false)}
      />
    </Dialog>
  );
};

export default AddStoreForm;